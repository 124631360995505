import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber as IntlFormattedNumber } from 'react-intl';
import { isEmptyValue } from '@jutro/data';
import { intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import { FieldComponent } from '../inputs/FieldComponent/FieldComponent';
function getFormattedNumberProps(props, number) {
  var placeholder = props.placeholder,
    format = props.format,
    value = props.value,
    showGrouping = props.showGrouping,
    showFractions = props.showFractions,
    maximumFractionDigits = props.maximumFractionDigits;
  var valueToFormat = number || value;
  var hasValue = !isEmptyValue(valueToFormat);
  if (!hasValue) {
    return {
      placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : FieldComponent.defaultROEmptyValue
    };
  }
  return {
    value: valueToFormat,
    style: format,
    useGrouping: showGrouping,
    minimumFractionDigits: 0,
    maximumFractionDigits: showFractions ? maximumFractionDigits : 0
  };
}
export var formattedNumberPropTypes = {
  placeholder: intlMessageShape,
  format: PropTypes.oneOf(['decimal', 'percent']),
  showFractions: PropTypes.bool,
  maximumFractionDigits: PropTypes.number,
  showGrouping: PropTypes.bool,
  value: PropTypes.number
};
export var FormattedNumber = function FormattedNumber(props) {
  var _getFormattedNumberPr = getFormattedNumberProps(props),
    placeholder = _getFormattedNumberPr.placeholder,
    formattedNumberProps = _objectWithoutProperties(_getFormattedNumberPr, ["placeholder"]);
  var translator = useContext(TranslatorContext);
  if (placeholder) {
    return React.createElement(React.Fragment, null, translator(placeholder));
  }
  return React.createElement(IntlFormattedNumber, formattedNumberProps);
};
FormattedNumber.propTypes = formattedNumberPropTypes;
FormattedNumber.defaultProps = {
  format: 'decimal',
  placeholder: FieldComponent.defaultROEmptyValue,
  showGrouping: true,
  maximumFractionDigits: 3
};
export function formatNumber(intl, props, number) {
  var _getFormattedNumberPr2 = getFormattedNumberProps(props, number),
    placeholder = _getFormattedNumberPr2.placeholder,
    value = _getFormattedNumberPr2.value,
    formattedNumberProps = _objectWithoutProperties(_getFormattedNumberPr2, ["placeholder", "value"]);
  if (placeholder) {
    return placeholder;
  }
  return intl.formatNumber(value, formattedNumberProps);
}
FormattedNumber.__docgenInfo = {
  componentName: "FormattedNumber",
  packageName: "@jutro/components",
  description: "The `FormattedNumber` component is used to render number value localized.",
  displayName: "FormattedNumber",
  methods: [],
  actualName: "FormattedNumber",
  metadataType: "element",
  props: {
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The string to display in the UI if 'value' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: true
      }
    },
    format: {
      type: {
        name: "enum",
        value: [{
          value: "'decimal'",
          computed: false
        }, {
          value: "'percent'",
          computed: false
        }]
      },
      required: false,
      description: "How to display the number in this format, 'decimal' or 'percentage'",
      defaultValue: {
        value: "'decimal'",
        computed: false
      }
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays fractions with the value"
    },
    maximumFractionDigits: {
      type: {
        name: "number"
      },
      required: false,
      description: "Number of fraction digits",
      defaultValue: {
        value: "3",
        computed: false
      }
    },
    showGrouping: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays grouping separators with the value",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    value: {
      type: {
        name: "number"
      },
      required: false,
      description: "The numeric value to display"
    }
  }
};