import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useReducer, useMemo } from 'react';
import { bindActionCreators } from '../helper';
import { reducer } from './reducer';
import * as actions from './actions';
export function useExpansion() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useReducer = useReducer(reducer, initialState),
    _useReducer2 = _slicedToArray(_useReducer, 2),
    expandedRows = _useReducer2[0],
    dispatch = _useReducer2[1];
  var boundActions = useMemo(function () {
    return bindActionCreators(dispatch, actions);
  }, []);
  return [expandedRows, boundActions];
}