import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { RadioButton } from '@jutro/components';
import { TableColumn } from '../table/TableColumn';
import { createTextFilter } from '../helper';
import tableStyles from "../table/Table.module.css";
export var RadioColumn = function RadioColumn() {
  if (false) {
    throw new Error('Component <RadioColumn /> should never render');
  }
  return React.createElement(React.Fragment, null);
};
RadioColumn.propTypes = _objectSpread({}, TableColumn.propTypes, {
  option: PropTypes.oneOfType([PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string
  }), PropTypes.func]),
  onOption: PropTypes.func,
  onAccessor: PropTypes.func
});
RadioColumn.getRowActions = function (_ref) {
  var selectOnRowClick = _ref.selectOnRowClick;
  if (!selectOnRowClick) {
    return undefined;
  }
  return function (event) {
    return RadioColumn.onRowClick(event);
  };
};
RadioColumn.onRowClick = function (evt) {
  var currentRow = evt.currentTarget;
  var currentRadioInput = currentRow.querySelector('input[type="radio"]');
  if (currentRadioInput) {
    evt.preventDefault();
    currentRadioInput.click();
  }
};
RadioColumn.getTabIndex = function (index) {
  return index === 0 ? 0 : -1;
};
RadioColumn.onKeyDown = function (evt) {
  var handleMoveDown = function handleMoveDown(selectedElementIndex, availableValues) {
    return (selectedElementIndex + 1) % availableValues.length;
  };
  var handleMoveUp = function handleMoveUp(selectedElementIndex, availableValues) {
    return selectedElementIndex === 0 ? availableValues.length - 1 : (selectedElementIndex - 1) % availableValues.length;
  };
  var eventMap = {
    ArrowDown: handleMoveDown,
    ArrowRight: handleMoveDown,
    ArrowUp: handleMoveUp,
    ArrowLeft: handleMoveUp
  };
  var handleMove = eventMap[evt.key];
  var getNextRow = function getNextRow(currentRow) {
    if (!currentRow.querySelector('input').checked) {
      return currentRow;
    }
    var siblingRows = currentRow.parentNode.childNodes;
    var currentRowIndex = _indexOfInstanceProperty(Array.prototype).call(siblingRows, currentRow);
    var nextIndex = handleMove(currentRowIndex, siblingRows);
    return siblingRows.item(nextIndex);
  };
  if (handleMove) {
    evt.preventDefault();
    var nextRow = getNextRow(evt.currentTarget);
    nextRow.focus();
    nextRow.click();
  }
};
RadioColumn.defaultCell = function (row, rowId, props) {
  var header = props.header,
    renderHeader = props.renderHeader,
    cell = props.cell,
    renderCell = props.renderCell,
    className = props.className,
    visible = props.visible,
    option = props.option,
    onOption = props.onOption,
    value = props.value,
    translator = props.translator,
    onValueChange = props.onValueChange,
    columnProportion = props.columnProportion,
    onFilter = props.onFilter,
    basePath = props.basePath,
    other = _objectWithoutProperties(props, ["header", "renderHeader", "cell", "renderCell", "className", "visible", "option", "onOption", "value", "translator", "onValueChange", "columnProportion", "onFilter", "basePath"]);
  if (visible === false) {
    return null;
  }
  var rowOption = option || onOption;
  if (_.isFunction(rowOption)) {
    rowOption = rowOption(row, rowId, props);
  } else if (!rowOption && row) {
    rowOption = {
      code: row.code,
      name: row.name || row.code
    };
  }
  if (!rowOption) {
    return null;
  }
  return renderRadioButton({
    rowOption: rowOption,
    value: value,
    onValueChange: onValueChange,
    otherProps: _objectSpread({}, other, {
      rowId: rowId
    })
  });
};
export function renderRadioButton(props) {
  var rowOption = props.rowOption,
    value = props.value,
    onValueChange = props.onValueChange,
    otherProps = props.otherProps;
  var id = otherProps.id,
    rowId = otherProps.rowId,
    rest = _objectWithoutProperties(otherProps, ["id", "rowId"]);
  return React.createElement(RadioButton, _extends({
    id: "".concat(id, "_").concat(rowId),
    labelClassName: tableStyles.radio,
    key: rowOption.code,
    option: rowOption,
    value: value,
    onValueChange: onValueChange
  }, rest));
}
RadioColumn.displayName = 'RadioColumn';
RadioColumn.defaultProps = {
  renderCell: RadioColumn.defaultCell,
  getRowActions: RadioColumn.getRowActions,
  onFilter: function onFilter(filterValue) {
    var textFilter = createTextFilter(filterValue);
    return function (_ref2) {
      var name = _ref2.name;
      return textFilter(name);
    };
  },
  columnProportion: 1
};
RadioColumn.__docgenInfo = {
  componentName: "RadioColumn",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "RadioColumn",
  methods: [],
  actualName: "RadioColumn",
  metadataType: "container",
  props: {
    option: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            name: {
              name: "string",
              required: false
            },
            code: {
              name: "string",
              required: false
            }
          }
        }, {
          name: "func"
        }]
      },
      required: false,
      description: "The option to be displayed in cell"
    },
    onOption: {
      type: {
        name: "func"
      },
      required: false,
      description: "The option to be displayed in cell"
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: false,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    },
    renderCell: {
      defaultValue: {
        value: "RadioColumn.defaultCell",
        computed: true
      },
      required: false
    },
    getRowActions: {
      defaultValue: {
        value: "RadioColumn.getRowActions",
        computed: true
      },
      required: false
    },
    onFilter: {
      defaultValue: {
        value: "filterValue => {\n    const textFilter = createTextFilter(filterValue);\n\n    return ({ name }) => textFilter(name);\n}",
        computed: false
      },
      required: false
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: false
      },
      required: false
    }
  },
  composes: ["../table/TableColumn"]
};
renderRadioButton.__docgenInfo = {
  componentName: "renderRadioButton",
  packageName: "@jutro/datatable",
  description: "Render the button with action defined on onclick listener",
  displayName: "renderRadioButton",
  methods: [],
  actualName: "renderRadioButton"
};