import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import { publish, JUTRO_TOPICS } from '@jutro/events';
var errorStyle = {
  color: 'white',
  background: 'rgb(204, 0, 0)',
  fontSize: '16px',
  fontFamily: 'Lucida Console, sans-serif',
  lineHeight: 1.2,
  padding: 20,
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
  width: '100%',
  height: '100%'
};
export var DevErrorBoundary = function (_Component) {
  _inherits(DevErrorBoundary, _Component);
  var _super = _createSuper(DevErrorBoundary);
  function DevErrorBoundary() {
    var _this;
    _classCallCheck(this, DevErrorBoundary);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "state", {
      error: false
    });
    return _this;
  }
  _createClass(DevErrorBoundary, [{
    key: "componentDidCatch",
    value: function componentDidCatch(error, errorInfo) {
      this.setState({
        error: error,
        errorInfo: errorInfo
      });
      publish(JUTRO_TOPICS.UNKNOWN_ERROR, {
        error: error,
        errorInfo: errorInfo
      });
    }
  }, {
    key: "renderStack",
    value: function renderStack(stackStr) {
      var _context, _context2;
      return _mapInstanceProperty(_context = _filterInstanceProperty(_context2 = stackStr.split(/\sin\s/)).call(_context2, function (s) {
        return !/^\s+$/.test(s);
      })).call(_context, function (l) {
        return React.createElement("div", null, "- in ".concat(l));
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
        error = _this$state.error,
        errorInfo = _this$state.errorInfo;
      if (error) {
        return React.createElement("div", {
          style: errorStyle
        }, React.createElement("div", null, "An unhandled error has occurred: ".concat(error.message)), React.createElement("hr", {
          style: {
            marginTop: '20px',
            marginBottom: '20px'
          }
        }), React.createElement("div", null, React.createElement("div", null, "Component Stack:"), this.renderStack(errorInfo.componentStack)));
      }
      return this.props.children;
    }
  }]);
  return DevErrorBoundary;
}(Component);
DevErrorBoundary.__docgenInfo = {
  componentName: "DevErrorBoundary",
  packageName: "@jutro/app",
  description: "Error boundary to serve as a \"catch-all\" for child component\nerrors during development.",
  displayName: "DevErrorBoundary",
  methods: [{
    name: "renderStack",
    docblock: null,
    modifiers: [],
    params: [{
      name: "stackStr",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "DevErrorBoundary"
};