import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import PropTypes from 'prop-types';
import { tooltipBaseProps } from '@jutro/prop-types';
import { useSafeTranslatedUrls } from '@jutro/locale';
var propTypes = _objectSpread({}, tooltipBaseProps, {
  className: PropTypes.string,
  titleClass: PropTypes.string,
  textClass: PropTypes.string,
  linkClass: PropTypes.string,
  translator: PropTypes.func
});
export var TooltipContent = function TooltipContent(_ref) {
  var text = _ref.text,
    title = _ref.title,
    link = _ref.link,
    href = _ref.href,
    className = _ref.className,
    titleClass = _ref.titleClass,
    textClass = _ref.textClass,
    linkClass = _ref.linkClass,
    translator = _ref.translator;
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var safeHref = urlTranslatorAndSanitizer(href);
  return React.createElement("div", {
    className: className,
    role: "alert"
  }, title && React.createElement("div", {
    className: titleClass
  }, React.createElement("strong", null, translator(title))), text && React.createElement("div", {
    className: textClass
  }, translator(text)), link && safeHref && React.createElement("a", {
    className: linkClass,
    href: safeHref,
    target: "_blank",
    rel: "noopener noreferrer"
  }, translator(link)));
};
TooltipContent.propTypes = propTypes;
TooltipContent.__docgenInfo = {
  componentName: "TooltipContent",
  packageName: "@jutro/components",
  description: "Content component that render inside tooltip",
  displayName: "TooltipContent",
  methods: [],
  actualName: "TooltipContent",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize the component"
    },
    titleClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize title"
    },
    textClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize text"
    },
    linkClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to customize link"
    },
    translator: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to translate the content"
    }
  },
  composes: ["@jutro/prop-types"]
};