var focusNewCell = function focusNewCell(e, focusedRow, id, tableId) {
  e.preventDefault();
  var nodes = document.querySelectorAll("[data-row=\"".concat(focusedRow, "\"][data-col=\"").concat(id, "\"][data-parent=\"").concat(tableId, "\"]"));
  if (nodes[0]) {
    var focusableElement = nodes[0].querySelectorAll('[tabIndex]')[0] || nodes[0].querySelectorAll('label')[0];
    if (focusableElement) {
      focusableElement.focus();
    } else {
      nodes[0].focus();
    }
  }
};
var click = function click(e, columnId) {
  if (columnId !== 'selection') {
    e.target.click();
  }
};
var isEditableFieldTarget = function isEditableFieldTarget(e) {
  return e.target.type === 'textarea' || e.target.type === 'text' || e.target.type === 'number';
};
export var onKeyDownHandler = function onKeyDownHandler(focused, tableId, rtState) {
  return function (e) {
    var focusedColumn = focused.column,
      focusedRow = focused.row;
    var columns = rtState.allVisibleColumns,
      endRow = rtState.endRow;
    switch (e.key) {
      case 'ArrowLeft':
        {
          if (isEditableFieldTarget(e)) {
            return;
          }
          if (focusedColumn > 0) {
            focusedColumn -= 1;
          }
          break;
        }
      case 'ArrowRight':
        {
          if (isEditableFieldTarget(e)) {
            return;
          }
          if (focusedColumn < columns.length - 1) {
            focusedColumn += 1;
          }
          break;
        }
      case 'ArrowUp':
        {
          if (isEditableFieldTarget(e)) {
            return;
          }
          if (focusedRow > 0) {
            focusedRow -= 1;
          }
          break;
        }
      case 'ArrowDown':
        {
          if (isEditableFieldTarget(e)) {
            return;
          }
          if (focusedRow < endRow) {
            focusedRow += 1;
          }
          break;
        }
      case 'Home':
        {
          if (isEditableFieldTarget(e)) {
            return;
          }
          if (e.ctrlKey) {
            if (focusedRow !== 0) {
              focusedRow = 0;
            }
          }
          if (focusedColumn !== 0) {
            focusedColumn = 0;
          }
          break;
        }
      case 'End':
        {
          if (isEditableFieldTarget(e)) {
            return;
          }
          if (e.ctrlKey) {
            if (focusedRow !== endRow) {
              focusedRow = endRow;
            }
          }
          if (focusedColumn !== columns.length - 1) {
            focusedColumn = columns.length - 1;
          }
          break;
        }
      case 'PageUp':
        {
          if (focusedRow !== 0) {
            focusedRow = 0;
          }
          break;
        }
      case 'PageDown':
        {
          if (focusedRow !== endRow) {
            focusedRow = endRow;
          }
          break;
        }
      case 'Enter':
        {
          if (e.altKey) {
            return;
          }
          click(e, columns[focusedColumn].id);
          break;
        }
      default:
        return;
    }
    focusNewCell(e, focusedRow, columns[focusedColumn].id, tableId);
  };
};