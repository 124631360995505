import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { createContextConsumerHOC } from '@jutro/platform';
import { extendWithBreakpointValues, getBreakpointPxValue, getBreakpoints } from './breakpoint.utils';
export var BreakpointTrackerContext = React.createContext('desktop');
export function withBreakpoint(ComponentToWrap) {
  return createContextConsumerHOC({
    component: ComponentToWrap,
    context: BreakpointTrackerContext,
    mapContextToProps: function mapContextToProps(existingProps, breakpoint) {
      return breakpoint;
    }
  });
}
export function renderWithBreakpoint(render) {
  if (window.__TEST__) {
    return render('desktop');
  }
  return React.createElement(BreakpointTrackerContext.Consumer, null, function (breakpoint) {
    return render(breakpoint);
  });
}
var BreakpointTracker = function (_Component) {
  _inherits(BreakpointTracker, _Component);
  var _super = _createSuper(BreakpointTracker);
  function BreakpointTracker() {
    var _this;
    _classCallCheck(this, BreakpointTracker);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "state", {});
    _defineProperty(_assertThisInitialized(_this), "breakpoints", void 0);
    _defineProperty(_assertThisInitialized(_this), "resizeTimer", null);
    _defineProperty(_assertThisInitialized(_this), "throttleResize", function () {
      if (!_this.resizeTimer && _this.props.delayMs) {
        _this.resizeTimer = window.setTimeout(_this.handleResize, _this.props.delayMs);
      }
    });
    _defineProperty(_assertThisInitialized(_this), "handleResize", function () {
      _this.resizeTimer = null;
      var newBreakpoint = _this.getBreakpointFromWidth(document.documentElement.clientWidth);
      if (_this.state.breakpoint !== newBreakpoint) {
        _this.setState({
          breakpoint: newBreakpoint
        });
      }
    });
    return _this;
  }
  _createClass(BreakpointTracker, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.breakpoints = getBreakpoints();
      window.addEventListener('resize', this.throttleResize);
      window.addEventListener('orientationchange', this.handleResize);
      this.setState({
        breakpoint: this.getBreakpointFromWidth(document.documentElement.clientWidth)
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      window.removeEventListener('resize', this.throttleResize);
      window.removeEventListener('orientationchange', this.handleResize);
    }
  }, {
    key: "getBreakpointFromWidth",
    value: function getBreakpointFromWidth(width) {
      var _this2 = this;
      var breakpoint = 'desktop';
      var keys = _Object$keys(this.breakpoints);
      _.forEachRight(keys, function (key) {
        if (width <= _this2.breakpoints[key]) {
          breakpoint = key;
        }
      });
      return breakpoint;
    }
  }, {
    key: "render",
    value: function render() {
      var breakpoint = this.state.breakpoint;
      if (!breakpoint) {
        return null;
      }
      return React.createElement(BreakpointTrackerContext.Provider, {
        value: breakpoint
      }, this.props.children);
    }
  }]);
  return BreakpointTracker;
}(Component);
_defineProperty(BreakpointTracker, "propTypes", {
  delayMs: PropTypes.number
});
_defineProperty(BreakpointTracker, "defaultProps", {
  delayMs: 50
});
_defineProperty(BreakpointTracker, "applyBreakpointOverrides", extendWithBreakpointValues);
_defineProperty(BreakpointTracker, "getBreakpoints", getBreakpoints);
_defineProperty(BreakpointTracker, "getBreakpointPxValue", getBreakpointPxValue);
export { BreakpointTracker as default };
renderWithBreakpoint.__docgenInfo = {
  componentName: "renderWithBreakpoint",
  packageName: "@jutro/layout",
  description: "",
  displayName: "renderWithBreakpoint",
  methods: [],
  actualName: "renderWithBreakpoint"
};
BreakpointTracker.__docgenInfo = {
  componentName: "BreakpointTracker",
  packageName: "@jutro/layout",
  description: "",
  displayName: "BreakpointTracker",
  methods: [{
    name: "getBreakpointFromWidth",
    docblock: null,
    modifiers: [],
    params: [{
      name: "width",
      optional: false,
      type: null
    }],
    returns: null
  }],
  actualName: "BreakpointTracker",
  props: {
    delayMs: {
      type: {
        name: "number"
      },
      required: false,
      description: "",
      defaultValue: {
        value: "50",
        computed: false
      }
    }
  }
};