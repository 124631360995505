import { error, info } from '@jutro/logger';
var SumoLogger = require('sumo-logger');
export default function loadSumoLogic(sumoLogicUrl, sourceName, sourceCategory) {
  var opts = {
    endpoint: sumoLogicUrl,
    returnPromise: false,
    sendErrors: true,
    sourceName: sourceName,
    sourceCategory: sourceCategory,
    hostName: window.location.hostname,
    onSuccess: function onSuccess() {
      info('Sumo logic success');
    },
    onError: function onError() {
      error('Sumo logic failed');
    }
  };
  return new SumoLogger(opts);
}