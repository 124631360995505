import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import { isEmptyValue } from '@jutro/data';
import { getOptionCode } from './widgets/inputs/availableValues';
export var SPACE_BAR = ' ';
export var makeHandleRadioButtonKeyDown = function makeHandleRadioButtonKeyDown(focusTrigger) {
  return function (evt, props, handleAvailableValuesValueChange) {
    var model = props.model,
      path = props.path,
      value = props.value,
      availableValues = props.availableValues;
    var selectedElementIndex = _findIndexInstanceProperty(availableValues).call(availableValues, function (el) {
      return !isEmptyValue(value) && getOptionCode(el) === value.toString();
    });
    var handleMove = eventMap[evt.key];
    if (handleMove) {
      evt.preventDefault();
    }
    var targetIndex = handleMove ? handleMove(selectedElementIndex, availableValues) : -1;
    if (targetIndex >= 0) {
      var optionCode = getOptionCode(availableValues[targetIndex]);
      handleAvailableValuesValueChange(optionCode, model || path);
      focusTrigger === null || focusTrigger === void 0 ? void 0 : focusTrigger(evt.currentTarget, optionCode);
    }
  };
};
var handleMoveDown = function handleMoveDown(selectedElementIndex, availableValues) {
  return (selectedElementIndex + 1) % availableValues.length;
};
var handleMoveUp = function handleMoveUp(selectedElementIndex, availableValues) {
  return selectedElementIndex === 0 ? availableValues.length - 1 : (selectedElementIndex - 1) % availableValues.length;
};
var eventMap = {
  SPACE_BAR: function SPACE_BAR() {
    return -1;
  },
  ArrowDown: handleMoveDown,
  ArrowRight: handleMoveDown,
  ArrowUp: handleMoveUp,
  ArrowLeft: handleMoveUp
};
export var getTabIndex = function getTabIndex(index) {
  return index === 0 ? 0 : -1;
};