import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
export function mapGooglePlacesAddressToDTO(addressComponents) {
  var DTOAddress = {};
  var streetNumber;
  var streetName;
  if (addressComponents) {
    _forEachInstanceProperty(addressComponents).call(addressComponents, function (addressComponent) {
      var types = addressComponent.types.join();
      switch (types) {
        case 'country':
          DTOAddress.country = addressComponent.short_name;
          break;
        case 'street_number':
          streetNumber = addressComponent.long_name;
          break;
        case 'route':
          streetName = addressComponent.long_name;
          break;
        case 'postal_code':
          DTOAddress.postalCode = addressComponent.short_name;
          break;
        case 'country,political':
          DTOAddress.country = addressComponent.long_name;
          DTOAddress.countryCode = addressComponent.short_name;
          break;
        case 'administrative_area_level_1,political':
          DTOAddress.state = addressComponent.short_name;
          break;
        case 'locality,political':
          DTOAddress.city = addressComponent.long_name;
          break;
        case 'sublocality_level_1,sublocality,political':
          DTOAddress.city = addressComponent.long_name;
          break;
        case 'neighborhood,political':
          DTOAddress.addressLine2 = addressComponent.long_name;
          break;
        default:
      }
      if (streetNumber && streetName) {
        DTOAddress.addressLine1 = "".concat(streetNumber, " ").concat(streetName);
      } else if (streetName) {
        DTOAddress.addressLine1 = streetName;
      } else if (streetNumber) {
        DTOAddress.addressLine1 = streetNumber;
      }
    });
  }
  return DTOAddress;
}