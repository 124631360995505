import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import { warning } from '@jutro/logger';
var loggedMessages = new _Set();
export var logDeprecationMessage = function logDeprecationMessage(deprecated, alternative) {
  var extraMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var dropTargetVersion = arguments.length > 3 ? arguments[3] : undefined;
  var message = "".concat(deprecated, " has been deprecated.");
  if (alternative) {
    message += " Please use ".concat(alternative, " instead.");
  }
  if (extraMessage) {
    message += " ".concat(extraMessage);
  }
  if (dropTargetVersion) {
    message += " It will be removed in the \"".concat(dropTargetVersion, "\" Jutro release.");
  }
  if (loggedMessages.has(message)) {
    return;
  }
  loggedMessages.add(message);
  warning(message, {
    bold: true
  });
};