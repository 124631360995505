import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from '../../../Icon/Icon';
export var GenericSelectControlDropdownIndicator = function GenericSelectControlDropdownIndicator(_ref) {
  var icon = _ref.icon,
    className = _ref.className,
    hasValue = _ref.hasValue,
    _ref$selectProps = _ref.selectProps,
    genericSelectStyles = _ref$selectProps.genericSelectStyles,
    isClearable = _ref$selectProps.isClearable,
    isLoading = _ref$selectProps.isLoading;
  var shouldShowClearIndicator = hasValue && isClearable;
  var shouldShowLoadingIndicator = isLoading;
  if (shouldShowClearIndicator || shouldShowLoadingIndicator) {
    return null;
  }
  var arrowClasses = cx(genericSelectStyles.selectIcon, className);
  return React.createElement(Icon, {
    tag: "span",
    icon: icon,
    className: arrowClasses,
    "aria-hidden": true,
    focusable: false
  });
};
GenericSelectControlDropdownIndicator.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  selectProps: PropTypes.shape({
    value: PropTypes.any,
    customStyles: PropTypes.object,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool
  })
};
GenericSelectControlDropdownIndicator.defaultProps = {
  icon: 'mi-expand-more'
};
GenericSelectControlDropdownIndicator.__docgenInfo = {
  componentName: "GenericSelectControlDropdownIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlDropdownIndicator",
  methods: [],
  actualName: "GenericSelectControlDropdownIndicator",
  props: {
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon to show in indicator",
      defaultValue: {
        value: "'mi-expand-more'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional CSS className for this component"
    },
    selectProps: {
      type: {
        name: "shape",
        value: {
          value: {
            name: "any",
            description: "Current selected value",
            required: false
          },
          customStyles: {
            name: "object",
            description: "Map with custom styles for generic select",
            required: false
          },
          isClearable: {
            name: "bool",
            description: "Indicates whether generic select is clearable",
            required: false
          },
          isLoading: {
            name: "bool",
            description: "Indicates whether generic select is in loading state",
            required: false
          }
        }
      },
      required: false,
      description: "Generic select custom props"
    }
  }
};