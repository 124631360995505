import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Number$isNaN from "@babel/runtime-corejs3/core-js-stable/number/is-nan";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import isDate from "lodash/isDate";
import { parseDateShapeToDate, formatDateToDataType } from '@jutro/components';
function getDefaultDate() {
  var today = new Date();
  return {
    year: today.getFullYear(),
    month: today.getMonth(),
    day: today.getDate(),
    hour: 0,
    minute: 0
  };
}
export function formatValueToDataType(value, dataType, includeTime) {
  if (!value) {
    return undefined;
  }
  var _getDefaultDate$value = _objectSpread({}, getDefaultDate(), {}, value),
    year = _getDefaultDate$value.year,
    month = _getDefaultDate$value.month,
    day = _getDefaultDate$value.day,
    hour = _getDefaultDate$value.hour,
    minute = _getDefaultDate$value.minute;
  var date = new Date(year, month, day, hour, minute);
  return formatDateToDataType(date, dataType, includeTime);
}
export function formatValueFromDataType(value, includeTime) {
  if (!value) {
    return undefined;
  }
  var valueToParse = value;
  if (isString(value) || isNumber(value) || isDate(value)) {
    var date = new Date(value);
    valueToParse = {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
      hour: date.getUTCHours(),
      minute: date.getUTCMinutes()
    };
  }
  var parsedValue = parseDateShapeToDate(valueToParse, includeTime);
  if (_Number$isNaN(Number(parsedValue.getTime()))) {
    return undefined;
  }
  return {
    year: parsedValue.getFullYear(),
    month: parsedValue.getMonth(),
    day: parsedValue.getDate(),
    hour: parsedValue.getHours(),
    minute: parsedValue.getMinutes()
  };
}
export function isValidDate(value) {
  if (!value) {
    return false;
  }
  var year = value.year,
    month = value.month,
    day = value.day;
  return !isNil(year) && !isNil(month) && !isNil(day);
}
export function isValidTime(value) {
  if (!value) {
    return false;
  }
  var hour = value.hour,
    minute = value.minute;
  return !isNil(hour) && !isNil(minute);
}