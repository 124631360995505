import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import { components } from 'react-select';
import { getMenuListId } from '../helpers';
export var GenericSelectControlInput = function GenericSelectControlInput(props) {
  var _props$selectProps = props.selectProps,
    required = _props$selectProps.required,
    id = _props$selectProps.id,
    menuIsOpen = _props$selectProps.menuIsOpen;
  return React.createElement(components.Input, _extends({}, props, {
    required: required,
    "aria-required": required,
    "aria-haspopup": "listbox"
  }, menuIsOpen && {
    'aria-controls': getMenuListId(id),
    'aria-autocomplete': 'list'
  }));
};
GenericSelectControlInput.__docgenInfo = {
  componentName: "GenericSelectControlInput",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlInput",
  methods: [],
  actualName: "GenericSelectControlInput"
};