import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import { Component } from 'react';
import { PageLoader } from '@jutro/components';
export var InitLoading = function (_Component) {
  _inherits(InitLoading, _Component);
  var _super = _createSuper(InitLoading);
  function InitLoading() {
    _classCallCheck(this, InitLoading);
    return _super.apply(this, arguments);
  }
  _createClass(InitLoading, [{
    key: "componentWillMount",
    value: function componentWillMount() {
      PageLoader.startPageLoading();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      PageLoader.stopPageLoading();
    }
  }, {
    key: "render",
    value: function render() {
      return PageLoader({});
    }
  }]);
  return InitLoading;
}(Component);
InitLoading.__docgenInfo = {
  componentName: "InitLoading",
  packageName: "@jutro/app",
  description: "The page loading component that will be shown\non application initialization.",
  displayName: "InitLoading",
  methods: [],
  actualName: "InitLoading"
};