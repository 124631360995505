import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _slicedToArray from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Symbol$toPrimitive from "@babel/runtime-corejs3/core-js-stable/symbol/to-primitive";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[_Symbol$toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { warning, error } from '@jutro/logger';
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import { getDisplayName, wrapWithIsRequired } from './ComponentPropTypes';
export var JUTRO_WARNING_PREFIX = 'JUTRO DEPRECATION WARNING';
var warned = {};
export var oneOfWithDeprecations = function oneOfWithDeprecations(allowedItems, deprecatedItems, version) {
  return wrapWithIsRequired(function (props, propName, componentName) {
    if (_indexOfInstanceProperty(deprecatedItems).call(deprecatedItems, props[propName]) > -1) {
      var message = "Value '".concat(props[propName], "' assigned to ").concat(propName, " is deprecated for ").concat(componentName, ". Please consider to use one of allowed values: '").concat(allowedItems.join(', '), "' since Jutro v").concat(version);
      printDeprecationWarning({
        propName: propName,
        componentName: componentName,
        message: message
      });
    }
    for (var _len = arguments.length, rest = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
      rest[_key - 3] = arguments[_key];
    }
    return PropTypes.oneOf([].concat(_toConsumableArray(deprecatedItems), _toConsumableArray(allowedItems))).apply(void 0, [props, propName, componentName].concat(rest));
  });
};
export function deprecated(validator) {
  var droppedTargetVersion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '@next';
  var additionalInfo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  for (var _len2 = arguments.length, ignoredValues = new Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
    ignoredValues[_key2 - 3] = arguments[_key2];
  }
  return function (props, propName, componentName, location, propFullName) {
    var componentNameSafe = componentName || '<<anonymous>>';
    var propFullNameSafe = propFullName || propName;
    var propValue = propName ? props[propName] : undefined;
    if (propValue && !(ignoredValues !== null && ignoredValues !== void 0 && _includesInstanceProperty(ignoredValues).call(ignoredValues, propValue))) {
      var message = "The ".concat(location, " \"").concat(propFullNameSafe, "\" of \"").concat(componentNameSafe, "\" is deprecated. It will be removed in the \"").concat(droppedTargetVersion, "\" Jutro release.");
      printDeprecationWarning({
        propName: propName,
        componentName: componentNameSafe,
        message: message,
        additionalInfo: additionalInfo
      });
    }
    for (var _len3 = arguments.length, rest = new Array(_len3 > 5 ? _len3 - 5 : 0), _key3 = 5; _key3 < _len3; _key3++) {
      rest[_key3 - 5] = arguments[_key3];
    }
    return validator.apply(void 0, [props, propName, componentName, location, propFullName].concat(rest));
  };
}
export function deprecateAll(validators) {
  var droppedTargetVersion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '@next';
  var additionalInfo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  for (var _len4 = arguments.length, ignoredValues = new Array(_len4 > 3 ? _len4 - 3 : 0), _key4 = 3; _key4 < _len4; _key4++) {
    ignoredValues[_key4 - 3] = arguments[_key4];
  }
  var _context;
  return _reduceInstanceProperty(_context = _Object$entries(validators)).call(_context, function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      propName = _ref2[0],
      validator = _ref2[1];
    return _objectSpread({}, acc, _defineProperty({}, propName, deprecated(validator, droppedTargetVersion, additionalInfo, ignoredValues)));
  }, {});
}
function printDeprecationWarning(_ref3) {
  var propName = _ref3.propName,
    componentName = _ref3.componentName,
    message = _ref3.message,
    additionalInfo = _ref3.additionalInfo;
  var messageKey = "".concat(componentName, ".").concat(propName);
  if (!warned[messageKey]) {
    warning("".concat(JUTRO_WARNING_PREFIX, ": ").concat(message, " ").concat(additionalInfo, " "));
    warned[messageKey] = true;
  }
}
export function resetDeprecatedWarnings(component) {
  if (component) {
    var _context2, _context3;
    _forEachInstanceProperty(_context2 = _filterInstanceProperty(_context3 = _Object$keys(warned)).call(_context3, function (key) {
      return _startsWithInstanceProperty(key).call(key, "".concat(component, "."));
    })).call(_context2, function (key) {
      return delete warned[key];
    });
    return;
  }
  warned = {};
}
function flattenPropsInfo(propsInfo, parentKey) {
  var _context4;
  return _reduceInstanceProperty(_context4 = _Object$entries(propsInfo)).call(_context4, function (accumulator, _ref4) {
    var _ref5 = _slicedToArray(_ref4, 2),
      key = _ref5[0],
      value = _ref5[1];
    var type = value.type,
      name = value.name;
    var nestedProperties = {};
    if ((type === null || type === void 0 ? void 0 : type.name) === 'shape' || name === 'shape') {
      nestedProperties = flattenPropsInfo((type === null || type === void 0 ? void 0 : type.value) || value, key);
    }
    var propsKey = parentKey ? "".concat(parentKey, ".").concat(key) : key;
    return _objectSpread({}, accumulator, {}, nestedProperties, _defineProperty({}, propsKey, value));
  }, {});
}
function transformProps(_ref6) {
  var props = _ref6.props,
    propsInfo = _ref6.propsInfo,
    displayName = _ref6.displayName,
    transformMap = _ref6.transformMap,
    parentKey = _ref6.parentKey;
  var _context5;
  return _reduceInstanceProperty(_context5 = _Object$entries(props)).call(_context5, function (accumulator, _ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      key = _ref8[0],
      value = _ref8[1];
    var propKey = parentKey ? "".concat(parentKey, ".").concat(key) : key;
    if (!get(propsInfo, propKey)) {
      warning("The prop \"".concat(propKey, "\" is not defined in the PropTypes for ").concat(displayName));
      return accumulator;
    }
    var _get = get(propsInfo, propKey),
      _get$deprecationInfo = _get.deprecationInfo,
      deprecationInfo = _get$deprecationInfo === void 0 ? {} : _get$deprecationInfo,
      name = _get.name,
      type = _get.type;
    var mapTo = deprecationInfo.mapTo;
    var actualName = name || (type === null || type === void 0 ? void 0 : type.name);
    var nestedProperties = {};
    var result = _objectSpread({}, accumulator);
    var transform = get(transformMap, propKey);
    var newValue = transform ? transform(value) : value;
    if (mapTo) {
      var _context6, _newPropInfo$type;
      var omit = accumulator[propKey],
        accWithoutKey = _objectWithoutProperties(accumulator, _mapInstanceProperty(_context6 = [propKey]).call(_context6, _toPropertyKey));
      result = _objectSpread({}, accWithoutKey);
      var newPropInfo = get(propsInfo, mapTo);
      var newName = (newPropInfo === null || newPropInfo === void 0 ? void 0 : newPropInfo.name) || (newPropInfo === null || newPropInfo === void 0 ? void 0 : (_newPropInfo$type = newPropInfo.type) === null || _newPropInfo$type === void 0 ? void 0 : _newPropInfo$type.name);
      if (newName !== actualName) {
        error("The deprecated prop \"".concat(propKey, "\" has been mapped to the new prop \"").concat(mapTo, "\" but the type does not match"));
      }
      set(result, mapTo, newValue);
    } else if (actualName === 'shape') {
      nestedProperties = transformProps({
        props: props[key],
        propsInfo: propsInfo,
        displayName: displayName,
        transformMap: transformMap,
        parentKey: key
      });
    } else {
      set(result, propKey, newValue);
    }
    return _objectSpread({}, result, {}, nestedProperties);
  }, {});
}
export function transformDeprecatedProps(props, docgenInfo) {
  var transformMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (isEmpty(docgenInfo)) return props;
  var _docgenInfo$props = docgenInfo.props,
    propsInfo = _docgenInfo$props === void 0 ? {} : _docgenInfo$props,
    displayName = docgenInfo.displayName;
  return isEmpty(propsInfo) ? props : transformProps({
    props: props,
    propsInfo: flattenPropsInfo(propsInfo),
    displayName: displayName,
    transformMap: transformMap
  });
}
export var withTransformedProps = function withTransformedProps(Component) {
  var transformMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var WithTransformedProps = function WithTransformedProps(props) {
    var transformedProps = transformDeprecatedProps(props, Component === null || Component === void 0 ? void 0 : Component.__docgenInfo, transformMap);
    return React.createElement(Component, _extends({}, props, transformedProps));
  };
  WithTransformedProps.displayName = "withTransformedProps(".concat(getDisplayName(Component), ")");
  WithTransformedProps.WrappedComponent = Component;
  WithTransformedProps.propTypes = Component.propTypes;
  return WithTransformedProps;
};