import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getVisiblePages } from './utils';
import { messages } from './Pagination.messages';
import styles from "./Pagination.module.css";
import { PaginationSelect } from './PaginationSelect';
import { NavButton } from './NavButton';
function getNumberOfPages(pageSize, totalRows) {
  return Math.ceil(totalRows / pageSize);
}
export var PageNumbers = function PageNumbers(_ref) {
  var id = _ref.id,
    numberOfRows = _ref.numberOfRows,
    pageSize = _ref.pageSize,
    onPageChange = _ref.onPageChange,
    page = _ref.page,
    canPrevious = _ref.canPrevious,
    canNext = _ref.canNext;
  var totalPages = getNumberOfPages(pageSize, numberOfRows);
  var displayPageNumbers = totalPages > 1;
  var handleChangePage = function handleChangePage(newPage) {
    if (newPage === page) {
      return;
    }
    onPageChange(newPage);
  };
  var renderPageButton = function renderPageButton(visiblePage, index, visiblePages) {
    var displayDots = index !== visiblePages.length - 1 && visiblePages[index + 1] - visiblePage !== 1;
    var availableValues = [];
    if (displayDots) {
      for (var i = visiblePages[index]; i < visiblePages[index + 1] - 1; i += 1) {
        availableValues.push({
          code: i,
          name: i + 1
        });
      }
    }
    return React.createElement(React.Fragment, {
      key: visiblePage
    }, React.createElement(NavButton, {
      onClick: function onClick() {
        return handleChangePage(visiblePage - 1);
      },
      className: cx(_defineProperty({}, styles.selectedPageButton, page === visiblePage - 1))
    }, visiblePage), displayDots && React.createElement(PaginationSelect, {
      id: "".concat(id, "-").concat(visiblePage + 1),
      onValueChange: function onValueChange(target) {
        return handleChangePage(Number(target));
      },
      availableValues: availableValues,
      displayValue: "..."
    }));
  };
  var visiblePages = getVisiblePages(page, totalPages);
  var visiblePagesButtons = React.createElement("div", {
    className: styles.pageButtonsWrapper
  }, _mapInstanceProperty(visiblePages).call(visiblePages, renderPageButton));
  return displayPageNumbers && React.createElement("div", {
    className: styles.paginationNav,
    "data-testid": "pagination-nav"
  }, canPrevious && React.createElement(NavButton, {
    onClick: function onClick() {
      return handleChangePage(page - 1);
    },
    icon: "mi-chevron-left",
    iconPosition: "left"
  }, messages.previous), visiblePagesButtons, canNext && React.createElement(NavButton, {
    onClick: function onClick() {
      return handleChangePage(page + 1);
    },
    icon: "mi-chevron-right",
    iconPosition: "right"
  }, messages.next));
};
PageNumbers.defaultProps = {
  page: 0
};
PageNumbers.propTypes = {
  id: PropTypes.string.isRequired,
  canNext: PropTypes.bool,
  canPrevious: PropTypes.bool,
  page: PropTypes.number,
  numberOfRows: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func
};
PageNumbers.__docgenInfo = {
  componentName: "PageNumbers",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "PageNumbers",
  methods: [],
  actualName: "PageNumbers",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    canNext: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true` 'nextNavButton' button is rendered"
    },
    canPrevious: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true` 'prevNavButton' button is rendered"
    },
    page: {
      type: {
        name: "number"
      },
      required: false,
      description: "Current page",
      defaultValue: {
        value: "0",
        computed: false
      }
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: true,
      description: "Total rows number"
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: true,
      description: "Number of items per single page"
    },
    onPageChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback used to handle page change"
    }
  }
};