import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { messages } from './Pagination.messages';
import styles from "./Pagination.module.css";
import { PaginationSelect } from './PaginationSelect';
export var SHOW_ALL_PAGES = 'All';
export var PageSizes = function PageSizes(_ref) {
  var id = _ref.id,
    numberOfRows = _ref.numberOfRows,
    onSelectPageSizeChange = _ref.onSelectPageSizeChange,
    pageSize = _ref.pageSize,
    pageSizeOptions = _ref.pageSizeOptions,
    pageSizeSelectedOption = _ref.pageSizeSelectedOption;
  var _context;
  var translator = useContext(TranslatorContext);
  var options = _mapInstanceProperty(_context = _filterInstanceProperty(pageSizeOptions).call(pageSizeOptions, function (option) {
    return option < numberOfRows;
  })).call(_context, function (option) {
    return {
      code: option,
      name: option
    };
  });
  var transformedPageSizeOptions = _concatInstanceProperty(options).call(options, {
    code: SHOW_ALL_PAGES,
    name: translator(messages.showAll)
  });
  var displayPageSize = !(numberOfRows < pageSize);
  return displayPageSize && React.createElement("div", {
    className: styles.pageSizeSelectWrapper
  }, React.createElement(PaginationSelect, {
    id: "".concat(id, "_pageSizeSelect"),
    onValueChange: onSelectPageSizeChange,
    value: pageSizeSelectedOption,
    availableValues: transformedPageSizeOptions
  }), React.createElement("div", {
    className: styles.resultOfMessage
  }, React.createElement("span", null, translator(messages.resultOf), " "), numberOfRows));
};
PageSizes.propTypes = {
  id: PropTypes.string.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizeSelectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelectPageSizeChange: PropTypes.func
};
PageSizes.__docgenInfo = {
  componentName: "PageSizes",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "PageSizes",
  methods: [],
  actualName: "PageSizes",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    pageSizeOptions: {
      type: {
        name: "array"
      },
      required: true,
      description: "List of page size options"
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: true,
      description: "Total rows number"
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: true,
      description: "Number of items per single page"
    },
    pageSizeSelectedOption: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Number of records per single page"
    },
    onSelectPageSizeChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback used to handle page size change"
    }
  }
};