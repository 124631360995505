import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useCallback } from 'react';
import { useKeyPress } from './useKeyPress.js';
export function useKeyActive(ref) {
  var targetKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['Enter', ' '];
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isKeyActive = _useState2[0],
    setIsKeyActive = _useState2[1];
  var handleKeyActive = useCallback(function (event, isKeyDown) {
    if (isKeyDown) {
      setIsKeyActive(true);
    } else {
      setIsKeyActive(false);
    }
  }, []);
  useKeyPress(ref, targetKeys, handleKeyActive);
  return isKeyActive;
}