import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import units from 'units-css';
var extendPhone = function extendPhone(props) {
  return _objectSpread({}, props, {}, props.tablet, {}, props.phoneWide, {}, props.phone);
};
var extendPhoneWide = function extendPhoneWide(props) {
  return _objectSpread({}, props, {}, props.tablet, {}, props.phoneWide);
};
var extendTablet = function extendTablet(props) {
  return _objectSpread({}, props, {}, props.tablet);
};
var identity = function identity(x) {
  return x;
};
export var breakpointExtensions = {
  phone: extendPhone,
  phoneWide: extendPhoneWide,
  tablet: extendTablet,
  desktop: identity
};
export var extendWithBreakpointValues = function extendWithBreakpointValues(props, breakpoint) {
  return (breakpointExtensions[breakpoint] || identity)(props);
};
var getDeviceDefinitionElement = function getDeviceDefinitionElement(rootElement) {
  var _document, _root$querySelector;
  var root = rootElement || ((_document = document) === null || _document === void 0 ? void 0 : _document.body);
  return (_root$querySelector = root.querySelector('.themeRoot')) !== null && _root$querySelector !== void 0 ? _root$querySelector : root;
};
export var getBreakpointPxValue = function getBreakpointPxValue(device, rootElement) {
  var element = getDeviceDefinitionElement(rootElement);
  var breakpointValue = typeof getComputedStyle === 'function' && getComputedStyle(element).getPropertyValue("--GW-BREAKPOINT-".concat(device.toUpperCase()));
  return breakpointValue && units.convert('px', breakpointValue, element);
};
export var getBreakpoints = function getBreakpoints(rootElement) {
  return {
    phone: getBreakpointPxValue('phone', rootElement) || 580,
    phoneWide: getBreakpointPxValue('phoneWide', rootElement) || 768,
    tablet: getBreakpointPxValue('tablet', rootElement) || 1200
  };
};