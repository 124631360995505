import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEqual from "lodash/isEqual";
import { log } from '@jutro/logger';
import { createContextConsumerHOC, logDeprecationMessage } from '@jutro/platform';
import { defaultThemeConfig } from '@jutro/theme-styles';
import { DynamicStyleSheet } from './DynamicStyleSheet';
import { parseThemeConfig } from './parseThemeHelper';
var customStyleSheet = new DynamicStyleSheet();
export var THEMEPROVIDER_DEFAULT = defaultThemeConfig.name;
export var defaultTheme = {
  name: THEMEPROVIDER_DEFAULT,
  switchTheme: function switchTheme() {
    log.warning('You should not use "theme.switchTheme()" outside a <ThemeProvider>');
  }
};
export var ThemeContext = React.createContext(defaultTheme);
export function withTheme(ComponentToWrap) {
  log.warning('You should use ThemeContext.Consumer directly, or alternatively "renderWithTheme(renderProp)" to consume theme without using a HOC');
  return createContextConsumerHOC({
    component: ComponentToWrap,
    context: ThemeContext,
    displayName: "Themed".concat(ComponentToWrap.displayName || ComponentToWrap.name),
    mapContextToProps: function mapContextToProps(existingProps, context) {
      return {
        theme: context
      };
    }
  });
}
export function renderWithTheme(renderProp) {
  return React.createElement(ThemeContext.Consumer, null, function (theme) {
    return renderProp(theme);
  });
}
export var ThemeProvider = function (_Component) {
  _inherits(ThemeProvider, _Component);
  var _super = _createSuper(ThemeProvider);
  function ThemeProvider(props) {
    var _this;
    _classCallCheck(this, ThemeProvider);
    _this = _super.call(this, props);
    _defineProperty(_assertThisInitialized(_this), "switchTheme", function (newConfig) {
      if (_this.state.config !== newConfig) {
        var newState = _this.createStateFromConfig(newConfig);
        _this.setState(newState, function () {
          _this.removeDynamicStylesheet();
          _this.createDynamicStylesheet();
          if (_this.useBodyStyles) {
            _this.applyRootStyleToBody(_this.getRootStyle());
          }
        });
      }
    });
    _defineProperty(_assertThisInitialized(_this), "createStateFromConfig", function (config) {
      var _ref = parseThemeConfig(config) || {},
        classNames = _ref.classNames,
        styles = _ref.styles;
      var theme = {
        name: config ? config.name : THEMEPROVIDER_DEFAULT,
        switchTheme: _this.switchTheme
      };
      return {
        classNames: classNames,
        config: config,
        styles: styles,
        theme: theme
      };
    });
    _defineProperty(_assertThisInitialized(_this), "getRootStyle", function () {
      var classNames = _this.state.classNames;
      return classNames ? classNames.root : undefined;
    });
    _this.state = _this.createStateFromConfig(props.initialConfig);
    return _this;
  }
  _createClass(ThemeProvider, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.createDynamicStylesheet();
      if (!this.props.applyLocally && ThemeProvider.bodyStylesMounted) {
        log.warning("Only a single ThemeProvider's styles set may be applied to document's body. Consider applying locally.");
      }
      if (this.useBodyStyles) {
        ThemeProvider.bodyStylesMounted = true;
        if (!this._bodyClasses) {
          this._bodyClasses = document.body.className;
        }
        this.applyRootStyleToBody(this.getRootStyle());
        this.forceUpdate();
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this$props = this.props,
        className = _this$props.className,
        initialConfig = _this$props.initialConfig;
      var prevClassName = prevProps.className,
        prevConfig = prevProps.initialConfig;
      this.updateCreatedStateIfNeeded(initialConfig, prevConfig);
      if (this.useBodyStyles && prevClassName !== className) {
        this.applyRootStyleToBody(this.getRootStyle());
      }
      if (!initialConfig || initialConfig === prevConfig) {
        return;
      }
      var dropTarget = initialConfig.dropTarget;
      if (!dropTarget) {
        return;
      }
      logDeprecationMessage("ThemeProvider: ".concat(initialConfig.name, " Theme"), undefined, undefined, dropTarget);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.useBodyStyles) {
        document.body.className = this._bodyClasses;
        this._bodyClasses = undefined;
        ThemeProvider.bodyStylesMounted = false;
      }
      this.removeDynamicStylesheet();
    }
  }, {
    key: "createDynamicStylesheet",
    value: function createDynamicStylesheet() {
      var styles = this.state.styles;
      if (styles) {
        this.stylesheet = customStyleSheet;
        this.stylesheet.mount();
        this.stylesheet.applyRules(styles);
      }
    }
  }, {
    key: "removeDynamicStylesheet",
    value: function removeDynamicStylesheet() {
      if (this.stylesheet) {
        this.stylesheet.unmount();
        this.stylesheet = null;
      }
    }
  }, {
    key: "updateCreatedStateIfNeeded",
    value: function updateCreatedStateIfNeeded(initialConfig, prevConfig) {
      if (!isEqual(initialConfig, prevConfig)) {
        this.setState(this.createStateFromConfig(initialConfig));
      }
    }
  }, {
    key: "applyRootStyleToBody",
    value: function applyRootStyleToBody(rootStyle) {
      document.body.className = cx(this._bodyClasses, this.themeRootStyles, rootStyle);
    }
  }, {
    key: "renderContent",
    value: function renderContent() {
      var children = this.props.children;
      if (this.useBodyStyles) {
        return children;
      }
      return React.createElement("div", {
        className: cx(this.themeRootStyles, this.getRootStyle())
      }, children);
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(ThemeContext.Provider, {
        value: this.state.theme
      }, this.renderContent());
    }
  }, {
    key: "useBodyStyles",
    get: function get() {
      if (false) {
        return false;
      }
      return !(this.props.applyLocally || ThemeProvider.bodyStyleMounted);
    }
  }, {
    key: "themeRootStyles",
    get: function get() {
      return ['themeRoot', this.props.className];
    }
  }]);
  return ThemeProvider;
}(Component);
_defineProperty(ThemeProvider, "propTypes", {
  initialConfig: PropTypes.shape({
    name: PropTypes.string.isRequired,
    rootStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    componentStyles: PropTypes.object
  }),
  className: PropTypes.string,
  children: PropTypes.node,
  applyLocally: PropTypes.bool
});
_defineProperty(ThemeProvider, "bodyStylesMounted", false);
renderWithTheme.__docgenInfo = {
  componentName: "renderWithTheme",
  packageName: "@jutro/theme",
  description: "Render by invoking a render prop with 'theme'.\nThis is an alternative to using ThemeContext.Consumer directly. Useful for consuming without\nusing a HOC.",
  displayName: "renderWithTheme",
  methods: [],
  actualName: "renderWithTheme"
};
ThemeProvider.__docgenInfo = {
  componentName: "ThemeProvider",
  packageName: "@jutro/theme",
  description: "A ThemeProvider that can be placed in the app component hierarchy. It takes a theme configuration object\nand injects a 'theme' object into any consumers.",
  displayName: "ThemeProvider",
  methods: [{
    name: "useBodyStyles",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "themeRootStyles",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "switchTheme",
    docblock: "Set the theme for this theme provider by providing another theme configuration.\nThis is to change the theme after the initial load at runtime\n\n@param {object} newConfig - new theme configuration object",
    modifiers: [],
    params: [{
      name: "newConfig",
      description: "new theme configuration object",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Set the theme for this theme provider by providing another theme configuration.\nThis is to change the theme after the initial load at runtime"
  }, {
    name: "createStateFromConfig",
    docblock: "create state from config\n@param {object} config configuration for the ThemeProvider\n@returns {object} ThemeProvider state",
    modifiers: [],
    params: [{
      name: "config",
      description: "configuration for the ThemeProvider",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "ThemeProvider state",
      type: {
        name: "object"
      }
    },
    description: "create state from config"
  }, {
    name: "getRootStyle",
    docblock: "Get the root style from a theme. This should be a single string.\n\n@returns {string} root style from theme or default style",
    modifiers: [],
    params: [],
    returns: {
      description: "root style from theme or default style",
      type: {
        name: "string"
      }
    },
    description: "Get the root style from a theme. This should be a single string."
  }, {
    name: "createDynamicStylesheet",
    docblock: "Create dynamic style sheet for configuration styles",
    modifiers: [],
    params: [],
    returns: null,
    description: "Create dynamic style sheet for configuration styles"
  }, {
    name: "removeDynamicStylesheet",
    docblock: "Remove dynamic style sheet",
    modifiers: [],
    params: [],
    returns: null,
    description: "Remove dynamic style sheet"
  }, {
    name: "updateCreatedStateIfNeeded",
    docblock: null,
    modifiers: [],
    params: [{
      name: "initialConfig",
      optional: false,
      type: null
    }, {
      name: "prevConfig",
      optional: false,
      type: null
    }],
    returns: null
  }, {
    name: "applyRootStyleToBody",
    docblock: "Apply root style to 'body' element\n@param {string} rootStyle - root class to apply",
    modifiers: [],
    params: [{
      name: "rootStyle",
      description: "root class to apply",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: null,
    description: "Apply root style to 'body' element"
  }, {
    name: "renderContent",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "ThemeProvider",
  props: {
    initialConfig: {
      type: {
        name: "shape",
        value: {
          name: {
            name: "string",
            required: true
          },
          rootStyle: {
            name: "union",
            value: [{
              name: "object"
            }, {
              name: "string"
            }],
            required: false
          },
          componentStyles: {
            name: "object",
            required: false
          }
        }
      },
      required: false,
      description: "The initial state configuration object"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional component styling class name"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The component children wrapped by the theme provider"
    },
    applyLocally: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for applying styles locally"
    }
  }
};