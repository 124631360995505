import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
export var SliderHandle = function (_Component) {
  _inherits(SliderHandle, _Component);
  var _super = _createSuper(SliderHandle);
  function SliderHandle() {
    var _this;
    _classCallCheck(this, SliderHandle);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "setHandle", function (handle) {
      _this.handle = handle;
    });
    _defineProperty(_assertThisInitialized(_this), "handleMouseDown", function () {
      _this.focus();
    });
    return _this;
  }
  _createClass(SliderHandle, [{
    key: "clickFocus",
    value: function clickFocus() {
      this.focus();
    }
  }, {
    key: "focus",
    value: function focus() {
      this.handle.focus();
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        id = _this$props.id,
        min = _this$props.min,
        max = _this$props.max,
        value = _this$props.value,
        offset = _this$props.offset,
        disabled = _this$props.disabled,
        className = _this$props.className,
        tooltipClassName = _this$props.tooltipClassName,
        labelId = _this$props.labelId;
      var tabIndex = disabled ? undefined : '0';
      var style = {
        left: "".concat(offset, "%")
      };
      return React.createElement("div", {
        id: id,
        tabIndex: tabIndex,
        className: className,
        style: style,
        role: "slider",
        "aria-valuemin": min,
        "aria-valuemax": max,
        "aria-valuenow": value,
        "aria-disabled": disabled,
        "aria-labelledby": labelId,
        "aria-orientation": "horizontal",
        name: "slider",
        ref: this.setHandle,
        onMouseDown: this.handleMouseDown
      }, React.createElement("output", {
        className: tooltipClassName
      }, React.createElement(FormattedNumber, {
        value: value
      })));
    }
  }]);
  return SliderHandle;
}(Component);
_defineProperty(SliderHandle, "propTypes", {
  id: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  labelId: PropTypes.string
});
_defineProperty(SliderHandle, "defaultProps", {
  disabled: false,
  step: 1
});
SliderHandle.__docgenInfo = {
  componentName: "SliderHandle",
  packageName: "@jutro/components",
  description: "",
  displayName: "SliderHandle",
  methods: [{
    name: "setHandle",
    docblock: null,
    modifiers: [],
    params: [{
      name: "handle",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleMouseDown",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "clickFocus",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "focus",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "SliderHandle",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: ""
    },
    min: {
      type: {
        name: "number"
      },
      required: true,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: true,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "number"
      },
      required: true,
      description: "Current slider value (to create fully controlled component)"
    },
    step: {
      type: {
        name: "number"
      },
      required: false,
      description: "Step between consecutive values",
      defaultValue: {
        value: "1",
        computed: false
      }
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is disabled",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the component"
    },
    tooltipClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the component's tooltip"
    },
    labelId: {
      type: {
        name: "string"
      },
      required: false,
      description: "Id of the element that labels the slider handle component."
    }
  }
};