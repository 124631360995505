import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./Footer.module.css";
var footerTextPropTypes = {
  className: PropTypes.string,
  text: intlMessageShape
};
export var FooterText = function FooterText(_ref) {
  var text = _ref.text,
    className = _ref.className;
  var translator = useContext(TranslatorContext);
  if (_.isEmpty(text)) {
    return null;
  }
  var classes = cx(styles.footerText, className);
  return React.createElement("div", {
    className: classes
  }, translator(text));
};
FooterText.propTypes = footerTextPropTypes;
FooterText.__docgenInfo = {
  componentName: "FooterText",
  packageName: "@jutro/components",
  description: "The `FooterText` component is designed as a simple component presenting text inside the `Footer` container.",
  displayName: "FooterText",
  methods: [],
  actualName: "FooterText",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    text: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Text to render"
    }
  }
};