import _ from 'lodash';
import { log } from '@jutro/logger';
var defaultErrorCategory = {};
var errorCategories;
var errorCategoryKnockoutPaths;
function getErrorCategoryKnockoutPath(errorCategory, knockoutPaths) {
  var knockoutPath = knockoutPaths[errorCategory];
  if (!knockoutPath) {
    log.warning("Missing KnockoutPath configuration for error category: ".concat(errorCategory));
  }
  return knockoutPath;
}
export function getErrorCategory(errorCode) {
  if (errorCategories && errorCategories[errorCode]) {
    var errorCategory = errorCategories[errorCode];
    var categoryMapping = errorCategory.categoryMapping,
      appErrorCode = errorCategory.appErrorCode;
    var knockoutPath = getErrorCategoryKnockoutPath(categoryMapping, errorCategoryKnockoutPaths);
    return {
      categoryMapping: categoryMapping,
      appErrorCode: appErrorCode,
      knockoutPath: knockoutPath
    };
  }
  return defaultErrorCategory;
}
export function setErrorCategoryKnockoutMaps(appErrorCategories, appKnockoutPaths) {
  if (!_.isObject(appErrorCategories)) {
    throw new TypeError("Expected an object for 'errorCategories', got a '".concat(typeof appErrorCategories, "' instead"));
  }
  errorCategories = appErrorCategories;
  if (!_.isObject(appKnockoutPaths)) {
    throw new TypeError("Expected an object for 'knockoutPaths', got a '".concat(typeof appKnockoutPaths, "' instead"));
  }
  errorCategoryKnockoutPaths = appKnockoutPaths;
}