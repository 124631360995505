import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _slicedToArray2 from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import React, { useRef, useCallback, useState } from 'react';
import isFunction from "lodash/isFunction";
export function bindActionCreators(dispatch) {
  var actions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _context;
  return _reduceInstanceProperty(_context = _Object$entries(actions)).call(_context, function (acc, _ref) {
    var _ref2 = _slicedToArray2(_ref, 2),
      name = _ref2[0],
      action = _ref2[1];
    return _objectSpread({}, acc, _defineProperty({}, name, function () {
      return dispatch(action.apply(void 0, arguments));
    }));
  }, {});
}
export function useControlledReducer(_ref3) {
  var reducer = _ref3.reducer,
    initialState = _ref3.initialState,
    controlledState = _ref3.controlledState,
    onStateChange = _ref3.onStateChange,
    _ref3$updateInControl = _ref3.updateInControlledMode,
    updateInControlledMode = _ref3$updateInControl === void 0 ? false : _ref3$updateInControl;
  var store = useRef({
    state: initialState
  }).current;
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    setState = _useState2[1];
  var update = useCallback(function () {
    return setState(function (state) {
      return state + 1;
    });
  }, []);
  if (controlledState !== undefined) {
    store.state = isFunction(controlledState) ? controlledState(store.state) : controlledState;
  }
  store.reducer = reducer;
  store.dispatch = function (action) {
    var oldState = store.state;
    var newState = store.reducer(oldState, action);
    if (newState === oldState) {
      return;
    }
    if (onStateChange) {
      onStateChange(newState);
    }
    if (!controlledState || updateInControlledMode) {
      store.state = newState;
      update();
    }
  };
  var dispatch = useCallback(function (action) {
    return store.dispatch(action);
  }, [store]);
  return [store.state, dispatch];
}