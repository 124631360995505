import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape, chevronAlignProptypes, deprecated } from '@jutro/prop-types';
import { getMessageProp } from '@jutro/platform';
import styles from "./Chevron.module.css";
import { Icon } from '../Icon/Icon';
import { messages } from './Chevron.messages';
var alignMap = {
  left: styles.chevronLeft,
  right: styles.chevronRight
};
var chevronPropTypes = {
  className: PropTypes.string,
  chevronIcon: PropTypes.string,
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  align: deprecated(chevronAlignProptypes, '6.0.0', 'Please align by changing position in markup or applying style by yourself'),
  messageProps: PropTypes.shape({
    label: intlMessageShape
  })
};
var ChevronInternal = function ChevronInternal(_ref) {
  var className = _ref.className,
    chevronIcon = _ref.chevronIcon,
    isOpen = _ref.isOpen,
    align = _ref.align,
    messageProps = _ref.messageProps,
    other = _objectWithoutProperties(_ref, ["className", "chevronIcon", "isOpen", "align", "messageProps"]);
  var translator = useContext(TranslatorContext);
  var alignClass = align ? alignMap[align] : undefined;
  var accordionLabel = getMessageProp('label', messageProps, messages);
  var chevronClass = cx(alignClass, styles.chevron, _defineProperty({}, styles.open, isOpen), className);
  return React.createElement(Icon, _extends({
    icon: chevronIcon,
    className: chevronClass,
    role: "button",
    "aria-label": translator(accordionLabel),
    "aria-expanded": !!isOpen
  }, other));
};
ChevronInternal.propTypes = chevronPropTypes;
ChevronInternal.defaultProps = {
  chevronIcon: 'mi-chevron-right',
  isOpen: false
};
ChevronInternal.displayName = 'Chevron';
export var Chevron = memo(ChevronInternal);
ChevronInternal.__docgenInfo = {
  componentName: "Chevron",
  packageName: "@jutro/components",
  description: "The `Chevron` component is used to render the Chevron icon for an `Accordion` or `Card`.  This component is\nbuilt with optional props: `className`, `chevronIcon` and `isOpen`.  The optional `className` prop is used to provide any\ncustom styling to the chevron.  The optional `chevronIcon` prop will alter the chevron styling using a well-known,\npre-defined variant of the icon.  The optional `isOpen` prop, when `true`, will alter the style of the chevron icon\nto indicate the chevron is opened.\n\nProperties: {@link ChevronProps}",
  displayName: "Chevron",
  methods: [],
  actualName: "ChevronInternal",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    chevronIcon: {
      type: {
        name: "string"
      },
      required: false,
      description: "The optional icon of this chevron",
      defaultValue: {
        value: "'mi-chevron-right'",
        computed: false
      }
    },
    isOpen: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "object"
        }]
      },
      required: false,
      description: "Optional flag indicating whether this chevron is open",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    align: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }, {
          value: "'none'",
          computed: false
        }]
      },
      required: false,
      description: "@deprecated Optional property to align chevron to the right or left",
      deprecationInfo: {
        version: "6.0.0",
        mapTo: null
      }
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "union",
            description: "accordion icon aria-label",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    }
  }
};