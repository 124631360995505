import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape, linkShape } from '@jutro/prop-types';
import { PopoverContainer } from '../PopoverContainer/PopoverContainer';
import styles from "./NotificationAction.module.css";
import { Popover, availableAlignments } from '../Popover/Popover';
var notificationActionPropTypes = {
  id: PropTypes.string.isRequired,
  title: intlMessageShape,
  headerLink: linkShape,
  footerLink: linkShape,
  className: PropTypes.string,
  children: PropTypes.node,
  renderTrigger: PropTypes.func.isRequired,
  align: PropTypes.oneOf(availableAlignments),
  isFlipEnabled: PropTypes.bool
};
export var NotificationAction = function NotificationAction(_ref) {
  var id = _ref.id,
    renderTrigger = _ref.renderTrigger,
    align = _ref.align,
    isFlipEnabled = _ref.isFlipEnabled,
    popoverProps = _objectWithoutProperties(_ref, ["id", "renderTrigger", "align", "isFlipEnabled"]);
  return React.createElement(Popover, {
    id: id,
    renderTrigger: renderTrigger,
    align: align,
    className: styles.popper,
    isFlipEnabled: isFlipEnabled
  }, React.createElement(PopoverContainer, _objectSpread({
    className: styles.popoverContainer,
    internalClassNames: {
      title: styles.popoverTitle,
      headerLink: styles.popoverHeaderLink,
      body: styles.popoverBody,
      footerLink: styles.popoverFooterLink
    }
  }, popoverProps)));
};
NotificationAction.propTypes = notificationActionPropTypes;
NotificationAction.defaultProps = _objectSpread({}, Popover.defaultProps);
NotificationAction.__docgenInfo = {
  componentName: "NotificationAction",
  packageName: "@jutro/components",
  description: "NotificationAction",
  displayName: "NotificationAction",
  methods: [],
  actualName: "NotificationAction",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify this component."
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Notification popover title"
    },
    headerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: true
          },
          onClick: {
            name: "func",
            required: false
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: false
          }
        }
      },
      required: false,
      description: "Description for header link"
    },
    footerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: true
          },
          onClick: {
            name: "func",
            required: false
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: false
          }
        }
      },
      required: false,
      description: "Description for footer link"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Notification popover content"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: true,
      description: "Function to render trigger element"
    },
    align: {
      type: {
        name: "enum",
        computed: true,
        value: "availableAlignments"
      },
      required: false,
      description: "Popover alignment (relative to trigger element)"
    },
    isFlipEnabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should popover flip when it is about to overflow the visible area"
    }
  }
};