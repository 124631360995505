import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import React, { useRef, useEffect, useCallback } from 'react';
export function useKeyPress(ref, targetKeys, callback) {
  var memoizedKeys = useMemoizedArray(targetKeys);
  var memoizedCallback = useCallback(function (event) {
    if (_someInstanceProperty(memoizedKeys).call(memoizedKeys, function (key) {
      var _context;
      return _includesInstanceProperty(_context = [event.key, event.code]).call(_context, key);
    })) {
      callback(event, event.type === 'keydown');
    }
  }, [memoizedKeys, callback]);
  useEffect(function () {
    var node = ref.current;
    if (node) {
      node.addEventListener('keydown', memoizedCallback);
      node.addEventListener('keyup', memoizedCallback);
      return function () {
        node.removeEventListener('keydown', memoizedCallback);
        node.removeEventListener('keyup', memoizedCallback);
      };
    }
    return undefined;
  }, [ref, memoizedCallback]);
}
function useMemoizedArray(array) {
  var ref = useRef(array);
  if (!areEqual(array, ref.current)) {
    ref.current = array;
  }
  return ref.current;
}
function areEqual(array1, array2) {
  return array1.length === array2.length && _everyInstanceProperty(array1).call(array1, function (item, index) {
    return array2[index] === item;
  });
}