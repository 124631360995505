import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { getMessageProp } from '@jutro/platform';
import { AuthContext } from '@jutro/auth';
import styles from "./Avatar.module.css";
import { messages } from './Avatar.messages';
import { Icon } from '../Icon/Icon';
var getInitials = function getInitials(name) {
  if (!name) {
    return null;
  }
  var names = _trimInstanceProperty(name).call(name).split(/\s+/);
  var _names = _slicedToArray(names, 2),
    _names$ = _names[0],
    firstName = _names$ === void 0 ? '' : _names$,
    _names$2 = _names[1],
    secondName = _names$2 === void 0 ? '' : _names$2;
  var firstInitial = firstName[0] || '';
  var secondInitial = secondName[0] || '';
  return "".concat(firstInitial).concat(secondInitial);
};
var avatarPropTypes = {
  username: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  messageProps: PropTypes.shape({
    userAvatar: intlMessageShape
  }),
  useAuthInfo: PropTypes.bool,
  showImageBorder: PropTypes.bool
};
var avatarDefaultProps = {
  useAuthInfo: false,
  icon: 'person'
};
export var Avatar = React.forwardRef(function (props, ref) {
  var username = props.username,
    icon = props.icon,
    onClick = props.onClick,
    className = props.className,
    imageSource = props.imageSource,
    showImageBorder = props.showImageBorder,
    messageProps = props.messageProps,
    useAuthInfo = props.useAuthInfo,
    dangerouslySetInnerHTML = props.dangerouslySetInnerHTML,
    other = _objectWithoutProperties(props, ["username", "icon", "onClick", "className", "imageSource", "showImageBorder", "messageProps", "useAuthInfo", "dangerouslySetInnerHTML"]);
  var translator = useContext(TranslatorContext);
  var auth = useContext(AuthContext);
  var userAvatar = getMessageProp('userAvatar', messageProps, messages);
  function getUsername() {
    if (!useAuthInfo) {
      return username;
    }
    return _.get(auth, 'userInfo.name', username);
  }
  function getContent() {
    if (imageSource) {
      return React.createElement("img", {
        src: imageSource,
        className: styles.userImage,
        alt: username || translator(userAvatar)
      });
    }
    return getInitials(getUsername()) || icon && React.createElement(Icon, {
      icon: icon,
      "aria-label": username || translator(userAvatar)
    });
  }
  var classes = cx(styles.avatar, _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, styles.image, imageSource), styles.userImage, imageSource), styles.border, imageSource && showImageBorder), styles.withBackground, !imageSource), className);
  var title = getUsername() || translator(userAvatar);
  return React.createElement("button", _extends({
    ref: ref,
    type: "button",
    className: classes,
    onClick: onClick,
    "aria-label": title,
    title: ref ? null : title
  }, other), getContent(), React.createElement("div", {
    className: styles.overlay
  }));
});
Avatar.propTypes = avatarPropTypes;
Avatar.defaultProps = avatarDefaultProps;
Avatar.displayName = 'Avatar';
Avatar.__docgenInfo = {
  componentName: "Avatar",
  packageName: "@jutro/components",
  description: "Displays a \"user avatar\" element with an image, icon, or initials.",
  displayName: "Avatar",
  methods: [],
  actualName: "Avatar",
  metadataType: "action",
  props: {
    username: {
      type: {
        name: "string"
      },
      required: false,
      description: "Users first and last names, used as component alt text as well\nas to display initials if no image selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon name from the Font Awesome icon list to be displayed if no image or initials present",
      defaultValue: {
        value: "'person'",
        computed: false
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "On click event handler"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: false,
      description: "Path to user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "union",
            description: "userAvatar message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, display user information based on Okta instead of passed props",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, border will be display"
    }
  }
};