import React from 'react';
import find from "lodash/find";
import cx from 'classnames';
import { getMenuListId } from '../helpers';
export var GenericSelectControlMenuList = function GenericSelectControlMenuList(_ref) {
  var children = _ref.children,
    className = _ref.className,
    isMulti = _ref.isMulti,
    innerRef = _ref.innerRef,
    selectProps = _ref.selectProps;
  var _find;
  var menuIsOpen = selectProps.menuIsOpen,
    genericSelectStyles = selectProps.genericSelectStyles,
    id = selectProps.id;
  var menuListClasses = cx({
    'menu-list': true,
    'menu-list--is-multi': isMulti
  }, genericSelectStyles.menuList, className);
  var activeOptionId = (_find = find(children, function (child) {
    var _child$props;
    return child === null || child === void 0 ? void 0 : (_child$props = child.props) === null || _child$props === void 0 ? void 0 : _child$props.isFocused;
  })) === null || _find === void 0 ? void 0 : _find.key;
  return React.createElement("div", {
    id: getMenuListId(id),
    className: menuListClasses,
    ref: innerRef,
    role: "listbox",
    "aria-labelledby": selectProps['aria-labelledby'],
    "aria-activedescendant": activeOptionId,
    "aria-expanded": menuIsOpen,
    tabIndex: "0"
  }, children);
};
GenericSelectControlMenuList.__docgenInfo = {
  componentName: "GenericSelectControlMenuList",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlMenuList",
  methods: [],
  actualName: "GenericSelectControlMenuList"
};