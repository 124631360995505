import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import { uniqueInnerId } from '@jutro/platform';
import { Button } from '../../button/Button';
import styles from "./ConfirmationModal.module.css";
import { ModalNext } from '../ModalNext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { ModalBody } from '../ModalBody/ModalBody';
import { ModalFooter } from '../ModalFooter/ModalFooter';
import { messages } from './ConfirmationModal.messages.js';
var defaultConfirmButtonText = messages.confirmButtonText,
  defaultCancelButtonText = messages.cancelButtonText;
var confirmationModalPropTypes = {
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func.isRequired,
  parentSelector: PropTypes.func,
  icon: PropTypes.string,
  title: intlMessageShape.isRequired,
  message: intlMessageShape,
  status: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  confirmButtonText: intlMessageShape,
  cancelButtonText: intlMessageShape
};
export var ConfirmationModal = function ConfirmationModal(_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    parentSelector = _ref.parentSelector,
    title = _ref.title,
    message = _ref.message,
    status = _ref.status,
    icon = _ref.icon,
    confirmButtonText = _ref.confirmButtonText,
    cancelButtonText = _ref.cancelButtonText;
  var translator = useContext(TranslatorContext);
  var onClose = useCallback(function () {
    return onResolve(confirmationResult.CLOSE);
  }, [onResolve]);
  var onCancel = useCallback(function () {
    return onResolve(confirmationResult.CANCEL);
  }, [onResolve]);
  var onConfirm = useCallback(function () {
    return onResolve(confirmationResult.CONFIRM);
  }, [onResolve]);
  var _uniqueInnerId = uniqueInnerId('modalNext', 'titleId', 'descriptionId'),
    titleId = _uniqueInnerId.titleId,
    descriptionId = _uniqueInnerId.descriptionId;
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onClose,
    parentSelector: parentSelector,
    overlayClassName: styles.overlayConfirmation,
    shouldFocusAfterRender: true,
    ariaLabelledby: titleId,
    ariaDescribedby: descriptionId
  }, React.createElement(ModalHeader, {
    icon: icon,
    title: title,
    onClose: onClose,
    status: status,
    titleId: titleId,
    ariaLabel: translator(messages.closeIconButtonAriaLabel)
  }), React.createElement(ModalBody, {
    id: descriptionId
  }, translator(message)), React.createElement(ModalFooter, null, cancelButtonText && React.createElement(Button, {
    type: "text",
    onClick: onCancel
  }, translator(cancelButtonText)), React.createElement(Button, {
    type: "filled",
    onClick: onConfirm
  }, translator(confirmButtonText))));
};
ConfirmationModal.propTypes = confirmationModalPropTypes;
ConfirmationModal.defaultProps = {
  confirmButtonText: defaultConfirmButtonText,
  cancelButtonText: defaultCancelButtonText
};
export var confirmationResult = {
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
  CLOSE: 'close'
};
ConfirmationModal.__docgenInfo = {
  componentName: "ConfirmationModal",
  packageName: "@jutro/components",
  description: "ConfirmationModal",
  displayName: "ConfirmationModal",
  methods: [],
  actualName: "ConfirmationModal",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Boolean denoting if the modal is open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: true,
      description: "Callback function called when the modal is confirmed, cancelled or closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to find the modal's parent selector"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Material Icon name (mi-*)"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Text to display for the title"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message to be displayed in the modal"
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: false
        }, {
          value: "'warning'",
          computed: false
        }, {
          value: "'error'",
          computed: false
        }, {
          value: "'success'",
          computed: false
        }]
      },
      required: false,
      description: "The status of the warning. One of 'info', 'warning', 'error', 'success' or blank for default."
    },
    confirmButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The text or message shape of the confirm button",
      defaultValue: {
        value: "messages.confirmButtonText",
        computed: true
      }
    },
    cancelButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The text or message shape of the cancel button",
      defaultValue: {
        value: "messages.cancelButtonText",
        computed: true
      }
    }
  }
};