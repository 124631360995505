import _setTimeout from "@babel/runtime-corejs3/core-js-stable/set-timeout";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useEffect, useState } from 'react';
export function useDebounce(value, delay) {
  var _useState = useState(value),
    _useState2 = _slicedToArray(_useState, 2),
    debouncedValue = _useState2[0],
    setDebouncedValue = _useState2[1];
  useEffect(function () {
    var handler = _setTimeout(function () {
      setDebouncedValue(value);
    }, delay);
    return function () {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}