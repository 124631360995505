import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import PropTypes from 'prop-types';
import { Layout, layoutShape } from './Layout';
export var PanelLayout = function PanelLayout(_ref2) {
  var id = _ref2.id,
    children = _ref2.children,
    className = _ref2.className,
    layout = _ref2.layout;
  var _ref = layout || {},
    desktop = _ref.desktop,
    tablet = _ref.tablet,
    phoneWide = _ref.phoneWide,
    phone = _ref.phone;
  return React.createElement(Layout, {
    id: id,
    layout: {
      desktop: _objectSpread({
        columns: ['1fr'],
        repeat: '8',
        gap: 'large',
        colStart: '2',
        colSpan: '6'
      }, desktop),
      tablet: _objectSpread({
        repeat: '6',
        gap: 'medium',
        colStart: '2',
        colSpan: '4'
      }, tablet),
      phoneWide: _objectSpread({
        repeat: '6',
        gap: 'medium',
        colStart: '2',
        colSpan: '4'
      }, phoneWide),
      phone: _objectSpread({
        repeat: '4',
        gap: 'medium',
        colStart: '1',
        colSpan: '4'
      }, phone)
    },
    className: className
  }, children);
};
PanelLayout.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  layout: PropTypes.shape({
    desktop: layoutShape,
    tablet: layoutShape,
    phoneWide: layoutShape,
    phone: layoutShape
  })
};
PanelLayout.__docgenInfo = {
  componentName: "PanelLayout",
  packageName: "@jutro/components",
  description: "",
  displayName: "PanelLayout",
  methods: [],
  actualName: "PanelLayout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify the component"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "children; preferably 'GridItem'; works with any child"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class(es) to add to the grid tag"
    },
    layout: {
      type: {
        name: "shape",
        value: {
          desktop: {
            name: "shape",
            description: "Optional props to override default layout",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          },
          tablet: {
            name: "shape",
            description: "Optional props to override layout on tablet",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          },
          phoneWide: {
            name: "shape",
            description: "Optional props to override layout on phoneWide",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          },
          phone: {
            name: "shape",
            description: "Optional props to override layout on phone",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          }
        }
      },
      required: false,
      description: ""
    }
  }
};