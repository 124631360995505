import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
export default {
  create: function create(config) {
    return function (event) {
      var _context;
      return _reduceInstanceProperty(_context = _Object$keys(event)).call(_context, function (result, key) {
        var _context2;
        if (config !== null && config !== void 0 && config.allowedProperties && _indexOfInstanceProperty(_context2 = config.allowedProperties).call(_context2, key) !== -1) {
          result[key] = event[key];
          return result;
        }
        result[key] = config.obfuscationString;
        return result;
      }, {});
    };
  }
};