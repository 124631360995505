import _defineProperty2 from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { uniqueInnerId } from '@jutro/platform';
import { Grid } from '@jutro/layout';
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING, intlMessageShape } from '@jutro/prop-types';
import { getOptionCode, getOptionName } from '../availableValues';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { RadioButtonCard, LAYOUT_PROP_TYPE, PORTRAIT_LAYOUT } from './RadioButtonCard';
import styles from "./RadioButtonCardField.module.css";
import { makeHandleRadioButtonKeyDown, getTabIndex } from '../../../radiobuttonUtils';
export var RadioButtonCardField = function (_FieldComponent) {
  _inherits(RadioButtonCardField, _FieldComponent);
  var _super = _createSuper(RadioButtonCardField);
  function RadioButtonCardField() {
    _classCallCheck(this, RadioButtonCardField);
    return _super.apply(this, arguments);
  }
  _createClass(RadioButtonCardField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(RadioButtonCardField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _this = this;
      var id = breakpointProps.id,
        availableValues = breakpointProps.availableValues,
        disabled = breakpointProps.disabled,
        label = breakpointProps.label,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        renderName = breakpointProps.renderName,
        renderSecondaryLabel = breakpointProps.renderSecondaryLabel,
        layout = breakpointProps.layout;
      var translator = this.translator;
      var selectedValue = this.getDataTypeAwareSelectedValue();
      var radios = _mapInstanceProperty(availableValues).call(availableValues, function (option, i) {
        var secondaryLabel = option.secondaryLabel,
          moneyAmount = option.moneyAmount,
          iconName = option.iconName,
          currency = option.currency,
          iconSrc = option.iconSrc,
          iconClassName = option.iconClassName,
          iconContainerClassName = option.iconContainerClassName;
        var optionCode = getOptionCode(option);
        var optionName = getOptionName(option);
        var checked = selectedValue === optionCode;
        var cardId = uniqueInnerId(id, optionCode)[optionCode];
        return React.createElement(RadioButtonCard, _extends({
          iconName: iconName,
          layout: layout,
          key: cardId,
          id: cardId,
          iconSrc: iconSrc,
          iconClassName: iconClassName,
          tabIndex: getTabIndex(i),
          iconContainerClassName: iconContainerClassName,
          code: optionCode,
          name: renderName(optionName, translator),
          secondaryLabel: renderSecondaryLabel(secondaryLabel, translator),
          moneyAmount: moneyAmount,
          currency: currency,
          disabled: disabled,
          checked: checked,
          required: required,
          onValueSelect: _this.handleAvailableValuesValueChange,
          styles: styles
        }, _this.generateAccessibilityProperties(), _this.generateDataPathProperty()));
      });
      var gridProps = layout === 'landscape' ? {} : {
        columns: ['1fr'],
        repeat: '3',
        phone: {
          repeat: '2'
        }
      };
      var classes = cx(styles.radioButtonCard, controlClassName);
      var triggerFocus = function triggerFocus(el, value) {
        el.querySelector("div[data-value=".concat(value, "] div[class*=__radioWrapper]")).focus();
      };
      var handleRadioButtonKeyDown = makeHandleRadioButtonKeyDown(triggerFocus);
      return React.createElement(Grid, _extends({
        id: id,
        className: classes,
        "aria-label": translator(label),
        role: "radiogroup",
        onKeyDown: function onKeyDown(event) {
          return handleRadioButtonKeyDown(event, _this.props, _this.handleAvailableValuesValueChange);
        },
        gap: "large"
      }, gridProps), radios);
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      return this.renderControl(breakpointProps);
    }
  }]);
  return RadioButtonCardField;
}(FieldComponent);
_defineProperty(RadioButtonCardField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(PropTypes.shape({
    iconName: PropTypes.string,
    iconSrc: PropTypes.string,
    iconClassName: PropTypes.string,
    iconContainerClassName: PropTypes.string,
    id: PropTypes.string.isRequired,
    displayName: intlMessageShape,
    secondaryLabel: intlMessageShape.isRequired,
    moneyAmount: PropTypes.number
  })),
  renderName: PropTypes.func,
  renderSecondaryLabel: PropTypes.func,
  layout: LAYOUT_PROP_TYPE
}));
_defineProperty(RadioButtonCardField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  renderName: function renderName(name, translator) {
    return translator(name);
  },
  renderSecondaryLabel: function renderSecondaryLabel(secondaryLabel, translator) {
    return translator(secondaryLabel);
  },
  dataType: DATA_TYPE_STRING,
  layout: PORTRAIT_LAYOUT,
  availableValues: []
}));
_defineProperty(RadioButtonCardField, "contextType", FieldComponent.contextType);
RadioButtonCardField.__docgenInfo = {
  componentName: "RadioButtonCardField",
  packageName: "@jutro/components",
  description: "Renders a list of radio button cards. You specify the options using component props.",
  displayName: "RadioButtonCardField",
  methods: [{
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "RadioButtonCardField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "The selected value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "The default value to set if none is provided"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "The format of the value",
      defaultValue: {
        value: "'string'",
        computed: false
      }
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            iconName: {
              name: "string",
              required: false
            },
            iconSrc: {
              name: "string",
              required: false
            },
            iconClassName: {
              name: "string",
              required: false
            },
            iconContainerClassName: {
              name: "string",
              required: false
            },
            id: {
              name: "string",
              required: true
            },
            displayName: {
              name: "union",
              required: false,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: false
                  },
                  defaultMessage: {
                    name: "string",
                    required: false
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: false
                  }
                }
              }]
            },
            secondaryLabel: {
              name: "union",
              required: true,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: false
                  },
                  defaultMessage: {
                    name: "string",
                    required: false
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: false
                  }
                }
              }]
            },
            moneyAmount: {
              name: "number",
              required: false
            }
          }
        }
      },
      required: false,
      description: "Array of choice objects to display; choice objects contain 'code', 'name' and 'secondaryLabel'",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    renderName: {
      type: {
        name: "func"
      },
      required: false,
      description: "Custom render method for name prop. Translated property value by default",
      defaultValue: {
        value: "(name, translator) => translator(name)",
        computed: false
      }
    },
    renderSecondaryLabel: {
      type: {
        name: "func"
      },
      required: false,
      description: "Custom render method for secondary label prop. Translated property value by default",
      defaultValue: {
        value: "(secondaryLabel, translator) =>\n    translator(secondaryLabel)",
        computed: false
      }
    },
    layout: {
      type: {
        name: "enum",
        value: [{
          value: "'portrait'",
          computed: false
        }, {
          value: "'landscape'",
          computed: false
        }]
      },
      required: false,
      description: "Cards layout",
      defaultValue: {
        value: "'portrait'",
        computed: false
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};