import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { messages } from '../LookupField.messages';
import { LookupOptionIcon } from './LookupOptionIcon';
export var LookupValue = function LookupValue(_ref3) {
  var data = _ref3.data,
    _ref3$selectProps = _ref3.selectProps,
    customStyles = _ref3$selectProps.customStyles,
    optionTypesMap = _ref3$selectProps.optionTypesMap;
  var translator = useContext(TranslatorContext);
  var _ref = data || {},
    type = _ref.type,
    displayName = _ref.displayName;
  var _ref2 = optionTypesMap[type] || {},
    typeDisplayName = _ref2.typeDisplayName;
  var translatedTypeDisplayName = translator(typeDisplayName || messages.unknownType);
  return React.createElement("div", {
    className: customStyles.value
  }, React.createElement(LookupOptionIcon, {
    type: type,
    customStyles: customStyles,
    optionTypesMap: optionTypesMap,
    title: translatedTypeDisplayName
  }), React.createElement("div", {
    className: customStyles.title
  }, displayName));
};
LookupValue.__docgenInfo = {
  componentName: "LookupValue",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupValue",
  methods: [],
  actualName: "LookupValue"
};