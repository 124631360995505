import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./DropdownMenu.module.css";
export var DropdownMenuHeader = function DropdownMenuHeader(props) {
  var id = props.id,
    title = props.title,
    children = props.children;
  var translator = useContext(TranslatorContext);
  return React.createElement("div", {
    id: id,
    className: styles.dropdownHeader
  }, React.createElement("div", {
    className: styles.dropdownHeaderTitle,
    role: "none"
  }, translator(title)), children);
};
DropdownMenuHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  children: PropTypes.node
};
DropdownMenuHeader.__docgenInfo = {
  componentName: "DropdownMenuHeader",
  packageName: "@jutro/router",
  description: "DropdownMenuHeader",
  displayName: "DropdownMenuHeader",
  methods: [],
  actualName: "DropdownMenuHeader",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify dropdown menu header component."
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "The element node representing the header title, in most cases, will be a plain string."
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The component children wrapped by the dropdown menu header component."
    }
  }
};