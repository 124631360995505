import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { layoutShape } from '../../../layouts/Layout';
import styles from "./ModalFooter.module.css";
import { getLayoutComponent } from '../helpers/modalHelpers';
var defaultLayout = {
  component: 'flex',
  componentProps: {
    alignItems: 'middle',
    justifyContent: 'right',
    wrap: 'reverse',
    gap: 'medium'
  }
};
var modalFooterPropTypes = {
  contentLayout: PropTypes.oneOfType([PropTypes.shape({
    component: PropTypes.string.isRequired,
    componentProps: PropTypes.object
  }), layoutShape]),
  children: PropTypes.node
};
export var ModalFooter = function ModalFooter(_ref) {
  var contentLayout = _ref.contentLayout,
    children = _ref.children;
  var Layout = getLayoutComponent(contentLayout.component);
  var footerClasses = styles.modalFooter;
  var footerLayoutClasses = cx(_defineProperty({}, styles.modalFooterFlexLayout, contentLayout === defaultLayout && defaultLayout.component === 'flex'));
  return React.createElement("div", {
    className: footerClasses
  }, React.createElement(Layout, _extends({
    className: footerLayoutClasses
  }, contentLayout.componentProps), children));
};
ModalFooter.propTypes = modalFooterPropTypes;
ModalFooter.defaultProps = {
  contentLayout: defaultLayout
};
ModalFooter.__docgenInfo = {
  componentName: "ModalFooter",
  packageName: "@jutro/components",
  description: "ModalFooter",
  displayName: "ModalFooter",
  methods: [],
  actualName: "ModalFooter",
  props: {
    contentLayout: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: true
            },
            componentProps: {
              name: "object",
              required: false
            }
          }
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Used to identify the component",
              required: false
            },
            repeat: {
              name: "union",
              value: [{
                name: "enum",
                value: [{
                  value: "'auto-fit'",
                  computed: false
                }, {
                  value: "'auto-fill'",
                  computed: false
                }]
              }, {
                name: "number"
              }, {
                name: "string"
              }],
              description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
              required: false
            },
            gap: {
              name: "enum",
              computed: true,
              value: "Object.keys(gaps)",
              description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
              required: false
            },
            columns: {
              name: "array",
              description: "define explicit columns",
              required: false
            },
            colSpan: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            },
            colStart: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            }
          }
        }]
      },
      required: false,
      description: "defines the layout to be used with a 'component' property set to either Flex or Grid\n(otherwise, will default to a div) and 'componentProperties' to set properties for that layout component",
      defaultValue: {
        value: "{\n    component: 'flex',\n    componentProps: {\n        alignItems: 'middle',\n        justifyContent: 'right',\n        wrap: 'reverse',\n        gap: 'medium',\n    },\n}",
        computed: false
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The content of the ModalFooter. Typically Buttons."
    }
  }
};