import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import { TypeaheadMultiSelectField } from '@jutro/components';
import { availableValuesCodeNameObject } from '@jutro/prop-types';
export var timeZoneFieldPropTypes = _objectSpread({}, TypeaheadMultiSelectField.propTypes, {
  availableValues: PropTypes.arrayOf(PropTypes.shape(availableValuesCodeNameObject).isRequired)
});
export var TimeZoneField = function TimeZoneField(props) {
  return React.createElement(TypeaheadMultiSelectField, _extends({}, props, {
    singleSelect: true
  }));
};
TimeZoneField.__docgenInfo = {
  componentName: "TimeZoneField",
  packageName: "@jutro/lab-preview-date",
  description: "",
  displayName: "TimeZoneField",
  methods: [],
  actualName: "TimeZoneField"
};