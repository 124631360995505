import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
export default {
  create: function create(config) {
    return function (event) {
      var _context;
      return _reduceInstanceProperty(_context = _Object$keys(event)).call(_context, function (result, key) {
        var _config$eventKeys;
        if (config !== null && config !== void 0 && (_config$eventKeys = config.eventKeys) !== null && _config$eventKeys !== void 0 && _config$eventKeys[key]) {
          result[key] = config.eventKeys[key];
          return result;
        }
        result[key] = event[key];
        return result;
      }, {});
    };
  }
};