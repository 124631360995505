import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _endsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/ends-with";
export var isMatches = function isMatches(file) {
  return function (type) {
    if (isExtension(type)) {
      var _context;
      return _endsWithInstanceProperty(_context = file.name.toLowerCase()).call(_context, type.toLowerCase());
    }
    return isCovers(type, file.type);
  };
};
function isExtension(type) {
  return _startsWithInstanceProperty(type).call(type, '.');
}
function isCovers(pattern, mimeType) {
  var _pattern$toLowerCase$ = pattern.toLowerCase().split('/'),
    _pattern$toLowerCase$2 = _slicedToArray(_pattern$toLowerCase$, 2),
    patternType = _pattern$toLowerCase$2[0],
    patternSubtype = _pattern$toLowerCase$2[1];
  var _mimeType$toLowerCase = mimeType.toLowerCase().split('/'),
    _mimeType$toLowerCase2 = _slicedToArray(_mimeType$toLowerCase, 2),
    type = _mimeType$toLowerCase2[0],
    subtype = _mimeType$toLowerCase2[1];
  if (patternType !== type) {
    return false;
  }
  if (patternSubtype === '*') {
    return true;
  }
  return patternSubtype === subtype;
}