import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { getMessageProp } from '@jutro/platform';
import styles from "./Footer.module.css";
import { FooterContext, footerBasicType } from './FooterContext';
import { FooterText } from './FooterText';
import { messages } from './FooterCopyright.messages';
var footerCopyrightPropTypes = {
  className: PropTypes.string,
  messageProps: PropTypes.shape({
    copyrightMessage: intlMessageShape
  })
};
export var FooterCopyright = function FooterCopyright(_ref) {
  var className = _ref.className,
    messageProps = _ref.messageProps;
  var translator = useContext(TranslatorContext);
  var footerType = useContext(FooterContext);
  var classes = cx(styles.footerCopyright, _defineProperty({}, styles.footerCopyrightBasic, footerType === footerBasicType), className);
  var copyrightMessage = getMessageProp('copyrightMessage', messageProps, messages);
  var copyrights = translator(copyrightMessage, {
    year: new Date().getFullYear()
  });
  return React.createElement(FooterText, {
    className: classes,
    text: copyrights
  });
};
FooterCopyright.propTypes = footerCopyrightPropTypes;
FooterCopyright.__docgenInfo = {
  componentName: "FooterCopyright",
  packageName: "@jutro/components",
  description: "The `FooterCopyright` component is designed as a simple component presenting copyright inside the `Footer` container.",
  displayName: "FooterCopyright",
  methods: [],
  actualName: "FooterCopyright",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          copyrightMessage: {
            name: "union",
            description: "Location aware text of copyright",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Copyright message props"
    }
  }
};