import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IntlContext, TranslatorContext } from '@jutro/locale';
import { LiveRegion } from '../../accessibility/LiveRegion/LiveRegion';
import styles from "./Badge.module.css";
import { InfoLabel } from '../InfoLabel/InfoLabel';
import { getFromArray } from '../../helpers';
var availableInfoLabelTypes = ['success', 'error', 'warning', 'info', 'neutral'];
var availableBadgeTypes = [].concat(availableInfoLabelTypes, ['notification', 'primary', 'inverse']);
var defaultBadgeType = 'notification';
var badgePropTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(availableBadgeTypes),
  liveRegionContext: PropTypes.string
};
export var Badge = function Badge(_ref) {
  var value = _ref.value,
    maxValue = _ref.maxValue,
    className = _ref.className,
    id = _ref.id,
    type = _ref.type,
    liveRegionContext = _ref.liveRegionContext;
  var _useContext = useContext(IntlContext),
    formatNumber = _useContext.formatNumber;
  var translator = useContext(TranslatorContext);
  var badgeType = getFromArray(availableBadgeTypes, type, defaultBadgeType);
  var infoLabelType = getFromArray(availableInfoLabelTypes, badgeType);
  var badgeContent = value > maxValue ? "".concat(formatNumber(maxValue), "+") : formatNumber(value);
  var classes = cx(styles.badge, styles[badgeType], className);
  var liveRegionContextClass = styles.liveRegionContext;
  return React.createElement(LiveRegion, {
    context: translator(liveRegionContext),
    contextClass: liveRegionContextClass
  }, React.createElement(InfoLabel, {
    id: id,
    type: infoLabelType,
    className: classes
  }, badgeContent));
};
Badge.propTypes = badgePropTypes;
Badge.defaultProps = {
  type: defaultBadgeType
};
Badge.__docgenInfo = {
  componentName: "Badge",
  packageName: "@jutro/components",
  description: "Displays a \"badge\" element with child components, such as text or images.",
  displayName: "Badge",
  methods: [],
  actualName: "Badge",
  metadataType: "element",
  props: {
    value: {
      type: {
        name: "number"
      },
      required: true,
      description: "The value of the Badge"
    },
    maxValue: {
      type: {
        name: "number"
      },
      required: false,
      description: "The maximum value for the badge\nif value prop is greater then maxValue, then Badge will display 'maxValue+'"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: false
        }, {
          value: "'error'",
          computed: false
        }, {
          value: "'warning'",
          computed: false
        }, {
          value: "'info'",
          computed: false
        }, {
          value: "'neutral'",
          computed: false
        }, {
          value: "'notification'",
          computed: false
        }, {
          value: "'primary'",
          computed: false
        }, {
          value: "'inverse'",
          computed: false
        }]
      },
      required: false,
      description: "Phrase allowing to set custom flavor (success, general, neutral, etc.)",
      defaultValue: {
        value: "'notification'",
        computed: false
      }
    },
    liveRegionContext: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to describe the live region for assistive technologies (E.g. Unread Emails)"
    }
  }
};