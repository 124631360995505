import _objectWithoutProperties from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import React, { useContext, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { Icon, InlineLabel } from '@jutro/components';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { DropdownMenu } from '../../../DropdownMenu/DropdownMenu';
import { DropdownMenuLink } from '../../../DropdownMenu/DropdownMenuLink';
import styles from "../../NavBarItem.module.css";
export var NavBarDropdownInternal = function NavBarDropdownInternal(props) {
  var header = props.header,
    icon = props.icon,
    disabled = props.disabled,
    className = props.className,
    activeClassName = props.activeClassName,
    alignment = props.alignment,
    routes = props.routes,
    id = props.id;
  var translator = useContext(TranslatorContext);
  var isTopNavBar = alignment === 'top';
  var hasActiveRoute = _someInstanceProperty(_).call(_, routes, function (route) {
    return route.path === props.location.pathname;
  });
  var classes = cx(styles.navBarItem, className, isTopNavBar ? styles.topNavBarItem : styles.leftNavBarItem, _defineProperty(_defineProperty(_defineProperty({}, styles.activeLeftNavBarItem, hasActiveRoute && !isTopNavBar), styles.activeTopNavBarItem, hasActiveRoute), activeClassName, hasActiveRoute));
  var iconComponent = icon && React.createElement(Icon, {
    icon: icon,
    className: styles.navBarItemIcon
  });
  var handleRenderTrigger = useCallback(function (_ref, toggleMenu) {
    var triggerId = _ref.id,
      isOpen = _ref.isOpen,
      menuId = _ref.menuId,
      others = _objectWithoutProperties(_ref, ["id", "isOpen", "menuId"]);
    var clickCallback = function clickCallback() {
      toggleMenu(!isOpen);
    };
    return React.createElement("button", _extends({
      id: triggerId,
      tabIndex: disabled ? -1 : 0,
      disabled: disabled,
      className: classes,
      onClick: clickCallback,
      "aria-expanded": isOpen
    }, others), React.createElement(InlineLabel, {
      icon: iconComponent
    }, translator(header)), React.createElement(Icon, {
      icon: "mi-expand-more",
      className: styles.navBarItemChevron
    }));
  }, [classes, disabled, header, iconComponent, styles.navBarItemChevron, translator]);
  return React.createElement(DropdownMenu, {
    className: styles.navBarDropdown,
    id: id,
    onRenderTrigger: handleRenderTrigger
  }, routes && _mapInstanceProperty(routes).call(routes, function (_ref2) {
    var title = _ref2.title,
      path = _ref2.path;
    return React.createElement(DropdownMenuLink, {
      key: path,
      activeClassName: styles.active,
      to: path
    }, title);
  }));
};
NavBarDropdownInternal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  alignment: PropTypes.oneOf(['top', 'left'])
};
NavBarDropdownInternal.displayName = 'NavBarDropdown';
export var NavBarDropdown = withRouter(NavBarDropdownInternal);
NavBarDropdownInternal.__docgenInfo = {
  componentName: "NavBarDropdown",
  packageName: "@jutro/router",
  description: "The NavBarLink component is designed as a simple component for presenting a dropdown inside the NavBar container.",
  displayName: "NavBarDropdown",
  methods: [],
  actualName: "NavBarDropdownInternal",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Link header."
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag to make dropdown disabled"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }]
      },
      required: false,
      description: "Navigation alignment"
    }
  }
};