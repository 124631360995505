import _defineProperty2 from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isEmptyValue } from '@jutro/data';
import { availableValueObjectShape, dataTypeShape, DATA_TYPE_STRING } from '@jutro/prop-types';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { GenericSelectControl, genericSelectInternalClassNamesShape } from '../GenericSelectControl/GenericSelectControl';
import { getOptionCode, getOptionName } from '../availableValues';
import { messages } from './DropdownSelectField.messages';
var isOther = function isOther(value) {
  return (value === null || value === void 0 ? void 0 : value.toLowerCase) && value.toLowerCase() === 'other';
};
var shouldRenderDetails = function shouldRenderDetails(value) {
  return _.isArray(value) ? _someInstanceProperty(value).call(value, isOther) : isOther(value);
};
var valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.number, availableValueObjectShape]);
var handleOnClick = function handleOnClick(e) {
  return e.stopPropagation();
};
export var DropdownSelectField = function (_FieldComponent) {
  _inherits(DropdownSelectField, _FieldComponent);
  var _super = _createSuper(DropdownSelectField);
  function DropdownSelectField() {
    var _this;
    _classCallCheck(this, DropdownSelectField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "handleOnChange", function (value) {
      _this.beforeValue = _this.props.value;
      _this.notifyChange(_this.getNewValue(value));
    });
    return _this;
  }
  _createClass(DropdownSelectField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(DropdownSelectField.prototype), "render", this).call(this);
    }
  }, {
    key: "getNewValue",
    value: function getNewValue(_ref) {
      var id = _ref.id,
        value = _ref.value;
      if (id === 'placeholder') {
        return undefined;
      }
      var _this$props = this.props,
        dataType = _this$props.dataType,
        availableValues = _this$props.availableValues;
      if (dataType === DATA_TYPE_STRING) {
        return value;
      }
      return _findInstanceProperty(availableValues).call(availableValues, function (availableValue) {
        return getOptionCode(availableValue).toString() === value;
      });
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        isValid = _ref2.isValid,
        labelId = _ref2.labelId;
      var id = breakpointProps.id,
        disabled = breakpointProps.disabled,
        required = breakpointProps.required,
        children = breakpointProps.children,
        internalClassNames = breakpointProps.internalClassNames,
        defaultValue = breakpointProps.defaultValue,
        className = breakpointProps.className,
        searchable = breakpointProps.searchable,
        usePortal = breakpointProps.usePortal,
        isInitiallyOpen = breakpointProps.isInitiallyOpen;
      var options = this.options,
        renderValue = this.renderValue;
      var defaultValueStringified = defaultValue ? defaultValue.toString() : null;
      var selectDefaultValue = defaultValueStringified ? _findInstanceProperty(options).call(options, function (el) {
        return el.value === defaultValueStringified;
      }) : null;
      var detail = shouldRenderDetails(renderValue) && children;
      var renderValueForComponentValue = _.isObject(renderValue) ? renderValue.id || renderValue.code : renderValue;
      var componentValue = renderValueForComponentValue ? _filterInstanceProperty(options).call(options, function (el) {
        return el.value === renderValueForComponentValue;
      }) : null;
      return React.createElement("div", {
        onClick: handleOnClick,
        role: "presentation"
      }, React.createElement(GenericSelectControl, {
        id: id,
        availableValues: options,
        className: className,
        internalClassNames: internalClassNames,
        defaultValue: selectDefaultValue,
        disabled: disabled,
        getOptionLabel: this.getOptionLabel,
        getOptionValue: this.getOptionValue,
        labelId: labelId,
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onValueChange: this.handleOnChange,
        placeholder: this.placeholderText,
        required: required,
        searchable: searchable,
        value: componentValue,
        valid: isValid,
        usePortal: usePortal,
        isInitiallyOpen: isInitiallyOpen
      }), detail && React.createElement("div", null, detail));
    }
  }, {
    key: "getOptionLabel",
    value: function getOptionLabel(_ref3) {
      var label = _ref3.label;
      return label;
    }
  }, {
    key: "getOptionValue",
    value: function getOptionValue(_ref4) {
      var value = _ref4.value;
      return value;
    }
  }, {
    key: "renderValue",
    get: function get() {
      var value = this.props.value;
      if (_.isArray(value)) {
        return _.isObject(value[0]) ? _mapInstanceProperty(value).call(value, function (_ref5) {
          var code = _ref5.code,
            id = _ref5.id;
          return code || id;
        }) : value;
      }
      if (isEmptyValue(value)) {
        return '';
      }
      if (_.isObject(value)) {
        var objectValue = value.code || value.id;
        return objectValue.toString() || '';
      }
      return value.toString();
    }
  }, {
    key: "placeholderText",
    get: function get() {
      var _this$props2 = this.props,
        placeholder = _this$props2.placeholder,
        label = _this$props2.label;
      var translator = this.translator;
      return _.isObject(placeholder) ? translator(placeholder) : placeholder.replace('{LABEL}', translator(label));
    }
  }, {
    key: "options",
    get: function get() {
      var _context;
      var _this$props3 = this.props,
        value = _this$props3.value,
        availableValues = _this$props3.availableValues,
        alwaysShowPlaceholder = _this$props3.alwaysShowPlaceholder,
        hidePlaceholder = _this$props3.hidePlaceholder;
      var translator = this.translator;
      var options = _filterInstanceProperty(_context = _mapInstanceProperty(availableValues).call(availableValues, function (option) {
        var visible = option.visible;
        var optionCode = getOptionCode(option);
        var optionName = getOptionName(option);
        if (_.isBoolean(optionCode)) return false;
        if (visible === false) return false;
        var optionValue = _.isNumber(optionCode) ? optionCode.toString() : optionCode;
        if (!optionValue) return false;
        return {
          label: translator(optionName),
          value: optionValue
        };
      })).call(_context, Boolean);
      var hasPlaceholder = _someInstanceProperty(availableValues).call(availableValues, function (_ref6) {
        var code = _ref6.code;
        return isEmptyValue(code);
      }) && _someInstanceProperty(availableValues).call(availableValues, function (_ref7) {
        var id = _ref7.id;
        return isEmptyValue(id);
      });
      if ((isEmptyValue(value) || alwaysShowPlaceholder) && !hasPlaceholder && !hidePlaceholder) {
        options.unshift({
          label: this.placeholderText,
          id: 'placeholder'
        });
      }
      return options;
    }
  }], [{
    key: "isContentVisible",
    value: function isContentVisible(value) {
      return shouldRenderDetails(value);
    }
  }]);
  return DropdownSelectField;
}(FieldComponent);
_defineProperty(DropdownSelectField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: valuePropType,
  defaultValue: valuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(availableValueObjectShape),
  children: PropTypes.node,
  internalClassNames: genericSelectInternalClassNamesShape,
  alwaysShowPlaceholder: PropTypes.bool,
  searchable: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  hidePlaceholder: PropTypes.bool
}));
_defineProperty(DropdownSelectField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  availableValues: [],
  placeholder: messages.defaultPlaceholder,
  dataType: DATA_TYPE_STRING,
  searchable: false
}));
_defineProperty(DropdownSelectField, "contextType", FieldComponent.contextType);
DropdownSelectField.__docgenInfo = {
  componentName: "DropdownSelectField",
  packageName: "@jutro/components",
  description: "Renders a dropdown where the user can select one of the values. You specify the allowed values and the\naction using props.",
  displayName: "DropdownSelectField",
  methods: [{
    name: "isContentVisible",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "value",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getNewValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ id, value }",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleOnChange",
    docblock: "Handler function for when the select field value is changed\n@param {object} value - value that is passed when a change occurs",
    modifiers: [],
    params: [{
      name: "value",
      description: "value that is passed when a change occurs",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Handler function for when the select field value is changed"
  }, {
    name: "renderValue",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "placeholderText",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "options",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} param1\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "{ isValid, labelId }"
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "getOptionLabel",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ label }",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getOptionValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ value }",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "DropdownSelectField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "custom",
        raw: "valuePropType"
      },
      required: false,
      description: "Selected item value"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "valuePropType"
      },
      required: false,
      description: "Default value to set if there is no value present."
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "The format of the value",
      defaultValue: {
        value: "'string'",
        computed: false
      }
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }]
        }
      },
      required: false,
      description: "Array of choice objects to display; choice objects contains 'code' and 'name'",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Node to render when 'other' value is selected, as processed by `renderContentFromMetadata` method\n(won't be rendered when value is not 'other')"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          control: {
            name: "string",
            description: "CSS class name for control",
            required: false
          },
          controlFocused: {
            name: "string",
            description: "CSS class name for focused control",
            required: false
          },
          menuList: {
            name: "string",
            description: "CSS class name for list of options",
            required: false
          },
          option: {
            name: "string",
            description: "CSS class name for option",
            required: false
          },
          selectIcon: {
            name: "string",
            description: "CSS class name for select icon",
            required: false
          }
        }
      },
      required: false,
      description: "Map of CSS class names for overriding individual parts of component's styles"
    },
    alwaysShowPlaceholder: {
      type: {
        name: "bool"
      },
      required: false,
      description: "When set to `true` options will show the placeholder text and it will be the first option."
    },
    searchable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Allows you to type in dropdown field to find options.",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should dropdown be initially opened"
    },
    hidePlaceholder: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Hide placeholder from dropdown"
    },
    placeholder: {
      defaultValue: {
        value: "messages.defaultPlaceholder",
        computed: true
      },
      required: false
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};