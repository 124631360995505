import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { metadataTypes } from '@jutro/uimetadata';
import styles from "./TableTitleBar.module.css";
var defaultTitlePosition = 'left';
var availableTitlePositions = ['left', 'right', 'center'];
export var TableTitleBar = function TableTitleBar(props) {
  var titleId = props.titleId,
    titlePosition = props.titlePosition;
  var translator = useContext(TranslatorContext);
  var title = translateTitle(props.title, translator);
  var titleElement = renderTitle(title, titleId, titlePosition);
  var titleActionElement = renderTitleAction(props.titleAction);
  return renderTitleBar(titleElement, titleActionElement);
};
function translateTitle(title, translator) {
  if (_.isFunction(title)) {
    title = title(translator);
  }
  if (translator) {
    title = translator(title);
  }
  return title;
}
function renderTitle(title, titleId, titlePosition) {
  var classNames = cx(styles.title, styles[titlePosition]);
  return title ? React.createElement("div", {
    id: titleId,
    className: classNames
  }, title) : null;
}
function renderTitleAction(titleAction) {
  return titleAction ? React.createElement("div", {
    className: styles.titleAction
  }, titleAction()) : null;
}
function renderTitleBar(titleElement, titleActionElement) {
  if (titleElement || titleActionElement) {
    return React.createElement("div", {
      className: styles.tableTitleBar
    }, titleElement, titleActionElement);
  }
  return null;
}
TableTitleBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleAction: PropTypes.func,
  theme: PropTypes.object,
  titleId: PropTypes.string,
  position: PropTypes.oneOf(availableTitlePositions)
};
TableTitleBar.defaultProps = {
  titlePosition: defaultTitlePosition
};
TableTitleBar.metadataType = metadataTypes.CONTAINER;
TableTitleBar.__docgenInfo = {
  componentName: "TableTitleBar",
  packageName: "@jutro/datatable",
  description: "TableTitleBar",
  displayName: "TableTitleBar",
  methods: [],
  actualName: "TableTitleBar",
  metadataType: "container",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: false,
      description: ""
    },
    titleAction: {
      type: {
        name: "func"
      },
      required: false,
      description: ""
    },
    theme: {
      type: {
        name: "object"
      },
      required: false,
      description: ""
    },
    titleId: {
      type: {
        name: "string"
      },
      required: false,
      description: ""
    },
    position: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }, {
          value: "'center'",
          computed: false
        }]
      },
      required: false,
      description: ""
    },
    titlePosition: {
      defaultValue: {
        value: "'left'",
        computed: false
      },
      required: false
    }
  }
};
renderTitle.__docgenInfo = {
  componentName: "renderTitle",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTitle",
  methods: [],
  actualName: "renderTitle"
};
renderTitleAction.__docgenInfo = {
  componentName: "renderTitleAction",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTitleAction",
  methods: [],
  actualName: "renderTitleAction"
};
renderTitleBar.__docgenInfo = {
  componentName: "renderTitleBar",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTitleBar",
  methods: [],
  actualName: "renderTitleBar"
};