import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { withRouter, useLocation, NavLink } from 'react-router-dom';
import { TranslatorContext, useSafeTranslatedUrls } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { Icon, Link } from '@jutro/components';
import { useAccessibleRef } from '@jutro/platform';
import { SideNavigationContext } from './SideNavigation/SideNavigationContext';
import { renderBadge } from './SideNavigation/renderBadge';
import styles from "./NavBarItem.module.css";
export var getIdFromPath = function getIdFromPath(path) {
  var _context;
  var pathArr = _filterInstanceProperty(_context = path.split('/')).call(_context, Boolean);
  var parentPath = pathArr[0];
  var childPath = _.last(pathArr);
  return parentPath === childPath ? childPath : "".concat(parentPath, "__").concat(childPath);
};
export var NavBarLinkInternal = function NavBarLinkInternal(_ref) {
  var header = _ref.header,
    toProp = _ref.to,
    href = _ref.href,
    exact = _ref.exact,
    icon = _ref.icon,
    disabled = _ref.disabled,
    className = _ref.className,
    activeClassName = _ref.activeClassName,
    alignment = _ref.alignment,
    notifications = _ref.notifications;
  var _useContext = useContext(SideNavigationContext),
    setActiveRoute = _useContext.setActiveRoute;
  var translator = useContext(TranslatorContext);
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var linkRef = useAccessibleRef();
  var to = urlTranslatorAndSanitizer(toProp);
  var isTopNavBar = alignment === 'top';
  var location = useLocation();
  var isActiveRoute = to === location.pathname;
  var isActiveSideNavItem = isActiveRoute && !isTopNavBar;
  var isInternalLink = !!to;
  var classes = cx(styles.navBarItem, isTopNavBar ? styles.topNavBarItem : styles.leftNavBarItem, _defineProperty(_defineProperty(_defineProperty({}, styles.activeTopNavBarItem, isActiveRoute && isTopNavBar), styles.activeLeftNavBarItem, isActiveSideNavItem), "isActiveSideNavItem", isActiveSideNavItem), className);
  var activeClasses = cx(isTopNavBar ? styles.activeTopNavBarItem : styles.activeLeftNavBarItem, activeClassName);
  var iconComponent = icon && React.createElement(Icon, {
    icon: icon,
    className: styles.navBarItemIcon
  });
  var title = React.createElement("span", {
    className: styles.title
  }, translator(header));
  var content = !isTopNavBar ? React.createElement("div", {
    className: styles.titleContentWrapper
  }, title, renderBadge(notifications, isInternalLink && getIdFromPath(to))) : title;
  var clickHandler = useCallback(function (event) {
    var _event$persist;
    (_event$persist = event.persist) === null || _event$persist === void 0 ? void 0 : _event$persist.call(event);
    setActiveRoute(to);
  }, [setActiveRoute, to]);
  var commonLinkProps = {
    disabled: disabled,
    className: classes,
    type: 'link',
    role: 'menuitem'
  };
  return isInternalLink ? React.createElement(NavLink, _extends({}, commonLinkProps, {
    to: to,
    exact: exact,
    activeClassName: activeClasses,
    tabIndex: disabled ? -1 : 0,
    onClick: clickHandler,
    innerRef: linkRef
  }), React.createElement("div", {
    className: styles.titleWrapper
  }, iconComponent, content)) : React.createElement(Link, _extends({}, commonLinkProps, {
    ref: linkRef,
    textClassName: styles.titleWrapper,
    inline: false,
    href: href,
    target: "_blank"
  }), iconComponent, content);
};
NavBarLinkInternal.propTypes = {
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  to: intlMessageShape,
  href: intlMessageShape,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  activeClassName: PropTypes.string,
  alignment: PropTypes.oneOf(['top', 'left']),
  notifications: PropTypes.number
};
NavBarLinkInternal.displayName = 'NavBarLink';
export var NavBarLink = withRouter(NavBarLinkInternal);
NavBarLinkInternal.__docgenInfo = {
  componentName: "NavBarLink",
  packageName: "@jutro/router",
  description: "The NavBarLink component is designed as a simple component for presenting a navigation link inside the NavBar container.",
  displayName: "NavBarLink",
  methods: [],
  actualName: "NavBarLinkInternal",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Link header."
    },
    to: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Internal path to the application."
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "External path from the application."
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag to make item disabled"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for  active component."
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }]
      },
      required: false,
      description: "Navigation alignment"
    },
    notifications: {
      type: {
        name: "number"
      },
      required: false,
      description: "The value of the Badge"
    }
  }
};