import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { ComponentPropTypes } from '@jutro/prop-types';
import styles from "./Footer.module.css";
import { FooterNavBar } from './FooterNavBar';
import { FooterText } from './FooterText';
import { FooterCopyright } from './FooterCopyright';
import { FooterContext, footerLegalTextType, footerBasicType } from './FooterContext';
var getFooterType = function getFooterType(children) {
  var _context, _context2;
  var containsText = _includesInstanceProperty(_context = _mapInstanceProperty(_context2 = React.Children).call(_context2, children, function (child) {
    return _.get(child, 'type');
  })).call(_context, FooterText);
  return containsText ? footerLegalTextType : footerBasicType;
};
var footerPropTypes = {
  className: PropTypes.string,
  children: ComponentPropTypes.oneOfChildOfComponentTypes([FooterNavBar, FooterText, FooterCopyright]),
  fluid: PropTypes.bool
};
export var Footer = function Footer(_ref) {
  var children = _ref.children,
    className = _ref.className,
    fluid = _ref.fluid,
    dangerouslySetInnerHTML = _ref.dangerouslySetInnerHTML,
    rest = _objectWithoutProperties(_ref, ["children", "className", "fluid", "dangerouslySetInnerHTML"]);
  var footerType = children && getFooterType(children);
  var classes = cx(styles.footer, className);
  var containerClasses = cx(styles.footerContainer, styles.footerContent, _defineProperty(_defineProperty({}, styles.footerContentBasic, footerType === footerBasicType), styles.fluid, fluid));
  return React.createElement("footer", _extends({
    className: classes
  }, rest), React.createElement(FooterContext.Provider, {
    value: footerType
  }, React.createElement("div", {
    className: containerClasses
  }, children)));
};
Footer.propTypes = footerPropTypes;
Footer.__docgenInfo = {
  componentName: "Footer",
  packageName: "@jutro/components",
  description: "The `Footer` component is designed as the container for a set of components that should be displayed on the footer of\napplication using `Jutro`. Dedicated components that should be used as children are `FooterNavBar` along with\n`FooterNavLink`s, `FooterText` and `FooterCopyright`.",
  displayName: "Footer",
  methods: [],
  actualName: "Footer",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.oneOfChildOfComponentTypes([\n    FooterNavBar,\n    FooterText,\n    FooterCopyright,\n])"
      },
      required: false,
      description: "Children of footer (navbar, text, copyright, etc.)."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Span the entire width."
    }
  }
};