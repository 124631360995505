import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { getConfigValue } from '@jutro/config';
import { warning } from '@jutro/logger';
import { AuthContextProvider } from './AuthContextProvider';
import { AuthStateProvider } from './AuthStateProvider';
import { LoginCallback } from './LoginCallback';
export var OPENID_SCOPE = 'openid';
export var DEFAULT_EXPIRE_EARLY_SECONDS = 30;
export var AUTO_RENEW = true;
export var DEFAULT_STORAGE = 'localStorage';
var getTokenManagerConfig = function getTokenManagerConfig() {
  var expireEarlySeconds = getConfigValue('JUTRO_AUTH_EXPIRE_EARLY_SECONDS', DEFAULT_EXPIRE_EARLY_SECONDS);
  var autoRenew = getConfigValue('JUTRO_AUTH_AUTO_RENEW', AUTO_RENEW);
  var storage = getConfigValue('JUTRO_AUTH_STORAGE', DEFAULT_STORAGE);
  if (storage === 'cookie') {
    warning("cookie storage option is not recommended as it's not secure");
  }
  return {
    expireEarlySeconds: expireEarlySeconds,
    storage: storage,
    autoRenew: autoRenew
  };
};
var getAuthConfig = function getAuthConfig() {
  return {
    isEnabled: getConfigValue('JUTRO_AUTH_ENABLED', false),
    issuer: getConfigValue('JUTRO_AUTH_ISSUER', ''),
    clientId: getConfigValue('JUTRO_AUTH_CLIENT_ID', ''),
    redirectBase: getConfigValue('JUTRO_AUTH_REDIRECT_BASE', window.location.origin),
    redirectPath: getConfigValue('JUTRO_AUTH_REDIRECT_PATH', ''),
    scope: getConfigValue('JUTRO_AUTH_SCOPE', "".concat(OPENID_SCOPE, ",profile,email")),
    pkce: getConfigValue('JUTRO_AUTH_PKCE_ENABLED', false),
    tokenManager: getTokenManagerConfig()
  };
};
export var AuthProvider = function AuthProvider(_ref) {
  var children = _ref.children,
    onAuthSessionExpired = _ref.onAuthSessionExpired;
  var authConfig = getAuthConfig();
  if (authConfig.isEnabled) {
    var issuer = authConfig.issuer,
      clientId = authConfig.clientId,
      redirectBase = authConfig.redirectBase,
      redirectPath = authConfig.redirectPath,
      scope = authConfig.scope,
      pkce = authConfig.pkce,
      tokenManager = authConfig.tokenManager;
    var redirectUri = "".concat(redirectBase).concat(redirectPath);
    var scopes = scope.split(',');
    if (_indexOfInstanceProperty(scopes).call(scopes, OPENID_SCOPE) === -1) {
      scopes.push(OPENID_SCOPE);
    }
    return React.createElement(AuthStateProvider, {
      issuer: issuer,
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: scopes,
      pkce: pkce,
      tokenManager: tokenManager,
      onSessionExpired: onAuthSessionExpired
    }, React.createElement(Switch, null, React.createElement(Route, {
      path: redirectPath,
      component: LoginCallback
    }), React.createElement(AuthContextProvider, null, children)));
  }
  return children;
};
AuthProvider.__docgenInfo = {
  componentName: "AuthProvider",
  packageName: "@jutro/auth",
  description: "A HOC that wraps children components in Okta Security. This will initialize the\nokta configuration and set up a callback route to handle the login redirect.\nWrapping will only happen if authentication is enabled, otherwise the original\ncomponent will be returned, unwrapped.",
  displayName: "AuthProvider",
  methods: [],
  actualName: "AuthProvider"
};