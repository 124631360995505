import _objectWithoutProperties from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import omit from "lodash/omit";
import { TranslatorContext } from '@jutro/locale';
var ValueComponentInternal = function ValueComponentInternal(_ref) {
  var id = _ref.id,
    className = _ref.className,
    prefix = _ref.prefix,
    suffix = _ref.suffix,
    Tag = _ref.tag,
    Component = _ref.component,
    rest = _objectWithoutProperties(_ref, ["id", "className", "prefix", "suffix", "tag", "component"]);
  var translator = useContext(TranslatorContext);
  return React.createElement(Tag, {
    id: id,
    className: className,
    "data-read-only": true
  }, prefix && translator(prefix), React.createElement(Component, omit(rest, ['path', 'onValueChange'])), suffix && translator(suffix));
};
ValueComponentInternal.defaultProps = {
  tag: 'span'
};
export var ValueComponentWrapper = function ValueComponentWrapper(ValueComponent) {
  return function (props) {
    return React.createElement(ValueComponentInternal, _extends({
      component: ValueComponent
    }, props));
  };
};
ValueComponentInternal.__docgenInfo = {
  componentName: "ValueComponentInternal",
  packageName: "@jutro/components",
  description: "",
  displayName: "ValueComponentInternal",
  methods: [],
  actualName: "ValueComponentInternal",
  props: {
    tag: {
      defaultValue: {
        value: "'span'",
        computed: false
      },
      required: false
    }
  }
};