import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { uniqueInnerId } from '@jutro/platform';
import styles from "./Panel.module.css";
export var alignments = ['center', 'left', 'right'];
var panelHeaderPropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  alignment: PropTypes.oneOf(alignments),
  title: intlMessageShape,
  subTitle: intlMessageShape
};
export var PanelHeader = function PanelHeader(props) {
  var id = props.id,
    alignment = props.alignment,
    className = props.className,
    title = props.title,
    subTitle = props.subTitle;
  var translator = useContext(TranslatorContext);
  if (!title) {
    return null;
  }
  var headerClasses = cx(styles.header, styles[alignment], className);
  var titleClasses = cx(styles.title);
  var subTitleClasses = cx(styles.subTitle);
  var titleText = translator(title);
  var subTitleText = translator(subTitle);
  var _uniqueInnerId = uniqueInnerId(id, 'titleId', 'subTitleId'),
    titleId = _uniqueInnerId.titleId,
    subTitleId = _uniqueInnerId.subTitleId;
  return React.createElement("div", {
    id: id,
    className: headerClasses
  }, React.createElement("span", {
    id: "".concat(titleId, "_title"),
    className: titleClasses,
    role: "heading",
    "aria-level": "1"
  }, titleText), subTitleText && React.createElement("span", {
    id: "".concat(subTitleId, "_subTitle"),
    className: subTitleClasses,
    role: "heading",
    "aria-level": "2"
  }, subTitleText));
};
PanelHeader.propTypes = panelHeaderPropTypes;
PanelHeader.defaultProps = {
  alignment: 'center'
};
PanelHeader.__docgenInfo = {
  componentName: "PanelHeader",
  packageName: "@jutro/components",
  description: "PanelHeader",
  displayName: "PanelHeader",
  methods: [],
  actualName: "PanelHeader",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify panel header component."
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles to be applied to PanelHeader"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'center'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Alignment of the PanelHeader",
      defaultValue: {
        value: "'center'",
        computed: false
      }
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Title of the PanelHeader"
    },
    subTitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "subTitle of the PanelHeader"
    }
  }
};