import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import { publish } from '@jutro/events';
export var getFromArray = function getFromArray(array, value, defaultValue) {
  return _includesInstanceProperty(array).call(array, value) ? value : defaultValue;
};
export function trackMethod(callback, topic, payload) {
  return function () {
    if (callback) {
      callback.apply(void 0, arguments);
    }
    publish(topic, payload);
  };
}