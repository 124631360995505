import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { messages } from '../LookupField.messages';
import { LookupOptionIcon } from './LookupOptionIcon';
import { LookupAddLink } from './LookupAddLink';
export var LookupOption = function LookupOption(props) {
  var data = props.data,
    innerProps = props.innerProps,
    isFocused = props.isFocused,
    isSelected = props.isSelected,
    _props$selectProps = props.selectProps,
    customStyles = _props$selectProps.customStyles,
    inputValue = _props$selectProps.inputValue,
    components = _props$selectProps.components,
    optionTypesMap = _props$selectProps.optionTypesMap;
  var translator = useContext(TranslatorContext);
  var stateClassName = cx(_defineProperty(_defineProperty({}, customStyles.focused, isFocused), customStyles.selected, isSelected));
  var type = data.type,
    displayName = data.displayName,
    value = data.value;
  if (data.__isNew__) {
    var AddLink = components.AddLink || LookupAddLink;
    return React.createElement(AddLink, _extends({}, props, {
      value: value,
      className: stateClassName,
      innerProps: innerProps
    }));
  }
  var _ref = optionTypesMap[type] || {},
    typeDisplayName = _ref.displayName;
  var translatedTypeDisplayName = translator(typeDisplayName || messages.unknownType);
  return React.createElement("div", _extends({}, innerProps, {
    className: cx(customStyles.option, stateClassName)
  }), React.createElement(LookupOptionIcon, {
    type: type,
    customStyles: customStyles,
    optionTypesMap: optionTypesMap,
    title: translatedTypeDisplayName
  }), React.createElement("div", {
    className: customStyles.item
  }, React.createElement("div", {
    className: customStyles.title
  }, renderTitle(displayName, inputValue)), React.createElement("div", {
    className: customStyles.subtitle
  }, translatedTypeDisplayName)));
};
function renderTitle(displayName, searchString) {
  var _context;
  if (!displayName || !searchString) {
    return displayName;
  }
  var index = _indexOfInstanceProperty(_context = displayName.toLowerCase()).call(_context, searchString.toLowerCase());
  if (index === -1) {
    return displayName;
  }
  return React.createElement(React.Fragment, null, displayName.substr(0, index), React.createElement("strong", null, displayName.substr(index, searchString.length)), displayName.substr(index + searchString.length));
}
LookupOption.__docgenInfo = {
  componentName: "LookupOption",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupOption",
  methods: [],
  actualName: "LookupOption"
};
renderTitle.__docgenInfo = {
  componentName: "renderTitle",
  packageName: "@jutro/components",
  description: "",
  displayName: "renderTitle",
  methods: [],
  actualName: "renderTitle"
};