import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../Icon/Icon';
export var LookupOptionIcon = function LookupOptionIcon(_ref2) {
  var type = _ref2.type,
    customStyles = _ref2.customStyles,
    optionTypesMap = _ref2.optionTypesMap,
    title = _ref2.title;
  var _ref = optionTypesMap[type] || {},
    _ref$icon = _ref.icon,
    icon = _ref$icon === void 0 ? 'question' : _ref$icon,
    className = _ref.className;
  return React.createElement(Icon, {
    icon: icon,
    className: cx(customStyles.icon, className),
    title: title
  });
};
LookupOptionIcon.__docgenInfo = {
  componentName: "LookupOptionIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupOptionIcon",
  methods: [],
  actualName: "LookupOptionIcon"
};