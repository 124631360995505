import _slicedToArray2 from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { renderContentFromMetadata, JsonFormValidator } from '@jutro/uiconfig';
import { log } from '@jutro/logger';
import { TableColumn } from './table/TableColumn';
import { DataTable } from './DataTable';
import { ActionColumn } from './columns/ActionColumn';
import { ActionItem } from './columns/ActionItem';
export var columnConfigShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.oneOf(['field', 'element']),
  component: PropTypes.oneOf(['actioncolumn', 'displaycolumn', 'fieldcolumn', 'moneycolumn', 'radiocolumn', 'tablecolumn']),
  componentProps: TableColumn.propTypes
});
var jsonDataTablePropTypes = _objectSpread({}, DataTable.propTypes, {
  callbackMap: PropTypes.object,
  componentMap: PropTypes.object,
  data: PropTypes.object,
  dataSchema: PropTypes.object.isRequired,
  onDataSchemaExtension: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSaveRow: PropTypes.func,
  overrideProps: PropTypes.object,
  showErrors: PropTypes.bool,
  uiColumns: PropTypes.arrayOf(columnConfigShape),
  readOnly: PropTypes.bool
});
export function generateColumnsFromSchema(dataProps, columnType) {
  var _context;
  return _mapInstanceProperty(_context = _Object$entries(dataProps)).call(_context, function (_ref) {
    var _ref2 = _slicedToArray2(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    var label = value.componentProps.label;
    return {
      id: key,
      type: 'field',
      component: columnType,
      componentProps: {
        header: label || key,
        path: key,
        textAlign: 'left',
        readOnly: key === 'id'
      }
    };
  });
}
export function useJsonSchema(dataSchema, onDataSchemaExtension, tableData) {
  var jsonHelper = useMemo(function () {
    return new JsonFormValidator({
      onDataSchemaExtension: onDataSchemaExtension
    });
  }, [onDataSchemaExtension]);
  var dataProps = useMemo(function () {
    return jsonHelper.dataProps(dataSchema, tableData, null);
  }, [dataSchema, tableData, jsonHelper]);
  return [dataProps];
}
export var JsonDataTable = function JsonDataTable(props) {
  var dataSchema = props.dataSchema,
    onDataSchemaExtension = props.onDataSchemaExtension,
    data = props.data,
    callbackMap = props.callbackMap,
    componentMap = props.componentMap,
    uiColumns = props.uiColumns,
    onDeleteRow = props.onDeleteRow,
    onSaveRow = props.onSaveRow,
    readOnly = props.readOnly,
    dataTableProps = _objectWithoutProperties(props, ["dataSchema", "onDataSchemaExtension", "data", "callbackMap", "componentMap", "uiColumns", "onDeleteRow", "onSaveRow", "readOnly"]);
  var _useJsonSchema = useJsonSchema(dataSchema, onDataSchemaExtension, data && data[0] || {}),
    _useJsonSchema2 = _slicedToArray(_useJsonSchema, 1),
    dataProps = _useJsonSchema2[0];
  var resolveDataProps = useCallback(function (id, path) {
    var testProps = dataProps[path];
    if (testProps) {
      testProps = _objectSpread({}, testProps, {
        componentProps: _objectSpread({}, testProps.componentProps, {
          fieldDatatype: testProps.datatype
        })
      });
      if (testProps.componentProps.readOnly) {
        testProps.componentProps.renderEditCell = null;
      }
    }
    return testProps;
  }, [dataProps]);
  var resolvers = {
    resolveDataProps: resolveDataProps,
    resolveCallbackMap: callbackMap,
    resolveComponentMap: componentMap
  };
  var columnMetadata = uiColumns || generateColumnsFromSchema(dataProps, 'displaycolumn');
  if (!uiColumns) {
    log.info('columnMetadata: ', _JSON$stringify(columnMetadata, null, 2));
  }
  var columns = renderContentFromMetadata(columnMetadata, undefined, resolvers);
  var isEditable = !readOnly && (onSaveRow || onDeleteRow);
  return React.createElement(DataTable, _extends({}, dataTableProps, {
    data: data,
    onSaveRow: onSaveRow
  }), columns, isEditable && React.createElement(ActionColumn, {
    id: "actions"
  }, onSaveRow && React.createElement(ActionItem, {
    id: "edit",
    icon: "edit",
    isEditTrigger: true
  }), onDeleteRow && React.createElement(ActionItem, {
    id: "delete",
    icon: "trash",
    onClick: onDeleteRow
  })));
};
JsonDataTable.propTypes = jsonDataTablePropTypes;
JsonDataTable.__docgenInfo = {
  componentName: "JsonDataTable",
  packageName: "@jutro/datatable",
  description: "DataTable variant allowing to integrate with schema, metadata and data",
  displayName: "JsonDataTable",
  methods: [],
  actualName: "JsonDataTable",
  metadataType: "container",
  props: {
    callbackMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve callback string to callback function"
    },
    componentMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve component string to component"
    },
    data: {
      type: {
        name: "object"
      },
      required: false,
      description: "Data for this table"
    },
    dataSchema: {
      type: {
        name: "object"
      },
      required: true,
      description: "Schema for this form; used for data props and validation"
    },
    onDataSchemaExtension: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to process schema data types with custom attributes"
    },
    onDeleteRow: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback for row deletion"
    },
    onSaveRow: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback for saving the row edit"
    },
    overrideProps: {
      type: {
        name: "object"
      },
      required: false,
      description: "optional\nOverride props by all, type or id."
    },
    showErrors: {
      type: {
        name: "bool"
      },
      required: false,
      description: "optional\nShow errors for all invalid fields; true after user attempts to submit/perform an action"
    },
    uiColumns: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "columnConfigShape"
        }
      },
      required: false,
      description: "Array of ui configs for columns"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Indicate if the table should be editable or readOnly"
    }
  },
  composes: ["./DataTable"]
};