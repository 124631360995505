import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./Flex.module.css";
import { useBreakpoint } from '../breakpoint/useBreakpoint';
var gapMapping = {
  none: styles.gwGapNone,
  small: styles.gwGapSmall,
  medium: styles.gwGapMedium,
  large: styles.gwGapLarge
};
var alignItemsMapping = {
  top: styles.gwAlignItemsStart,
  middle: styles.gwAlignItemsCenter,
  bottom: styles.gwAlignItemsEnd,
  left: styles.gwAlignItemsStart,
  center: styles.gwAlignItemsCenter,
  right: styles.gwAlignItemsEnd,
  baseline: styles.gwAlignItemsBaseline,
  stretch: styles.gwAlignItemsStretch
};
var alignContentMapping = {
  top: styles.gwAlignContentStart,
  middle: styles.gwAlignContentCenter,
  bottom: styles.gwAlignContentEnd,
  left: styles.gwAlignContentStart,
  center: styles.gwAlignContentCenter,
  right: styles.gwAlignContentEnd,
  baseline: styles.gwAlignContentBaseline,
  stretch: styles.gwAlignContentStretch
};
var justifyContentMapping = {
  top: styles.gwJustifyContentStart,
  middle: styles.gwJustifyContentCenter,
  bottom: styles.gwJustifyContentEnd,
  left: styles.gwJustifyContentStart,
  center: styles.gwJustifyContentCenter,
  right: styles.gwJustifyContentEnd,
  around: styles.gwJustifyContentAround,
  between: styles.gwJustifyContentBetween,
  evenly: styles.gwJustifyContentEvenly
};
var wrapMapping = {
  true: styles.gwWrap,
  false: styles.gwNoWrap,
  reverse: styles.gwReverseWrap
};
var directionMapping = {
  row: styles.gwDirectionRow,
  rowReverse: styles.gwDirectionRowReverse,
  column: styles.gwDirectionColumn,
  columnReverse: styles.gwDirectionColumnReverse
};
export var styleMappings = null;
if (window.__TEST__) {
  styleMappings = {
    gap: gapMapping,
    alignItems: alignItemsMapping,
    alignContent: alignContentMapping,
    justifyContent: justifyContentMapping,
    wrap: wrapMapping,
    direction: directionMapping
  };
}
export var Flex = function Flex(props) {
  var _useBreakpoint = useBreakpoint(props),
    breakpointProps = _useBreakpoint.breakpointProps;
  var gap = breakpointProps.gap,
    justifyContent = breakpointProps.justifyContent,
    alignContent = breakpointProps.alignContent,
    alignItems = breakpointProps.alignItems,
    tag = breakpointProps.tag,
    children = breakpointProps.children,
    className = breakpointProps.className,
    direction = breakpointProps.direction,
    phone = breakpointProps.phone,
    phoneWide = breakpointProps.phoneWide,
    tablet = breakpointProps.tablet,
    wrap = breakpointProps.wrap,
    dangerouslySetInnerHTML = breakpointProps.dangerouslySetInnerHTML,
    other = _objectWithoutProperties(breakpointProps, ["gap", "justifyContent", "alignContent", "alignItems", "tag", "children", "className", "direction", "phone", "phoneWide", "tablet", "wrap", "dangerouslySetInnerHTML"]);
  var classes = cx(styles.gwFlex, _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, gap && gapMapping[gap], gap), alignItems && alignItemsMapping[alignItems], alignItems), alignContent && alignContentMapping[alignContent], alignContent), justifyContent && justifyContentMapping[justifyContent], justifyContent), wrapMapping["".concat(wrap)], direction && directionMapping[direction], className);
  var Tag = tag;
  return React.createElement(Tag, _extends({
    className: classes
  }, other), children);
};
var flexPropTypes = {
  gap: PropTypes.oneOf(_Object$keys(gapMapping)),
  alignItems: PropTypes.oneOf(_Object$keys(alignItemsMapping)),
  alignContent: PropTypes.oneOf(_Object$keys(alignContentMapping)),
  justifyContent: PropTypes.oneOf(_Object$keys(justifyContentMapping)),
  direction: PropTypes.oneOf(_Object$keys(directionMapping)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  theme: PropTypes.shape({
    getStyle: PropTypes.func
  }),
  wrap: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(_Object$keys(wrapMapping))])
};
Flex.propTypes = flexPropTypes;
Flex.defaultProps = {
  gap: 'medium',
  tag: 'div',
  wrap: true,
  direction: 'row'
};
Flex.displayName = 'Flex';
Flex.__docgenInfo = {
  componentName: "Flex",
  packageName: "@jutro/layout",
  description: "Defines a css grid 'Flex'. This is used in conjunction with 'FlexItem' children.",
  displayName: "Flex",
  methods: [],
  actualName: "Flex",
  metadataType: "layout",
  props: {
    gap: {
      type: {
        name: "enum",
        value: [{
          value: "\"none\"",
          computed: false
        }, {
          value: "\"small\"",
          computed: false
        }, {
          value: "\"medium\"",
          computed: false
        }, {
          value: "\"large\"",
          computed: false
        }]
      },
      required: false,
      description: "Gap between rows and columns ('none', 'small', 'medium', 'large')",
      defaultValue: {
        value: "'medium'",
        computed: false
      }
    },
    alignItems: {
      type: {
        name: "enum",
        value: [{
          value: "\"top\"",
          computed: false
        }, {
          value: "\"middle\"",
          computed: false
        }, {
          value: "\"bottom\"",
          computed: false
        }, {
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }, {
          value: "\"baseline\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Align all items within the grid in the axis opposite to its direction; default is stretch"
    },
    alignContent: {
      type: {
        name: "enum",
        value: [{
          value: "\"top\"",
          computed: false
        }, {
          value: "\"middle\"",
          computed: false
        }, {
          value: "\"bottom\"",
          computed: false
        }, {
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }, {
          value: "\"baseline\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Align the flex within its container in the axis opposite to its direction; default is stretch"
    },
    justifyContent: {
      type: {
        name: "enum",
        value: [{
          value: "\"top\"",
          computed: false
        }, {
          value: "\"middle\"",
          computed: false
        }, {
          value: "\"bottom\"",
          computed: false
        }, {
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }, {
          value: "\"around\"",
          computed: false
        }, {
          value: "\"between\"",
          computed: false
        }, {
          value: "\"evenly\"",
          computed: false
        }]
      },
      required: false,
      description: "Justify the content in the flex direction: horizontally if direction is 'row' (default) or vertically if direction is 'column'"
    },
    direction: {
      type: {
        name: "enum",
        value: [{
          value: "\"row\"",
          computed: false
        }, {
          value: "\"rowReverse\"",
          computed: false
        }, {
          value: "\"column\"",
          computed: false
        }, {
          value: "\"columnReverse\"",
          computed: false
        }]
      },
      required: false,
      description: "Set the flex direction (row, rowReverse, column, columnReverse)",
      defaultValue: {
        value: "'row'",
        computed: false
      }
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: false,
      description: "Optional dom tag to render",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class(es) to add to the flex tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children; preferably 'FlexItem'"
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Flex property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Flex property for use at 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Flex property for use at 'tablet' breakpoint;"
    },
    theme: {
      type: {
        name: "shape",
        value: {
          getStyle: {
            name: "func",
            required: false
          }
        }
      },
      required: false,
      description: "Theme to apply to component"
    },
    wrap: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "enum",
          value: [{
            value: "\"true\"",
            computed: false
          }, {
            value: "\"false\"",
            computed: false
          }, {
            value: "\"reverse\"",
            computed: false
          }]
        }]
      },
      required: false,
      description: "Wrap (true), don't wrap (false), reverse wrap (reverse) flex items in the container",
      defaultValue: {
        value: "true",
        computed: false
      }
    }
  }
};