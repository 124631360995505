import { PhoneNumberUtil } from 'google-libphonenumber';
export var getSamplePhoneNumber = function getSamplePhoneNumber(countryCode, phoneNumberType) {
  var phoneUtils = PhoneNumberUtil.getInstance();
  var phoneNumber = phoneUtils.getExampleNumberForType(countryCode, phoneNumberType);
  if (phoneNumber) {
    return phoneUtils.format(phoneNumber);
  }
  return null;
};
export var getPrefixForCountry = function getPrefixForCountry(countryCode) {
  var phoneUtils = PhoneNumberUtil.getInstance();
  return phoneUtils.getCountryCodeForRegion(countryCode);
};
export var isValidatePhoneNumber = function isValidatePhoneNumber(phoneNumber, countryCode) {
  var phoneUtils = PhoneNumberUtil.getInstance();
  return phoneUtils.isValidNumber(phoneUtils.parse(phoneNumber, countryCode));
};