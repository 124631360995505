import _defineProperty2 from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import clamp from "lodash/clamp";
import { mappingDelta, getMessageProp } from '@jutro/platform';
import { intlMessageShape } from '@jutro/prop-types';
import { getValueForInput, isNumber } from '@jutro/data';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import styles from "./StepperField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { Button } from '../../button/Button';
import { messages } from './StepperField.messages';
export var StepperField = function (_FieldComponent) {
  _inherits(StepperField, _FieldComponent);
  var _super = _createSuper(StepperField);
  function StepperField() {
    var _this;
    _classCallCheck(this, StepperField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "state", {
      min: 0,
      max: 0
    });
    _defineProperty(_assertThisInitialized(_this), "getValidValue", function (value) {
      if (value === '') {
        return '';
      }
      var _this$state = _this.state,
        min = _this$state.min,
        max = _this$state.max;
      return clamp(value, min, max);
    });
    _defineProperty(_assertThisInitialized(_this), "handleBlur", function (evt) {
      if (evt.target.value === '') {
        _this.notifyChange('');
      }
      var value = getValueForInput('number', evt.target.value);
      _this.notifyChange(_this.getValidValue(value));
    });
    _defineProperty(_assertThisInitialized(_this), "isValueInBoundaries", function (value) {
      return value >= _this.state.min && value <= _this.state.max;
    });
    _defineProperty(_assertThisInitialized(_this), "handleStep", function (evt, increase) {
      evt.preventDefault();
      var _this$state2 = _this.state,
        step = _this$state2.step,
        value = _this$state2.value;
      var newValue = increase ? value + step : value - step;
      _this.notifyChange(newValue);
    });
    _defineProperty(_assertThisInitialized(_this), "stepUp", function (evt) {
      return _this.handleStep(evt, true);
    });
    _defineProperty(_assertThisInitialized(_this), "stepDown", function (evt) {
      return _this.handleStep(evt, false);
    });
    return _this;
  }
  _createClass(StepperField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(StepperField.prototype), "render", this).call(this);
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function generateAccessibilityProperties() {
      var _this$props = this.props,
        value = _this$props.value,
        minValue = _this$props.minValue,
        maxValue = _this$props.maxValue;
      return _objectSpread({}, _get(_getPrototypeOf(StepperField.prototype), "generateAccessibilityProperties", this).call(this), {
        'aria-valuenow': value,
        'aria-valuemin': minValue,
        'aria-valuemax': maxValue,
        'aria-live': 'polite'
      });
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var id = breakpointProps.id,
        disabled = breakpointProps.disabled,
        required = breakpointProps.required,
        controlClassName = breakpointProps.controlClassName,
        messageProps = breakpointProps.messageProps;
      var controlClasses = cx(styles.stepper, controlClassName);
      var inputClasses = cx(inputStyles.input, styles.input);
      var translator = this.translator;
      var decreaseBtnLabel = translator(getMessageProp('decreaseBtnLabel', messageProps, messages));
      var increaseBtnLabel = translator(getMessageProp('increaseBtnLabel', messageProps, messages));
      var _this$state3 = this.state,
        min = _this$state3.min,
        max = _this$state3.max,
        value = _this$state3.value,
        step = _this$state3.step;
      return React.createElement("div", {
        className: controlClasses
      }, React.createElement(Button, {
        icon: "mi-remove",
        "aria-label": decreaseBtnLabel,
        onClick: this.stepDown,
        disabled: disabled || !this.isValueInBoundaries(value - step),
        type: "outlined"
      }), React.createElement("input", _extends({
        id: id,
        type: "number",
        className: inputClasses,
        step: step,
        value: value,
        min: min,
        max: max,
        onChange: this.handleChange,
        onBlur: this.handleBlur
      }, this.getInputFocusHandlers(), {
        disabled: disabled,
        required: required,
        tabIndex: "0"
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties())), React.createElement(Button, {
        icon: "mi-add",
        "aria-label": increaseBtnLabel,
        onClick: this.stepUp,
        disabled: disabled || !this.isValueInBoundaries(value + step),
        type: "outlined",
        className: styles.button
      }));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var minValue = nextProps.minValue,
        maxValue = nextProps.maxValue,
        stepValue = nextProps.stepValue,
        value = nextProps.value;
      var min = isNumber(minValue) ? minValue : -Infinity;
      var max = isNumber(maxValue) ? maxValue : Infinity;
      var step = stepValue > 0 ? stepValue : 1;
      var newValue = getValueForInput('number', value);
      if (newValue && (!prevState || prevState.min !== min || prevState.max !== max)) {
        newValue = clamp(newValue, min, max);
      }
      return mappingDelta({
        min: min,
        max: max,
        step: step,
        value: newValue
      }, prevState);
    }
  }]);
  return StepperField;
}(FieldComponent);
_defineProperty(StepperField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepValue: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  messageProps: PropTypes.shape({
    decreaseBtnLabel: intlMessageShape,
    increaseBtnLabel: intlMessageShape
  })
}));
_defineProperty(StepperField, "contextType", FieldComponent.contextType);
_defineProperty(StepperField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps));
StepperField.__docgenInfo = {
  componentName: "StepperField",
  packageName: "@jutro/components",
  description: "Renders a numeric value and buttons to increase or decrease the value.",
  displayName: "StepperField",
  methods: [{
    name: "getValidValue",
    docblock: "Given a number value, will clamp the value within the current max and min\n\n@param {number} value - Value to be clamped\n@returns {number|string} - The clamped number value or an empty string",
    modifiers: [],
    params: [{
      name: "value",
      description: "Value to be clamped",
      type: {
        name: "number"
      },
      optional: false
    }],
    returns: {
      description: "The clamped number value or an empty string",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      }
    },
    description: "Given a number value, will clamp the value within the current max and min"
  }, {
    name: "handleBlur",
    docblock: "Overridden blur handler for `input` element. Converts possible string input into a number and clamps value.\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Overridden blur handler for `input` element. Converts possible string input into a number and clamps value."
  }, {
    name: "isValueInBoundaries",
    docblock: "Checks whether value is between props.minValue and props.maxValue\n\n@param {number} value to be tested\n@returns {boolean} true if the value is between properties boundaries",
    modifiers: [],
    params: [{
      name: "value",
      description: "to be tested",
      type: {
        name: "number"
      },
      optional: false
    }],
    returns: {
      description: "true if the value is between properties boundaries",
      type: {
        name: "boolean"
      }
    },
    description: "Checks whether value is between props.minValue and props.maxValue"
  }, {
    name: "handleStep",
    docblock: "Custom handler for `button` element. Increments/decrements the stepper input control\n\n@param {object} evt - React event wrapper\n@param {boolean} increase - indicates whether to increase or decrease the value",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "increase",
      description: "indicates whether to increase or decrease the value",
      type: {
        name: "boolean"
      },
      optional: false
    }],
    returns: null,
    description: "Custom handler for `button` element. Increments/decrements the stepper input control"
  }, {
    name: "stepUp",
    docblock: "Custom handler to increase the stepper input control\n\n@param {object} evt - React event wrapper\n@returns {undefined}",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: null
    },
    description: "Custom handler to increase the stepper input control"
  }, {
    name: "stepDown",
    docblock: "Custom handler to decrease the stepper input control\n\n@param {object} evt - React event wrapper\n@returns {undefined}",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: null
    },
    description: "Custom handler to decrease the stepper input control"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "StepperField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "String or number passed as value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "Default value"
    },
    stepValue: {
      type: {
        name: "number"
      },
      required: false,
      description: "Step increment value for the input field"
    },
    minValue: {
      type: {
        name: "number"
      },
      required: false,
      description: "Minimum value for the input field (note: this is different than aspect)"
    },
    maxValue: {
      type: {
        name: "number"
      },
      required: false,
      description: "Maximum value for the input field (note: this is different than aspect)"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          decreaseBtnLabel: {
            name: "union",
            description: "stepper decrease button aria-label",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          increaseBtnLabel: {
            name: "union",
            description: "stepper increase button aria-label",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};