import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _Symbol$for from "@babel/runtime-corejs3/core-js-stable/symbol/for";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
var _context;
import { log } from '@jutro/logger';
import ServiceRegistry from './ServiceRegistry';
var ServiceManager = function () {
  function ServiceManager() {
    _classCallCheck(this, ServiceManager);
    this.registry = new ServiceRegistry();
  }
  _createClass(ServiceManager, [{
    key: "register",
    value: function register(id, Service, options) {
      this.registry.register(id, Service, options);
    }
  }, {
    key: "getService",
    value: function getService(id) {
      if (!this.registry.hasRegistration(id)) {
        log.warning("No service available for id '".concat(id, "'"));
        return undefined;
      }
      return this.registry.getRegistration(id).getService();
    }
  }, {
    key: "getServiceByContract",
    value: function getServiceByContract(contract, resolver) {
      var implementors = this.registry.getImplementorsOf(contract);
      if (implementors.length === 0) {
        return undefined;
      }
      if (!resolver) {
        if (implementors.length > 1) {
          log.warning("Contract \"".concat(contract, "\" has multiple implementations, but no resolver provided"));
        }
        return this.getService(implementors[0].id);
      }
      var metadata = _mapInstanceProperty(implementors).call(implementors, function (reg) {
        return reg.getMetadata();
      });
      var selected = _findInstanceProperty(metadata).call(metadata, resolver);
      if (selected === undefined) {
        log.warning('Resolver callback did not find any implementations');
        return undefined;
      }
      return this.getService(selected.id);
    }
  }, {
    key: "listRegistrations",
    value: function listRegistrations() {
      log.info("Currently registered services: ".concat(this.registry.getRegistrationIds().join(', ')));
    }
  }]);
  return ServiceManager;
}();
var SERVICE_MANAGER_KEY = _Symbol$for('jutro.services.serviceManager');
if (_indexOfInstanceProperty(_context = _Object$getOwnPropertySymbols(global)).call(_context, SERVICE_MANAGER_KEY) === -1) {
  global[SERVICE_MANAGER_KEY] = new ServiceManager();
}
export default global[SERVICE_MANAGER_KEY];