import React from 'react';
import { Tag } from '../../../Tag/Tag';
var clickEvent = function clickEvent(evt) {
  return evt.stopPropagation();
};
export var GenericSelectControlMultiValue = function GenericSelectControlMultiValue(_ref) {
  var id = _ref.id,
    removeProps = _ref.removeProps,
    children = _ref.children,
    className = _ref.className,
    isDisabled = _ref.selectProps.isDisabled;
  return React.createElement(Tag, {
    id: id,
    label: children,
    className: className,
    disabled: isDisabled,
    renderIcon: function renderIcon(icon) {
      return React.createElement("div", removeProps, icon);
    },
    onClick: clickEvent
  });
};
GenericSelectControlMultiValue.__docgenInfo = {
  componentName: "GenericSelectControlMultiValue",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlMultiValue",
  methods: [],
  actualName: "GenericSelectControlMultiValue"
};