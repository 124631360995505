var tokenStorage = {};
var getTokenByKey = function getTokenByKey(key) {
  return tokenStorage[key];
};
var setToken = function setToken(key, token) {
  tokenStorage[key] = token;
};
var removeTokenByKey = function removeTokenByKey(key) {
  delete tokenStorage[key];
};
var getAccessToken = function getAccessToken() {
  return getTokenByKey('accessToken');
};
var getIdToken = function getIdToken() {
  return getTokenByKey('idToken');
};
module.exports = {
  getTokenByKey: getTokenByKey,
  getAccessToken: getAccessToken,
  getIdToken: getIdToken,
  setToken: setToken,
  removeTokenByKey: removeTokenByKey
};