import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _Number$isNaN from "@babel/runtime-corejs3/core-js-stable/number/is-nan";
import _setTimeout from "@babel/runtime-corejs3/core-js-stable/set-timeout";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import styles from "./Collapse.module.css";
var SHOW = 'SHOW';
var SHOWN = 'SHOWN';
var HIDE = 'HIDE';
var HIDDEN = 'HIDDEN';
var DEFAULT_DELAYS = {
  show: 350,
  hide: 350
};
export var Collapse = function (_Component) {
  _inherits(Collapse, _Component);
  var _super = _createSuper(Collapse);
  function Collapse(props) {
    var _this;
    _classCallCheck(this, Collapse);
    _this = _super.call(this, props);
    _this.state = {
      collapse: props.isOpen ? SHOWN : HIDDEN,
      height: null
    };
    _this.element = null;
    return _this;
  }
  _createClass(Collapse, [{
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      var _this2 = this;
      var willOpen = nextProps.isOpen;
      var collapse = this.state.collapse;
      if (willOpen && collapse === HIDDEN) {
        this.setState({
          collapse: SHOW
        }, function () {
          _this2.setState({
            height: _this2.getHeight()
          });
          _this2.transitionTag = _setTimeout(function () {
            _this2.setState({
              collapse: SHOWN,
              height: null
            });
          }, _this2.getDelay('show'));
        });
      } else if (!willOpen && collapse === SHOWN) {
        this.setState({
          height: this.getHeight()
        }, function () {
          _this2.setState({
            collapse: HIDE,
            height: _this2.getHeight()
          }, function () {
            _this2.setState({
              height: 0
            });
          });
        });
        this.transitionTag = _setTimeout(function () {
          _this2.setState({
            collapse: HIDDEN,
            height: null
          });
        }, this.getDelay('hide'));
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, prevState) {
      if (this.state.collapse === SHOWN && prevState && prevState.collapse !== SHOWN) {
        this.props.onOpened();
      }
      if (this.state.collapse === HIDDEN && prevState && prevState.collapse !== HIDDEN) {
        this.props.onClosed();
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      clearTimeout(this.transitionTag);
    }
  }, {
    key: "getDelay",
    value: function getDelay(key) {
      var delay = this.props.delay;
      if (typeof delay === 'object') {
        return _Number$isNaN(delay[key]) ? DEFAULT_DELAYS[key] : delay[key];
      }
      return delay;
    }
  }, {
    key: "getHeight",
    value: function getHeight() {
      return this.element.scrollHeight;
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;
      var _$omit = _.omit(this.props, 'isOpen', 'onClosed', 'onOpened', 'delay'),
        className = _$omit.className,
        other = _objectWithoutProperties(_$omit, ["className"]);
      var _this$state = this.state,
        collapse = _this$state.collapse,
        height = _this$state.height;
      var collapseClass;
      switch (collapse) {
        case SHOW:
          collapseClass = styles.collapsing;
          break;
        case SHOWN:
          collapseClass = "".concat(styles.collapsed, " ").concat(styles.show);
          break;
        case HIDE:
          collapseClass = styles.collapsing;
          break;
        case HIDDEN:
          collapseClass = styles.collapsed;
          break;
        default:
          collapseClass = styles.collapsed;
      }
      var classes = cx(styles.collapse, collapseClass, className);
      var style = height === null ? null : {
        height: height
      };
      return React.createElement("div", _extends({
        role: "region",
        style: style,
        className: classes,
        ref: function ref(c) {
          _this3.element = c;
        },
        "aria-expanded": collapse === SHOWN
      }, other));
    }
  }]);
  return Collapse;
}(Component);
_defineProperty(Collapse, "propTypes", {
  isOpen: PropTypes.bool,
  className: PropTypes.node,
  delay: PropTypes.oneOfType([PropTypes.shape({
    show: PropTypes.number,
    hide: PropTypes.number
  }), PropTypes.number]),
  onOpened: PropTypes.func,
  onClosed: PropTypes.func
});
_defineProperty(Collapse, "defaultProps", {
  isOpen: false,
  delay: DEFAULT_DELAYS,
  onOpened: function onOpened() {},
  onClosed: function onClosed() {}
});
Collapse.__docgenInfo = {
  componentName: "Collapse",
  packageName: "@jutro/components",
  description: "",
  displayName: "Collapse",
  methods: [{
    name: "getDelay",
    docblock: null,
    modifiers: [],
    params: [{
      name: "key",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getHeight",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "Collapse",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    className: {
      type: {
        name: "node"
      },
      required: false,
      description: ""
    },
    delay: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            show: {
              name: "number",
              required: false
            },
            hide: {
              name: "number",
              required: false
            }
          }
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "",
      defaultValue: {
        value: "{\n    show: 350,\n    hide: 350,\n}",
        computed: false
      }
    },
    onOpened: {
      type: {
        name: "func"
      },
      required: false,
      description: "",
      defaultValue: {
        value: "() => {}",
        computed: false
      }
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: false,
      description: "",
      defaultValue: {
        value: "() => {}",
        computed: false
      }
    }
  }
};