import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./GlobalizationChooser.module.css";
var globalizationChooserStorybookPropTypes = {
  className: PropTypes.string,
  containerStyle: PropTypes.string,
  innerStyle: PropTypes.string,
  localeId: PropTypes.string,
  languageId: PropTypes.string,
  resolvedLocale: PropTypes.string,
  resolvedLanguage: PropTypes.string,
  languageLabel: PropTypes.string,
  localeLabel: PropTypes.string,
  availableLanguageOptions: PropTypes.array,
  availableLocaleOptions: PropTypes.array,
  onLocaleValueChange: PropTypes.func,
  onLanguageValueChange: PropTypes.func,
  showLocaleLabel: PropTypes.bool,
  showLanguageLabel: PropTypes.bool,
  showLocaleSelect: PropTypes.bool,
  showLanguageSelect: PropTypes.bool,
  style: PropTypes.object
};
export var GlobalizationChooserStorybook = function GlobalizationChooserStorybook(_ref) {
  var className = _ref.className,
    containerStyle = _ref.containerStyle,
    innerStyle = _ref.innerStyle,
    localeId = _ref.localeId,
    languageId = _ref.languageId,
    resolvedLocale = _ref.resolvedLocale,
    resolvedLanguage = _ref.resolvedLanguage,
    languageLabel = _ref.languageLabel,
    localeLabel = _ref.localeLabel,
    availableLanguageOptions = _ref.availableLanguageOptions,
    availableLocaleOptions = _ref.availableLocaleOptions,
    onLocaleChange = _ref.onLocaleChange,
    onLanguageChange = _ref.onLanguageChange,
    showLocaleLabel = _ref.showLocaleLabel,
    showLanguageLabel = _ref.showLanguageLabel,
    showLocaleSelect = _ref.showLocaleSelect,
    showLanguageSelect = _ref.showLanguageSelect,
    style = _ref.style;
  var renderLabel = function renderLabel(id, label, showLabel) {
    if (!label || !showLabel) {
      return null;
    }
    return React.createElement("label", {
      htmlFor: id,
      style: style.label
    }, label);
  };
  var dropdownClasses = cx(styles.globalizationChooser, className);
  var renderLanguageSelect = function renderLanguageSelect() {
    if (!showLanguageSelect) {
      return null;
    }
    return React.createElement(React.Fragment, null, renderLabel(languageId, languageLabel, showLanguageLabel), React.createElement("div", {
      className: innerStyle,
      style: style.innerContainer
    }, React.createElement("select", {
      style: style.select,
      id: languageId,
      onChange: function onChange(evt) {
        return onLanguageChange(evt.target.value);
      },
      value: resolvedLanguage,
      className: dropdownClasses
    }, _mapInstanceProperty(availableLanguageOptions).call(availableLanguageOptions, function (_ref2) {
      var value = _ref2.value,
        label = _ref2.label;
      return React.createElement("option", {
        key: value,
        value: value
      }, label);
    }))));
  };
  var renderLocaleSelect = function renderLocaleSelect() {
    if (!showLocaleSelect) {
      return null;
    }
    return React.createElement(React.Fragment, null, renderLabel(localeId, localeLabel, showLocaleLabel), React.createElement("div", {
      className: innerStyle,
      style: style.innerContainer
    }, React.createElement("select", {
      style: style.select,
      id: localeId,
      onChange: function onChange(evt) {
        return onLocaleChange(evt.target.value);
      },
      value: resolvedLocale,
      className: dropdownClasses
    }, _mapInstanceProperty(availableLocaleOptions).call(availableLocaleOptions, function (_ref3) {
      var value = _ref3.value,
        label = _ref3.label;
      return React.createElement("option", {
        key: value,
        value: value
      }, label);
    }))));
  };
  return React.createElement("div", {
    className: cx(className, styles.container, containerStyle),
    style: style.container
  }, renderLanguageSelect(), renderLocaleSelect());
};
GlobalizationChooserStorybook.propTypes = globalizationChooserStorybookPropTypes;
GlobalizationChooserStorybook.__docgenInfo = {
  componentName: "GlobalizationChooserStorybook",
  packageName: "@jutro/components",
  description: "GlobalizationChooserStorybook",
  displayName: "GlobalizationChooserStorybook",
  methods: [],
  actualName: "GlobalizationChooserStorybook",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    containerStyle: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component container."
    },
    innerStyle: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component inner container."
    },
    localeId: {
      type: {
        name: "string"
      },
      required: false,
      description: "Id of the locale select element."
    },
    languageId: {
      type: {
        name: "string"
      },
      required: false,
      description: "Id of the language select element."
    },
    resolvedLocale: {
      type: {
        name: "string"
      },
      required: false,
      description: "Selected or active locale."
    },
    resolvedLanguage: {
      type: {
        name: "string"
      },
      required: false,
      description: "Selected or active language."
    },
    languageLabel: {
      type: {
        name: "string"
      },
      required: false,
      description: "Language label."
    },
    localeLabel: {
      type: {
        name: "string"
      },
      required: false,
      description: "Message key for the locale label."
    },
    availableLanguageOptions: {
      type: {
        name: "array"
      },
      required: false,
      description: "Languages available for selection."
    },
    availableLocaleOptions: {
      type: {
        name: "array"
      },
      required: false,
      description: "Locales available for selection."
    },
    onLocaleValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback invoked on locale change."
    },
    onLanguageValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback invoked on language change."
    },
    showLocaleLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing or hiding locale label"
    },
    showLanguageLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing or hiding language label"
    },
    showLocaleSelect: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing/hiding the locale select"
    },
    showLanguageSelect: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing/hiding the language select"
    },
    style: {
      type: {
        name: "object"
      },
      required: false,
      description: "Object containing the style overrides for component"
    }
  }
};