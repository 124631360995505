import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./ProgressPhase.module.css";
import { getKeyPressHandler } from '../../../accessibility/getKeyPressHandler';
import { PhaseProgressLabel } from './PhaseProgressLabel';
export var phaseProgressPropTypes = {
  id: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: intlMessageShape.isRequired,
    visited: PropTypes.bool,
    active: PropTypes.bool
  }).isRequired).isRequired,
  title: intlMessageShape.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isError: PropTypes.bool,
  showLabel: PropTypes.bool
};
export var PhaseProgress = function PhaseProgress(_ref) {
  var title = _ref.title,
    id = _ref.id,
    _ref$steps = _ref.steps,
    steps = _ref$steps === void 0 ? [] : _ref$steps,
    icon = _ref.icon,
    onClick = _ref.onClick,
    className = _ref.className,
    showLabel = _ref.showLabel,
    isError = _ref.isError;
  var translator = useContext(TranslatorContext);
  var active = _someInstanceProperty(steps).call(steps, function (step) {
    return step.active;
  });
  var activeStepIndex = _findIndexInstanceProperty(steps).call(steps, function (step) {
    return step.active;
  });
  var finalStep = activeStepIndex === steps.length - 1;
  var complete = _everyInstanceProperty(steps).call(steps, function (step) {
    return step.visited;
  });
  var disabled = !(active || complete);
  var progress = finalStep || complete ? 100 : 100 / steps.length * (activeStepIndex + 1);
  var progressPercent = "".concat(progress, "%");
  var handleClick = function handleClick(e) {
    e.preventDefault();
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };
  var handleKeyPress = function handleKeyPress(e) {
    if (disabled) {
      return;
    }
    handleClick(e);
  };
  var classes = cx(styles.progressPhase, _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, styles.active, active), styles.complete, complete), styles.disabled, disabled), styles.error, isError), className);
  return React.createElement("div", {
    id: id,
    role: "link",
    className: classes,
    onClick: handleClick,
    onKeyPress: getKeyPressHandler(handleKeyPress),
    active: active.toString(),
    "aria-label": translator(title),
    tabIndex: disabled ? '-1' : '0'
  }, React.createElement("div", {
    role: "progressbar",
    "aria-valuetext": title,
    "aria-valuenow": progress,
    "aria-valuemin": "0",
    "aria-valuemax": "100",
    className: styles.bar
  }, React.createElement("div", {
    className: styles.indicator,
    style: {
      width: progressPercent
    }
  })), showLabel && React.createElement(PhaseProgressLabel, {
    title: title,
    stepTitle: active ? steps[activeStepIndex].title : undefined,
    icon: icon,
    complete: complete,
    active: active,
    isError: isError
  }));
};
PhaseProgress.propTypes = phaseProgressPropTypes;
PhaseProgress.__docgenInfo = {
  componentName: "PhaseProgress",
  packageName: "@jutro/components",
  description: "PhaseProgress",
  displayName: "PhaseProgress",
  methods: [],
  actualName: "PhaseProgress",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component's identifier. Should be unique on a per-page basis."
    },
    steps: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            title: {
              name: "union",
              required: true,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: false
                  },
                  defaultMessage: {
                    name: "string",
                    required: false
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: false
                  }
                }
              }]
            },
            visited: {
              name: "bool",
              required: false
            },
            active: {
              name: "bool",
              required: false
            }
          }
        }
      },
      required: false,
      description: "Steps",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Text part for labeling the phase"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "FontAwesome icon name that should be displayed as part of the label"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback function triggered when the phase gets clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component"
    },
    isError: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag determining if phase should be shown as error"
    },
    showLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag determining if phase label should be displayed"
    }
  }
};