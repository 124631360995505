import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { IconButton } from '@jutro/components';
import styles from "./BurgerMenu.module.css";
import { BurgerSidebar } from './BurgerSidebar';
import { messages } from './BurgerMenu.messages';
export var BurgerMenu = function BurgerMenu(_ref) {
  var className = _ref.className,
    burgerContent = _ref.burgerContent,
    separatorClassName = _ref.separatorClassName,
    buttonClassName = _ref.buttonClassName;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var _useState3 = useState(document.body.style.overflow),
    _useState4 = _slicedToArray(_useState3, 1),
    initialBodyOverflow = _useState4[0];
  var translator = useContext(TranslatorContext);
  var toggleSidebar = useCallback(function () {
    return setIsOpen(function (prevIsOpen) {
      return !prevIsOpen;
    });
  }, []);
  useEffect(function () {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = initialBodyOverflow;
    }
  }, [isOpen]);
  var burgerMenuStyles = cx(styles.burgerMenu, className);
  var adjustSidebarMenuToViewportHeight = useCallback(function () {
    var viewportHeight = window.innerHeight;
    document.documentElement.style.setProperty('--burger-menu-sidebar-height', "".concat(viewportHeight, "px"));
  }, []);
  useEffect(function () {
    window.addEventListener('resize', adjustSidebarMenuToViewportHeight);
    return function () {
      window.removeEventListener('resize', adjustSidebarMenuToViewportHeight);
    };
  }, [adjustSidebarMenuToViewportHeight]);
  return React.createElement("div", {
    className: burgerMenuStyles
  }, React.createElement(IconButton, {
    icon: "mi-menu",
    iconColor: "light",
    onClick: toggleSidebar,
    "aria-label": translator(messages.openBurgerMenu),
    "aria-expanded": isOpen,
    className: cx(styles.burgerButton, buttonClassName),
    iconClassName: styles.burgerButtonIcon
  }), React.createElement(BurgerSidebar, {
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    burgerContent: _filterInstanceProperty(burgerContent).call(burgerContent, Boolean),
    separatorClassName: separatorClassName
  }));
};
BurgerMenu.propTypes = {
  className: PropTypes.string,
  burgerContent: PropTypes.arrayOf(PropTypes.node),
  separatorClassName: PropTypes.string,
  buttonClassName: PropTypes.string
};
BurgerMenu.__docgenInfo = {
  componentName: "BurgerMenu",
  packageName: "@jutro/router",
  description: "BurgerMenu component renders burger menu",
  displayName: "BurgerMenu",
  methods: [],
  actualName: "BurgerMenu",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    burgerContent: {
      type: {
        name: "arrayOf",
        value: {
          name: "node"
        }
      },
      required: false,
      description: "Sidebar content"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class to the burger sidebar separator"
    },
    buttonClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class to the burger menu button"
    }
  }
};