import _defineProperty2 from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { useContext } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isValid as isValidDate, parse as parseDate, format as formatDate } from 'date-fns';
import { LocaleContext } from '@jutro/locale';
import { FieldComponent, FieldIcon, DateValue, GenericSelectControl } from '@jutro/components';
import { generateTimeIntervals } from './timeIntervalGenerator';
import styles from "./TimeField.module.css";
var DEFAULT_TIME_INTERVAL = 30;
var FORMAT_12H = {
  type: '12h',
  format: 'hh:mm a',
  placeholder: '12:00 AM',
  mask: 'Hh:Mm aa'
};
var FORMAT_24H = {
  type: '24h',
  format: 'HH:mm',
  placeholder: '00:00',
  mask: 'Hh:Mm'
};
var TimeFieldInternal = function (_FieldComponent) {
  _inherits(TimeFieldInternal, _FieldComponent);
  var _super = _createSuper(TimeFieldInternal);
  function TimeFieldInternal() {
    var _this;
    _classCallCheck(this, TimeFieldInternal);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "isValidOption", function (value, selectValue, selectOptions) {
      var _this$props = _this.props,
        timeFormat = _this$props.timeFormat,
        locale = _this$props.locale;
      var _this$getTimeFormat = _this.getTimeFormat(locale, timeFormat),
        format = _this$getTimeFormat.format;
      var isValid = isValidDate(parseDate(value, format, new Date()));
      var isExist = _findInstanceProperty(selectOptions).call(selectOptions, function (option) {
        return option.value.toLowerCase() === value.toLowerCase();
      });
      return isValid && !isExist;
    });
    _defineProperty(_assertThisInitialized(_this), "onValueChange", function (option) {
      if (!option) {
        _this.notifyChange(undefined);
        return;
      }
      var _this$props2 = _this.props,
        timeFormat = _this$props2.timeFormat,
        locale = _this$props2.locale;
      var _this$getTimeFormat2 = _this.getTimeFormat(locale, timeFormat),
        format = _this$getTimeFormat2.format;
      var parsedValue = parseDate(option.value, format, new Date());
      _this.notifyChange({
        hour: parsedValue.getHours(),
        minute: parsedValue.getMinutes()
      });
    });
    return _this;
  }
  _createClass(TimeFieldInternal, [{
    key: "getTimeFormat",
    value: function getTimeFormat(locale, timeFormat) {
      var _context;
      if (timeFormat === FORMAT_12H.type) {
        return FORMAT_12H;
      }
      if (timeFormat === FORMAT_24H.type) {
        return FORMAT_24H;
      }
      var formatFromLocale = _findInstanceProperty(_context = [FORMAT_12H, FORMAT_24H]).call(_context, function (_ref) {
        var format = _ref.format;
        var _locale$formatLong;
        return _includesInstanceProperty(format).call(format, (_locale$formatLong = locale.formatLong) === null || _locale$formatLong === void 0 ? void 0 : _locale$formatLong.time({
          width: 'short'
        }));
      });
      return formatFromLocale || FORMAT_24H;
    }
  }, {
    key: "getPlaceholder",
    value: function getPlaceholder(customPlaceholder) {
      if (customPlaceholder) {
        var translator = this.translator;
        return translator(customPlaceholder);
      }
      var _this$props3 = this.props,
        timeFormat = _this$props3.timeFormat,
        locale = _this$props3.locale;
      var _this$getTimeFormat3 = this.getTimeFormat(locale, timeFormat),
        placeholder = _this$getTimeFormat3.placeholder;
      return placeholder;
    }
  }, {
    key: "filterOptions",
    value: function filterOptions(_ref2, rawInput) {
      var value = _ref2.value;
      var normalizeInput = rawInput.replace(/_|\s/g, '').toLowerCase();
      var normalizeValue = value.replace(/_|\s/g, '').toLowerCase();
      return _includesInstanceProperty(normalizeValue).call(normalizeValue, normalizeInput);
    }
  }, {
    key: "parseRawValue",
    value: function parseRawValue(value) {
      if (!value) {
        return '';
      }
      var _this$props4 = this.props,
        timeFormat = _this$props4.timeFormat,
        locale = _this$props4.locale;
      var _this$getTimeFormat4 = this.getTimeFormat(locale, timeFormat),
        format = _this$getTimeFormat4.format;
      var hour = value.hour,
        minute = value.minute;
      var date = new Date(1970, 0, 1, hour, minute, 0);
      return formatDate(date, format);
    }
  }, {
    key: "parseValue",
    value: function parseValue(value) {
      if (!value) {
        return undefined;
      }
      var _this$props5 = this.props,
        timeFormat = _this$props5.timeFormat,
        locale = _this$props5.locale;
      var _this$getTimeFormat5 = this.getTimeFormat(locale, timeFormat),
        format = _this$getTimeFormat5.format;
      var hour = value.hour,
        minute = value.minute;
      var date = new Date(1970, 0, 1, hour, minute, 0);
      return {
        value: formatDate(date, format),
        label: formatDate(date, format)
      };
    }
  }, {
    key: "renderInput",
    value: function renderInput(inputProps) {
      var _this2 = this;
      var _this$props6 = this.props,
        value = _this$props6.value,
        disabled = _this$props6.disabled,
        placeholder = _this$props6.placeholder,
        timeFormat = _this$props6.timeFormat,
        locale = _this$props6.locale,
        controlClassName = _this$props6.controlClassName;
      var _this$getTimeFormat6 = this.getTimeFormat(locale, timeFormat),
        mask = _this$getTimeFormat6.mask;
      var ariaAutocomplete = inputProps['aria-autocomplete'],
        inputValue = inputProps.value,
        onFocus = inputProps.onFocus,
        onBlur = inputProps.onBlur,
        onChange = inputProps.onChange;
      var formatChars = {
        H: '[0-2]',
        h: '[0-9]',
        M: '[0-5]',
        m: '[0-9]',
        a: '[AM|PM|am|pm]'
      };
      var selectedValue = this.parseRawValue(value);
      var classes = cx(styles.input, {
        disabled: disabled
      }, controlClassName);
      var onChangeCallback = function onChangeCallback(event) {
        var value = event.target.value;
        if (value !== selectedValue && !value.replace('/_|s|:/', '')) {
          _this2.onValueChange();
        }
        onChange(event);
      };
      return React.createElement(InputMask, {
        mask: mask,
        formatChars: formatChars,
        value: inputValue || selectedValue,
        onChange: onChangeCallback,
        onFocus: onFocus,
        onBlur: onBlur,
        disabled: disabled || false
      }, function () {
        return React.createElement("input", {
          className: classes,
          "aria-autocomplete": ariaAutocomplete,
          placeholder: _this2.getPlaceholder(placeholder),
          disabled: disabled || false,
          type: "text"
        });
      });
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(props) {
      var id = props.id,
        value = props.value;
      var hour = value.hour,
        minute = value.minute;
      var date = new Date(Date.UTC(1970, 0, 1, hour, minute));
      var classes = cx(styles.readOnly);
      return React.createElement(DateValue, {
        id: id,
        className: classes,
        value: date,
        onlyTime: true
      });
    }
  }, {
    key: "renderComponent",
    value: function renderComponent(props, options) {
      var _this3 = this;
      var id = props.id,
        value = props.value,
        disabled = props.disabled,
        timeFormat = props.timeFormat,
        locale = props.locale,
        _props$timeIntervals = props.timeIntervals,
        timeIntervals = _props$timeIntervals === void 0 ? DEFAULT_TIME_INTERVAL : _props$timeIntervals;
      var _this$getTimeFormat7 = this.getTimeFormat(locale, timeFormat),
        format = _this$getTimeFormat7.format;
      var selectedValue = this.parseValue(value);
      var availableValues = generateTimeIntervals(timeIntervals || DEFAULT_TIME_INTERVAL, format);
      var inputElement = React.createElement(GenericSelectControl, {
        id: id,
        value: selectedValue,
        availableValues: availableValues,
        allowNewValue: this.isValidOption,
        filter: this.filterOptions,
        getOptionLabel: function getOptionLabel(option) {
          return option.label;
        },
        getOptionValue: function getOptionValue(option) {
          return option.value;
        },
        onValueChange: this.onValueChange,
        valid: options.isValid,
        disabled: disabled,
        components: {
          DropdownIndicator: function DropdownIndicator() {
            return null;
          },
          IndicatorSeparator: function IndicatorSeparator() {
            return null;
          },
          Value: function Value() {
            return null;
          },
          Placeholder: function Placeholder() {
            return null;
          },
          ClearIndicator: function ClearIndicator() {
            return null;
          },
          Input: function Input(inputProps) {
            return _this3.renderInput(inputProps);
          }
        },
        noOptionsMessage: null,
        createNewMessage: "{message}",
        controlShouldRenderValue: false,
        backspaceRemovesValue: false,
        isClearable: true
      });
      return React.createElement(FieldIcon, props, inputElement);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      return this.renderComponent(breakpointProps, options);
    }
  }]);
  return TimeFieldInternal;
}(FieldComponent);
export var TimeField = function TimeField(props) {
  var _useContext = useContext(LocaleContext),
    dateLocale = _useContext.dateLocale;
  return React.createElement(TimeFieldInternal, _extends({
    locale: dateLocale
  }, props));
};
TimeField.displayName = 'TimeField';
TimeField.propTypes = _objectSpread({}, FieldComponent.propTypes, {}, FieldIcon.propTypes, {
  value: PropTypes.shape({
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired
  }),
  timeIntervals: PropTypes.number,
  timeFormat: PropTypes.oneOf(['12h', '24h'])
});
TimeField.defaultProps = _objectSpread({}, FieldComponent.defaultProps, {
  icon: 'mi-access-time',
  iconPosition: 'right',
  timeIntervals: DEFAULT_TIME_INTERVAL
});
TimeField.__docgenInfo = {
  componentName: "TimeField",
  packageName: "@jutro/lab-preview-date",
  description: "",
  displayName: "TimeField",
  methods: [],
  actualName: "TimeField",
  props: {
    value: {
      type: {
        name: "shape",
        value: {
          hour: {
            name: "number",
            required: true
          },
          minute: {
            name: "number",
            required: true
          }
        }
      },
      required: false,
      description: "Time to display"
    },
    timeIntervals: {
      type: {
        name: "number"
      },
      required: false,
      description: "Time intervals for input preselected values",
      defaultValue: {
        value: "30",
        computed: false
      }
    },
    timeFormat: {
      type: {
        name: "enum",
        value: [{
          value: "'12h'",
          computed: false
        }, {
          value: "'24h'",
          computed: false
        }]
      },
      required: false,
      description: "Time format"
    },
    icon: {
      defaultValue: {
        value: "'mi-access-time'",
        computed: false
      },
      required: false
    },
    iconPosition: {
      defaultValue: {
        value: "'right'",
        computed: false
      },
      required: false
    }
  },
  composes: ["@jutro/components"]
};