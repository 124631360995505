import React, { useRef, useCallback, useEffect } from 'react';
import { useKeyPress } from './useKeyPress';
export function useAccessibleRef(focused) {
  var ref = useRef();
  var handleKey = useCallback(function (e, keyPressed) {
    e.preventDefault();
    e.stopPropagation();
    if ((focused || isFocused(ref)) && keyPressed) {
      var _ref$current, _ref$current$click;
      (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : (_ref$current$click = _ref$current.click) === null || _ref$current$click === void 0 ? void 0 : _ref$current$click.call(_ref$current);
    }
  }, [focused, ref]);
  useEffect(function () {
    if (focused && !isFocused(ref)) {
      var _ref$current2, _ref$current2$focus;
      (_ref$current2 = ref.current) === null || _ref$current2 === void 0 ? void 0 : (_ref$current2$focus = _ref$current2.focus) === null || _ref$current2$focus === void 0 ? void 0 : _ref$current2$focus.call(_ref$current2);
    }
  }, [focused, ref]);
  useKeyPress(ref, ['Space', 'Enter'], handleKey);
  return ref;
}
function isFocused(_ref) {
  var current = _ref.current;
  return document.activeElement === current;
}