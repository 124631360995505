import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../Icon/Icon';
export var GenericSelectControlClearIndicator = function GenericSelectControlClearIndicator(_ref) {
  var className = _ref.className,
    genericSelectStyles = _ref.selectProps.genericSelectStyles,
    innerProps = _ref.innerProps;
  return React.createElement("div", innerProps, React.createElement(Icon, _extends({}, innerProps, {
    tag: "span",
    icon: "mi-cancel",
    className: cx(genericSelectStyles.selectIcon, genericSelectStyles.cancel, className),
    "aria-hidden": true
  })));
};
GenericSelectControlClearIndicator.__docgenInfo = {
  componentName: "GenericSelectControlClearIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlClearIndicator",
  methods: [],
  actualName: "GenericSelectControlClearIndicator"
};