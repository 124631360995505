import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { warning } from '@jutro/logger';
import styles from "./Grid.module.css";
import { useBreakpoint } from '../breakpoint/useBreakpoint';
var valignMapping = {
  top: styles.alignSelfStart,
  middle: styles.alignSelfCenter,
  bottom: styles.alignSelfEnd,
  baseline: styles.alignSelfBaseline,
  stretch: styles.alignSelfStretch
};
var alignMapping = {
  start: styles.justifySelfStart,
  center: styles.justifySelfCenter,
  end: styles.justifySelfEnd,
  stretch: styles.justifySelfStretch
};
var textAlignMapping = {
  left: styles.textLeft,
  center: styles.textCenter,
  right: styles.textRight
};
export var styleMappings = null;
if (window.__TEST__) {
  styleMappings = {
    valign: valignMapping,
    textAlign: textAlignMapping,
    align: alignMapping
  };
}
export var GridItem = function GridItem(props) {
  var _useBreakpoint = useBreakpoint(props),
    breakpointProps = _useBreakpoint.breakpointProps;
  var colSpan = breakpointProps.colSpan,
    colStart = breakpointProps.colStart,
    clone = breakpointProps.clone,
    fullWidth = breakpointProps.fullWidth,
    rowSpan = breakpointProps.rowSpan,
    rowStart = breakpointProps.rowStart,
    visible = breakpointProps.visible,
    align = breakpointProps.align,
    valign = breakpointProps.valign,
    textAlign = breakpointProps.textAlign,
    tag = breakpointProps.tag,
    children = breakpointProps.children,
    className = breakpointProps.className,
    style = breakpointProps.style,
    phone = breakpointProps.phone,
    phoneWide = breakpointProps.phoneWide,
    tablet = breakpointProps.tablet,
    dangerouslySetInnerHTML = breakpointProps.dangerouslySetInnerHTML,
    other = _objectWithoutProperties(breakpointProps, ["colSpan", "colStart", "clone", "fullWidth", "rowSpan", "rowStart", "visible", "align", "valign", "textAlign", "tag", "children", "className", "style", "phone", "phoneWide", "tablet", "dangerouslySetInnerHTML"]);
  var Tag = tag;
  if (visible === false) {
    return null;
  }
  var valignClass = valign && valignMapping[valign];
  var alignClass = align && alignMapping[align];
  var textAlignClass = textAlign && textAlignMapping[textAlign];
  var classes = cx(textAlignClass, valignClass, alignClass, className);
  var inlineStyles = _objectSpread({}, style);
  if (colStart || colSpan) {
    if (colStart) {
      inlineStyles.gridColumnStart = colStart;
    }
    if (colSpan) {
      inlineStyles.gridColumnEnd = "span ".concat(colSpan);
    }
    if (fullWidth) {
      warning('GridItem: fullWidth property is ignored because colStart or colSpan is provided.');
    }
  } else if (fullWidth) {
    inlineStyles.gridColumnStart = 1;
    inlineStyles.gridColumnEnd = -1;
  }
  if (rowStart) {
    inlineStyles.gridRowStart = rowStart;
  }
  if (rowSpan) {
    inlineStyles.gridRowEnd = "span ".concat(rowSpan);
  }
  var renderWithWrapper = function renderWithWrapper(wrappedChildren) {
    return React.createElement(Tag, _extends({
      className: classes,
      style: inlineStyles
    }, other), wrappedChildren);
  };
  if (clone) {
    var _context;
    return React.createElement(React.Fragment, null, _mapInstanceProperty(_context = React.Children).call(_context, children, function (child) {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, _objectSpread({}, other, {
          className: cx(child.props.className, classes),
          style: _objectSpread({}, child.props.style, {}, inlineStyles)
        }));
      }
      return renderWithWrapper(child);
    }));
  }
  return renderWithWrapper(children);
};
var gridItemPropTypes = {
  clone: PropTypes.bool,
  fullWidth: PropTypes.bool,
  visible: PropTypes.bool,
  rowSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.oneOf(_Object$keys(textAlignMapping)),
  valign: PropTypes.oneOf(_Object$keys(valignMapping)),
  align: PropTypes.oneOf(_Object$keys(alignMapping)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
};
GridItem.propTypes = gridItemPropTypes;
GridItem.defaultProps = {
  tag: 'div'
};
GridItem.displayName = 'GridItem';
GridItem.__docgenInfo = {
  componentName: "GridItem",
  packageName: "@jutro/layout",
  description: "Defines a css grid 'GridItem'. This is used in conjunction with 'Grid' parent.",
  displayName: "GridItem",
  methods: [],
  actualName: "GridItem",
  metadataType: "layout",
  props: {
    clone: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Render the child without tag wrapper. It passes classNames and styles directly to child element if possible.\nIf not possible, it falls back to wrapping with a tag (for not valid React elements)"
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, item takes full width of parent grid.\nIgnored if colStart or colSpan is specified."
    },
    visible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Specify whether Grid item should be visible"
    },
    rowSpan: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "How many rows it takes"
    },
    rowStart: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Row number to start"
    },
    colSpan: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "How many columns it takes"
    },
    colStart: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Column number to start"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }]
      },
      required: false,
      description: "Horizontally align the contents of the column"
    },
    valign: {
      type: {
        name: "enum",
        value: [{
          value: "\"top\"",
          computed: false
        }, {
          value: "\"middle\"",
          computed: false
        }, {
          value: "\"bottom\"",
          computed: false
        }, {
          value: "\"baseline\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Vertically align the column (relative to other columns in the same row). By default the column will be stretched to equal height."
    },
    align: {
      type: {
        name: "enum",
        value: [{
          value: "\"start\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"end\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Align the column. By default the column will be stretched horizontally."
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: false,
      description: "Dom tag to use",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Css class(es) to append to tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children for this item"
    },
    style: {
      type: {
        name: "object"
      },
      required: false,
      description: "DOM element styles"
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any GridItem property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any GridItem property for use at 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any GridItem property for use at 'tablet' breakpoint"
    }
  }
};