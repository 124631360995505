import { PhoneNumberUtil } from 'google-libphonenumber';
import { ServiceManager } from '@jutro/services';
import { logDeprecationMessage } from '@jutro/platform';
import { log } from '@jutro/logger';
import LocaleSettingsInterface from './LocaleSettingsInterface';
import { getCountryCodeFromLocale } from './locale';
var DATE_SHORT = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
};
var DATE_LONG = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};
function getLocaleSettings() {
  return ServiceManager.getServiceByContract(LocaleSettingsInterface);
}
function getDefaultLocale() {
  var localeSettings = getLocaleSettings();
  return localeSettings ? localeSettings.getPreferredLocale() : {};
}
export var formatISOShortDate = function formatISOShortDate(date) {
  logDeprecationMessage('formatISOShortDate');
  var stringDate = '';
  if (date.getTime()) {
    stringDate = date.toISOString().substr(0, 10);
  }
  return stringDate;
};
export var formatShortDate = function formatShortDate(date) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : getDefaultLocale();
  logDeprecationMessage('formatShortDate');
  return date.toLocaleString(locale, DATE_SHORT);
};
export var formatLongDate = function formatLongDate(date) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : getDefaultLocale();
  logDeprecationMessage('formatLongDate');
  return date.toLocaleString(locale, DATE_LONG);
};
export var formatCurrency = function formatCurrency(amount, currency) {
  var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : getDefaultLocale();
  logDeprecationMessage('formatCurrency');
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'code'
  });
};
export var formatPhoneNumber = function formatPhoneNumber(phoneNumber) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : getDefaultLocale();
  logDeprecationMessage('formatPhoneNumber');
  var phoneUtils = PhoneNumberUtil.getInstance();
  var phoneRegion = getCountryCodeFromLocale(locale) || getLocaleSettings().getDefaultPhoneCountry();
  try {
    return phoneUtils.format(phoneUtils.parse(phoneNumber, phoneRegion));
  } catch (e) {
    log.warning("Unable to format phone number '".concat(phoneNumber, "': ").concat(e.message));
    return phoneNumber;
  }
};
export var formatPhoneNumberAndCountryCode = function formatPhoneNumberAndCountryCode(phoneNumber, countryCodeIso2) {
  var phoneUtils = PhoneNumberUtil.getInstance();
  try {
    var _rawInput$getCountryC;
    var rawInput = phoneUtils.parseAndKeepRawInput(phoneNumber, countryCodeIso2);
    var countryCode = ((_rawInput$getCountryC = rawInput.getCountryCode()) === null || _rawInput$getCountryC === void 0 ? void 0 : _rawInput$getCountryC.toString()) || '';
    var formattedPhoneNumber = phoneUtils.format(phoneUtils.parse(phoneNumber, countryCodeIso2));
    return [countryCode, formattedPhoneNumber];
  } catch (error) {
    log.warning("Unable to format phone number '".concat(phoneNumber, "': ").concat(error.message));
    return [];
  }
};