import React, { useEffect } from 'react';
import { log } from '@jutro/logger';
import styles from "./UnresolvedComponent.module.css";
export var UnresolvedComponent = function UnresolvedComponent(_ref) {
  var id = _ref.id,
    componentName = _ref.componentName;
  var componentNameId = componentName ? "<".concat(componentName, " id=\"").concat(id, "\" />") : id;
  useEffect(function () {
    log.warning("Unable to resolve component \"".concat(componentNameId, "\". It is missing correct \"component\" or \"datatype\" properties."));
  }, [id, componentName]);
  return React.createElement("div", {
    id: id,
    className: styles.unresolvedComponent
  }, React.createElement("b", null, "***"), " Could not resolve component:", ' ', React.createElement("code", null, componentNameId), " ", React.createElement("b", null, "***"));
};
UnresolvedComponent.__docgenInfo = {
  componentName: "UnresolvedComponent",
  packageName: "@jutro/uiconfig",
  description: "Renders a warning that a component could not be resolved via metadata and logs a warning to the developer console.",
  displayName: "UnresolvedComponent",
  methods: [],
  actualName: "UnresolvedComponent"
};