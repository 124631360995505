import { warning } from '@jutro/logger';
import { getLanguageFromLocale } from './locale';
var FALLBACK_LOCALE = 'en-US';
var warnDifference = function warnDifference(requestedLocale, loadedLocale) {
  return warning("date-fns: Requested locale ".concat(requestedLocale, " not found; Falling back to ").concat(loadedLocale.code, "."));
};
export var getDateLocale = function getDateLocale(locale) {
  var dateLocale;
  try {
    dateLocale = require("date-fns/locale/".concat(locale, "/index.js"));
  } catch (_unused) {
    try {
      var fallbackLanguageLocale = getLanguageFromLocale(locale);
      dateLocale = require("date-fns/locale/".concat(fallbackLanguageLocale, "/index.js"));
    } catch (_unused2) {
      dateLocale = require("date-fns/locale/".concat(FALLBACK_LOCALE, "/index.js"));
    }
    warnDifference(locale, dateLocale);
  }
  return dateLocale;
};