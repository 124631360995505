import _defineProperty2 from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyValue, isNilValue } from '@jutro/data';
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING } from '@jutro/prop-types';
import cx from 'classnames';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { getOptionCode, getOptionName } from '../availableValues';
import { getComponentId } from '../uniqueIds';
import styles from "./ImageRadioButtonField.module.css";
import { Icon } from '../../Icon/Icon';
import { makeHandleRadioButtonKeyDown } from '../../../radiobuttonUtils';
export var ImageRadioButtonField = function (_FieldComponent) {
  _inherits(ImageRadioButtonField, _FieldComponent);
  var _super = _createSuper(ImageRadioButtonField);
  function ImageRadioButtonField(props, context) {
    var _this;
    _classCallCheck(this, ImageRadioButtonField);
    _this = _super.call(this, props, context);
    _defineProperty(_assertThisInitialized(_this), "handleRadioChange", function (evt) {
      var targetValue = evt.target.value;
      _this.handleAvailableValuesValueChange(targetValue);
    });
    _defineProperty(_assertThisInitialized(_this), "getOtherOptionImageName", function (breakpointProps) {
      var otherOptionImage = breakpointProps.otherOptionImage,
        imageFormat = breakpointProps.imageFormat;
      if (/.*\.([a-z]{3})/.test(otherOptionImage)) {
        return otherOptionImage;
      }
      return "".concat(otherOptionImage, ".").concat(imageFormat);
    });
    var id = props.id;
    _this.controlUniqueId = getComponentId(id);
    return _this;
  }
  _createClass(ImageRadioButtonField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(ImageRadioButtonField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _this2 = this;
      var id = breakpointProps.id,
        availableValues = breakpointProps.availableValues,
        imageBasePath = breakpointProps.imageBasePath,
        imageClassName = breakpointProps.imageClassName,
        detailElement = breakpointProps.detailElement,
        disabled = breakpointProps.disabled,
        label = breakpointProps.label,
        required = breakpointProps.required,
        controlClassName = breakpointProps.controlClassName,
        imageFormat = breakpointProps.imageFormat;
      var translator = this.translator;
      var values = availableValues;
      var publicUrl = process.env.PUBLIC_URL;
      var sourceRoot = publicUrl ? "".concat(publicUrl, "/").concat(imageBasePath) : imageBasePath;
      var componentClassName = cx(styles.imageRadioButton, imageClassName, controlClassName);
      var selectedValue = this.getDataTypeAwareSelectedValue();
      var triggerFocus = function triggerFocus(el, value) {
        el.querySelector("label[for=".concat(_this2.controlUniqueId, "_").concat(value, "]")).focus();
      };
      var handleRadioButtonKeyDown = makeHandleRadioButtonKeyDown(triggerFocus);
      var radios = _mapInstanceProperty(values).call(values, function (option) {
        var code = getOptionCode(option);
        var checked = !isNilValue(selectedValue) && selectedValue.toString() === code;
        if (isNilValue(code)) {
          code = 'empty';
          checked = isEmptyValue(selectedValue);
        }
        var valueId = "".concat(_this2.controlUniqueId, "_").concat(code);
        var imageFile = code === 'other' ? _this2.getOtherOptionImageName(breakpointProps) : "".concat(code, ".").concat(imageFormat);
        var imageSource = "".concat(sourceRoot, "/").concat(imageFile);
        var name = getOptionName(option);
        var optionName = translator(name);
        var contentWrapperStyles = cx(styles.contentWrapper, _defineProperty2({}, styles.disabled, disabled));
        var labelStyles = cx(styles.label, _defineProperty2({}, styles.disabled, disabled));
        return React.createElement("label", {
          "aria-label": optionName,
          key: valueId,
          htmlFor: valueId,
          className: labelStyles
        }, React.createElement("input", _extends({
          type: "radio",
          id: valueId,
          value: code || '',
          onChange: _this2.handleRadioChange,
          "aria-checked": checked,
          checked: checked,
          disabled: disabled,
          required: required
        }, _this2.generateDataPathProperty(), _this2.generateAccessibilityProperties())), React.createElement("div", {
          className: contentWrapperStyles
        }, React.createElement("div", {
          className: styles.iconImageContainer
        }, React.createElement("div", {
          className: styles.imageWrapper
        }, React.createElement("img", {
          alt: translator(option.imageAltText) || optionName,
          className: styles.image,
          src: imageSource
        })), React.createElement(Icon, {
          icon: "mi-check",
          className: styles.icon
        })), React.createElement("div", {
          className: styles.text
        }, optionName)));
      });
      if (selectedValue === 'other' && detailElement) {
        var otherId = "".concat(id, "_other_element");
        radios.push(React.createElement("label", {
          key: otherId,
          htmlFor: otherId,
          className: cx(styles.label, styles.otherOption)
        }, detailElement));
      }
      return React.createElement("div", {
        id: id,
        className: componentClassName,
        "aria-label": translator(label),
        onKeyDown: function onKeyDown(event) {
          return handleRadioButtonKeyDown(event, breakpointProps, _this2.handleAvailableValuesValueChange);
        },
        role: "radiogroup"
      }, radios);
    }
  }]);
  return ImageRadioButtonField;
}(FieldComponent);
_defineProperty(ImageRadioButtonField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.array,
  imageBasePath: PropTypes.string,
  imageFormat: PropTypes.string,
  imageClassName: PropTypes.string,
  otherOptionImage: PropTypes.string
}));
_defineProperty(ImageRadioButtonField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  availableValues: [],
  dataType: DATA_TYPE_STRING,
  imageFormat: 'jpg',
  otherOptionImage: 'other.svg'
}));
_defineProperty(ImageRadioButtonField, "contextType", FieldComponent.contextType);
ImageRadioButtonField.__docgenInfo = {
  componentName: "ImageRadioButtonField",
  packageName: "@jutro/components",
  description: "Renders a list of radio buttons where each button is an image. You map images onto options\nas shown in the following example.",
  displayName: "ImageRadioButtonField",
  methods: [{
    name: "handleRadioChange",
    docblock: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "getOtherOptionImageName",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "ImageRadioButtonField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "The selected value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "Default value to set if none is provided"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "The format of the value",
      defaultValue: {
        value: "'string'",
        computed: false
      }
    },
    availableValues: {
      type: {
        name: "array"
      },
      required: false,
      description: "Array of choice objects to display; choice objects contains 'code', 'name' and 'imageAltText'",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    imageBasePath: {
      type: {
        name: "string"
      },
      required: false,
      description: "Base path for image sources"
    },
    imageFormat: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional file format to reference images for options",
      defaultValue: {
        value: "'jpg'",
        computed: false
      }
    },
    imageClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "An optional class for the div surrounding the images"
    },
    otherOptionImage: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional name of custom image to be used for 'other' option",
      defaultValue: {
        value: "'other.svg'",
        computed: false
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};