import React, { useContext } from 'react';
import { info } from '@jutro/logger';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { getMessageProp } from '@jutro/platform';
import PropTypes from 'prop-types';
import styles from "./SkipNav.module.css";
import { getKeyPressHandler } from '../getKeyPressHandler';
import { messages } from './SkipNav.messages';
var skipNav = function skipNav() {
  info('Skipping the nav');
  var headerPosition = getComputedStyle(document.querySelector('header')).getPropertyValue('position');
  var mainContent = document.querySelector('main');
  if (headerPosition !== 'sticky') {
    mainContent.scrollIntoView();
  }
  mainContent.focus({
    preventScroll: true
  });
};
var skipNavPropTypes = {
  messageProps: PropTypes.shape({
    skipNavMessage: intlMessageShape
  })
};
var handleKeyboardSelection = getKeyPressHandler(skipNav);
export var SkipNav = function SkipNav(_ref) {
  var messageProps = _ref.messageProps;
  var translator = useContext(TranslatorContext);
  var skipNavMessage = getMessageProp('skipNavMessage', messageProps, messages);
  return React.createElement("span", {
    className: styles.skipNav,
    tabIndex: "0",
    role: "link",
    onClick: skipNav,
    onKeyPress: handleKeyboardSelection
  }, translator(skipNavMessage));
};
SkipNav.propTypes = skipNavPropTypes;
SkipNav.__docgenInfo = {
  componentName: "SkipNav",
  packageName: "@jutro/components",
  description: "Component providing a link to skip the navigation menu when focused.\nAssumes that the application uses jutro header/main/footer layout.",
  displayName: "SkipNav",
  methods: [],
  actualName: "SkipNav",
  props: {
    messageProps: {
      type: {
        name: "shape",
        value: {
          skipNavMessage: {
            name: "union",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message for skip navigation"
    }
  }
};