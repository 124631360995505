import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React from 'react';
import PropTypes from 'prop-types';
import { DATA_TYPE_STRING, DATA_TYPE_OBJECT } from '@jutro/prop-types';
import { formatPhoneNumberAndCountryCode } from '@jutro/locale';
import { getDefaultCountry } from '../IntlPhoneNumberHelpers';
var phoneDataTypeShape = PropTypes.oneOf([DATA_TYPE_STRING, DATA_TYPE_OBJECT]);
export var formattedPhoneNumberPropTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    countryCode: PropTypes.shape({
      code: PropTypes.string
    }),
    phoneNumber: PropTypes.string
  })]),
  showCountryCodeForReadOnly: PropTypes.bool,
  dataType: phoneDataTypeShape,
  defaultCountry: PropTypes.string
};
export var FormattedPhoneNumber = function FormattedPhoneNumber(props) {
  var value = props.value,
    showCountryCodeForReadOnly = props.showCountryCodeForReadOnly,
    dataType = props.dataType,
    defaultCountry = props.defaultCountry;
  var initialCountry = getDefaultCountry(defaultCountry);
  var resolvedValue;
  if (dataType === DATA_TYPE_OBJECT && value !== null && value !== void 0 && value.phoneNumber) {
    var _value$countryCode;
    resolvedValue = formatPhoneNumberAndCountryCode(value.phoneNumber, (value === null || value === void 0 ? void 0 : (_value$countryCode = value.countryCode) === null || _value$countryCode === void 0 ? void 0 : _value$countryCode.code) || initialCountry);
  }
  if (dataType === DATA_TYPE_STRING && value) {
    resolvedValue = formatPhoneNumberAndCountryCode(value, initialCountry);
  }
  var _ref = resolvedValue || [],
    _ref2 = _slicedToArray(_ref, 2),
    countryCode = _ref2[0],
    phoneNumber = _ref2[1];
  var readOnlyPhoneNumber = showCountryCodeForReadOnly ? "+".concat(countryCode, " ").concat(phoneNumber) : phoneNumber;
  return React.createElement(React.Fragment, null, phoneNumber ? readOnlyPhoneNumber : '-');
};
FormattedPhoneNumber.defaultProps = {
  dataType: DATA_TYPE_OBJECT,
  showCountryCodeForReadOnly: true
};
FormattedPhoneNumber.propTypes = formattedPhoneNumberPropTypes;
FormattedPhoneNumber.__docgenInfo = {
  componentName: "FormattedPhoneNumber",
  packageName: "@jutro/lab-preview-intl-phone-number",
  description: "The `FormattedPhoneNumber` component is used to render international phone number value.",
  displayName: "FormattedPhoneNumber",
  methods: [],
  actualName: "FormattedPhoneNumber",
  metadataType: "element",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            countryCode: {
              name: "shape",
              value: {
                code: {
                  name: "string",
                  required: false
                }
              },
              required: false
            },
            phoneNumber: {
              name: "string",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Value to display"
    },
    showCountryCodeForReadOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Set country code visibility if rendered in readOnly mode.",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    dataType: {
      type: {
        name: "custom",
        raw: "phoneDataTypeShape"
      },
      required: false,
      description: "Type of returned value",
      defaultValue: {
        value: "'object'",
        computed: false
      }
    },
    defaultCountry: {
      type: {
        name: "string"
      },
      required: false,
      description: "Default country code to be rendered. Must be in iso2 country code string."
    }
  }
};