import React from 'react';
export var hexColorElements = function hexColorElements(type, colorProperties, className) {
  if (type === 'solid') {
    return React.createElement("div", {
      className: className
    }, colorProperties.hexColor);
  }
  var startingHexColor = colorProperties.startingHexColor,
    finishingHexColor = colorProperties.finishingHexColor;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    key: "startingHexColor",
    className: className
  }, startingHexColor), React.createElement("div", {
    key: "finishingHexColor",
    className: className
  }, finishingHexColor));
};
hexColorElements.__docgenInfo = {
  componentName: "hexColorElements",
  packageName: "@jutro/components",
  description: "",
  displayName: "hexColorElements",
  methods: [],
  actualName: "hexColorElements"
};