import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./DropdownMenu.module.css";
export var DropdownMenuSeparator = function DropdownMenuSeparator(props) {
  var id = props.id,
    className = props.className;
  var separatorClasses = cx(styles.dropdownSeparator, className);
  return React.createElement("div", {
    id: id,
    className: separatorClasses,
    role: "separator"
  });
};
DropdownMenuSeparator.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
};
DropdownMenuSeparator.__docgenInfo = {
  componentName: "DropdownMenuSeparator",
  packageName: "@jutro/router",
  description: "DropdownMenuSeparator",
  displayName: "DropdownMenuSeparator",
  methods: [],
  actualName: "DropdownMenuSeparator",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify dropdown menu separator component."
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style class for the separator."
    }
  }
};