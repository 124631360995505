import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import { components } from 'react-select';
import cx from 'classnames';
export var GenericSelectControlMenu = function GenericSelectControlMenu(props) {
  var _props$selectProps = props.selectProps,
    genericSelectStyles = _props$selectProps.genericSelectStyles,
    isLoading = _props$selectProps.isLoading;
  if (isLoading) {
    return null;
  }
  return React.createElement(components.Menu, _extends({}, props, {
    className: cx(genericSelectStyles.genericSelect, genericSelectStyles.menu)
  }));
};
GenericSelectControlMenu.__docgenInfo = {
  componentName: "GenericSelectControlMenu",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlMenu",
  methods: [],
  actualName: "GenericSelectControlMenu"
};