import React from 'react';
import PropTypes from 'prop-types';
export var ActionItem = function ActionItem() {
  if (false) {
    throw new Error('Component <ActionItem /> should never render');
  }
  return React.createElement(React.Fragment, null);
};
ActionItem.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  isEditTrigger: PropTypes.bool,
  triggerOnRowClick: PropTypes.bool,
  singleActionType: PropTypes.oneOf(['icon', 'link'])
};
ActionItem.displayName = 'ActionItem';
ActionItem.__docgenInfo = {
  componentName: "ActionItem",
  packageName: "@jutro/datatable",
  description: "ActionItem",
  displayName: "ActionItem",
  methods: [],
  actualName: "ActionItem",
  metadataType: "action",
  props: {
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional Material Icon name (mi-*)"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Action for click event - ignored if `isEditTrigger` enabled"
    },
    label: {
      type: {
        name: "string"
      },
      required: false,
      description: "Label for action"
    },
    isEditTrigger: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if action triggers edit mode for row"
    },
    triggerOnRowClick: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if row click should trigger action"
    },
    singleActionType: {
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: false
        }, {
          value: "'link'",
          computed: false
        }]
      },
      required: false,
      description: "If action is single - determines it should be displayed as icon or link"
    }
  }
};