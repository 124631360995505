import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Loader } from '../loader/Loader';
import styles from "./PageLoader.module.css";
var pageLoaderPropTypes = {
  className: PropTypes.string,
  renderLoader: PropTypes.func
};
export var PageLoader = function PageLoader(_ref) {
  var className = _ref.className,
    renderLoader = _ref.renderLoader;
  var classes = cx(styles.pageLoader, className);
  return React.createElement("div", {
    className: classes
  }, React.createElement(Loader, {
    renderLoader: renderLoader
  }));
};
PageLoader.startPageLoading = function startPageLoading() {
  window.document.documentElement.classList.add(styles.appLoading);
};
PageLoader.stopPageLoading = function stopPageLoading() {
  window.document.documentElement.classList.remove(styles.appLoading);
};
PageLoader.propTypes = pageLoaderPropTypes;
PageLoader.__docgenInfo = {
  componentName: "PageLoader",
  packageName: "@jutro/components",
  description: "The `PageLoader` component renders an animated loading image that is shown when the `<html>` element is decorated\nwith the `app-loading` class.  Provides two static methods, `startPageLoading()` and `stopPageLoading()` that can\nbe used to activate the showing of the image as well as hiding any elements which are decorated with the\n`hidden-when-loading` class.",
  displayName: "PageLoader",
  methods: [],
  actualName: "PageLoader",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    renderLoader: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to render loader"
    }
  }
};