import React, { createContext, useRef } from 'react';
import { IntlProvider as ReactIntlProvider, useIntl } from 'react-intl';
import { warning } from '@jutro/logger';
import debounce from "lodash/debounce";
export var IntlContext = createContext(undefined);
var ReactIntlConsumer = function ReactIntlConsumer(_ref) {
  var children = _ref.children;
  var intl = useIntl();
  return React.createElement(IntlContext.Provider, {
    value: intl
  }, children);
};
export var IntlProvider = function IntlProvider(_ref2) {
  var defaultLocale = _ref2.defaultLocale,
    locale = _ref2.locale,
    messages = _ref2.messages,
    textComponent = _ref2.textComponent,
    children = _ref2.children;
  var bulkErrors = useRef({
    locale: '',
    ids: []
  });
  var deferWarning = useRef(debounce(function () {
    warning("".concat(bulkErrors.current.locale, " locale translations are not provided or missing next ").concat(bulkErrors.current.ids.length, " id's: ").concat(bulkErrors.current.ids.join(', ')));
    bulkErrors.current = {
      locale: '',
      ids: []
    };
  }, 500));
  var ammend = function ammend(errMeg) {
    var missingId = errMeg.match(/Missing message: "(.*)" for locale "(.*)"/);
    if (missingId) {
      bulkErrors.current.locale = bulkErrors.current.locale || missingId[2];
      bulkErrors.current.ids.push(missingId[1]);
      deferWarning.current();
    }
  };
  return React.createElement(ReactIntlProvider, {
    onError: function onError(err) {
      return ammend(err.message);
    },
    defaultLocale: defaultLocale,
    locale: locale,
    messages: messages,
    textComponent: textComponent
  }, React.createElement(ReactIntlConsumer, null, children));
};
IntlProvider.displayName = 'IntlProvider';
ReactIntlConsumer.__docgenInfo = {
  componentName: "ReactIntlConsumer",
  packageName: "@jutro/locale",
  description: "",
  displayName: "ReactIntlConsumer",
  methods: [],
  actualName: "ReactIntlConsumer"
};
IntlProvider.__docgenInfo = {
  componentName: "IntlProvider",
  packageName: "@jutro/locale",
  description: "",
  displayName: "IntlProvider",
  methods: [],
  actualName: "IntlProvider"
};