import _toConsumableArray from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import Ajv from 'ajv';
var SchemaValidator = function () {
  function SchemaValidator(schema) {
    var ajvOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      allErrors: false
    };
    _classCallCheck(this, SchemaValidator);
    this.ajv = new Ajv(ajvOptions);
    this.validator = this.ajv.compile(schema);
  }
  _createClass(SchemaValidator, [{
    key: "getUniqueErrors",
    value: function getUniqueErrors() {
      var errorMessageArray = this.ajv.errorsText(this.validator.errors).split(', ');
      return _toConsumableArray(new _Set(errorMessageArray)).join('\n');
    }
  }, {
    key: "validate",
    value: function validate(data) {
      var valid = this.validator(data);
      var errorMessages = valid ? undefined : this.getUniqueErrors();
      return {
        valid: valid,
        errorMessages: errorMessages
      };
    }
  }]);
  return SchemaValidator;
}();
export { SchemaValidator as default };