import React, { useEffect } from 'react';
import { useAuthState } from './AuthStateProvider';
var OktaError = function OktaError(_ref) {
  var error = _ref.error;
  if (error.name && error.message) {
    return React.createElement("p", null, "".concat(error.name, ": ").concat(error.message));
  }
  return React.createElement("p", null, "Error: ", error.toString());
};
export var LoginCallback = function LoginCallback(_ref2) {
  var errorComponent = _ref2.errorComponent;
  var _useAuthState = useAuthState(),
    authService = _useAuthState.authService,
    authState = _useAuthState.authState;
  var authStateReady = !authState.isPending;
  var ErrorReporter = errorComponent || OktaError;
  useEffect(function () {
    authService.handleAuthentication();
  }, [authService]);
  if (authStateReady && authState.error) {
    return React.createElement(ErrorReporter, {
      error: authState.error
    });
  }
  return null;
};
OktaError.__docgenInfo = {
  componentName: "OktaError",
  packageName: "@jutro/auth",
  description: "",
  displayName: "OktaError",
  methods: [],
  actualName: "OktaError"
};
LoginCallback.__docgenInfo = {
  componentName: "LoginCallback",
  packageName: "@jutro/auth",
  description: "",
  displayName: "LoginCallback",
  methods: [],
  actualName: "LoginCallback"
};