import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
function replaceAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}
export function applyInsertMode(newState, oldState, userInput, maskOptions, handleValueChangeCallback) {
  var _context;
  var isEmpty = _everyInstanceProperty(_context = newState.value.split('')).call(_context, function (char, index) {
    var _context2;
    return char === maskOptions.maskChar || _includesInstanceProperty(_context2 = maskOptions.permanents).call(_context2, index);
  });
  if (isEmpty || oldState.selection === null || newState.selection === null || !maskOptions.maskChar) {
    if (newState.value !== oldState.value) {
      handleValueChangeCallback({
        target: {
          value: newState.value
        }
      });
    }
    return newState;
  }
  var newValue = newState.value;
  if (userInput !== null) {
    newValue = newState.value;
    var startIndex = oldState.selection.start;
    var endIndex = newState.selection.end;
    while (endIndex < newValue.length) {
      var _context3, _context4;
      if (_includesInstanceProperty(_context3 = maskOptions.permanents).call(_context3, endIndex)) {
        endIndex += 1;
      } else if (_includesInstanceProperty(_context4 = maskOptions.permanents).call(_context4, startIndex)) {
        startIndex += 1;
      } else {
        newValue = replaceAt(newValue, endIndex, oldState.value.charAt(startIndex));
        endIndex += 1;
        startIndex += 1;
      }
    }
  } else if (newState.value !== oldState.value && oldState.selection.end < newValue.length) {
    newValue = oldState.value;
    var _startIndex = newState.selection.start;
    var _endIndex = oldState.selection.end;
    if (_endIndex === _startIndex) {
      _endIndex += 1;
    }
    while (_endIndex < newValue.length) {
      var _context5, _context6;
      if (_includesInstanceProperty(_context5 = maskOptions.permanents).call(_context5, _endIndex)) {
        _endIndex += 1;
      } else if (_includesInstanceProperty(_context6 = maskOptions.permanents).call(_context6, _startIndex)) {
        _startIndex += 1;
      } else {
        newValue = replaceAt(newValue, _startIndex, newValue.charAt(_endIndex));
        _endIndex += 1;
        _startIndex += 1;
      }
    }
    while (_startIndex < newValue.length) {
      var _context7;
      if (_includesInstanceProperty(_context7 = maskOptions.permanents).call(_context7, _startIndex)) {
        _startIndex += 1;
      } else {
        newValue = replaceAt(newValue, _startIndex, maskOptions.maskChar);
        _startIndex += 1;
      }
    }
  }
  return {
    value: newValue,
    selection: newState.selection
  };
}