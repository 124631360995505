import React from 'react';
import isString from "lodash/isString";
import { logDeprecationMessage } from '@jutro/platform';
export function resolveComponentFromMap(component) {
  var componentMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (isString(component)) {
    var resolvedComponent = componentMap[component];
    if (!resolvedComponent) {
      logDeprecationMessage('Support for componentMaps with case-insensitive keys', 'Adjust the keys in your componentMap to match the name of the component');
      resolvedComponent = componentMap[component.toLowerCase()];
    }
    return resolvedComponent;
  }
  return component;
}