import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import cx from 'classnames';
import styles from "./SliderIndicatorsContainer.module.css";
import { sliderValuePropType } from './sliderUtils';
export var SliderIndicatorsContainer = function SliderIndicatorsContainer(_ref) {
  var min = _ref.min,
    max = _ref.max,
    value = _ref.value,
    range = _ref.range,
    containerClassName = _ref.containerClassName,
    indicatorClassName = _ref.indicatorClassName;
  var _context;
  var values = range ? value : [value];
  var indicators = _filterInstanceProperty(_context = [{
    content: min,
    className: styles.min
  }, {
    content: max,
    className: styles.max
  }]).call(_context, function (_ref2) {
    var content = _ref2.content;
    return !_includesInstanceProperty(values).call(values, content);
  });
  return React.createElement("div", {
    className: containerClassName
  }, _mapInstanceProperty(indicators).call(indicators, function (_ref3) {
    var content = _ref3.content,
      className = _ref3.className;
    return React.createElement("div", {
      key: content,
      className: cx(indicatorClassName, className)
    }, React.createElement(FormattedNumber, {
      value: content
    }));
  }));
};
SliderIndicatorsContainer.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: sliderValuePropType.isRequired,
  range: PropTypes.bool,
  containerClassName: PropTypes.string,
  indicatorClassName: PropTypes.string
};
SliderIndicatorsContainer.__docgenInfo = {
  componentName: "SliderIndicatorsContainer",
  packageName: "@jutro/components",
  description: "",
  displayName: "SliderIndicatorsContainer",
  methods: [],
  actualName: "SliderIndicatorsContainer",
  props: {
    min: {
      type: {
        name: "number"
      },
      required: true,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: true,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "custom",
        raw: "sliderValuePropType"
      },
      required: true,
      description: "Current slider value (to create fully controlled component)"
    },
    range: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If set - range selector will be shown"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the component"
    },
    indicatorClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the indicator"
    }
  }
};