import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownMenuLink } from '@jutro/router';
import { Button } from '@jutro/components';
import { uniqueInnerId } from '@jutro/platform';
import styles from "./Pagination.module.css";
export var PaginationSelect = function PaginationSelect(_ref) {
  var availableValues = _ref.availableValues,
    displayValue = _ref.displayValue,
    id = _ref.id,
    isOpen = _ref.isOpen,
    onValueChange = _ref.onValueChange,
    value = _ref.value;
  var handleRenderTrigger = function handleRenderTrigger(props, toggleMenu) {
    var _context;
    var paginationId = props.id;
    var _uniqueInnerId = uniqueInnerId(paginationId, 'paginationSelectButtonId'),
      paginationSelectButtonId = _uniqueInnerId.paginationSelectButtonId;
    return React.createElement(Button, {
      id: "".concat(paginationSelectButtonId, "_paginationSelectButton"),
      type: "text",
      onClick: function onClick() {
        return toggleMenu();
      },
      icon: displayValue ? undefined : 'mi-expand-more',
      iconPosition: "right",
      className: styles.paginationSelect,
      "aria-expanded": props.isOpen,
      "data-testid": "pagination-select"
    }, displayValue || _mapInstanceProperty(_context = _filterInstanceProperty(availableValues).call(availableValues, function (_ref2) {
      var code = _ref2.code;
      return code === value;
    })).call(_context, function (o) {
      return o.name.defaultMessage || o.name;
    }));
  };
  var handleClick = function handleClick(evt) {
    evt.preventDefault();
    var menuCode = evt.currentTarget.dataset.code;
    onValueChange(menuCode);
  };
  var options = _mapInstanceProperty(availableValues).call(availableValues, function (_ref3) {
    var code = _ref3.code,
      name = _ref3.name;
    return React.createElement(DropdownMenuLink, {
      key: code,
      value: code,
      onClick: handleClick,
      className: styles.paginationMenuItem,
      "data-code": code,
      disabled: code === value
    }, name);
  });
  return React.createElement(DropdownMenu, {
    id: "paginationSelectDropdownMenu-".concat(id),
    isOpen: isOpen,
    onRenderTrigger: handleRenderTrigger,
    menuClassName: styles.paginationMenu
  }, options);
};
PaginationSelect.propTypes = {
  id: PropTypes.string,
  availableValues: PropTypes.array.isRequired,
  onValueChange: PropTypes.func,
  isOpen: PropTypes.bool,
  displayValue: PropTypes.string
};
PaginationSelect.defaultProps = {
  isOpen: false
};
PaginationSelect.__docgenInfo = {
  componentName: "PaginationSelect",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "PaginationSelect",
  methods: [],
  actualName: "PaginationSelect",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify the component"
    },
    availableValues: {
      type: {
        name: "array"
      },
      required: true,
      description: "Array of choice objects to display; choice objects contains 'code' and 'name'"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback used to handle selection change"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "The prop that indicates if the paginatin select menu is currently visible",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    displayValue: {
      type: {
        name: "string"
      },
      required: false,
      description: "If set, replaces text on select triggering button"
    }
  }
};