import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { injectIntl } from 'react-intl';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { warning } from '@jutro/logger';
import { IntlProvider } from './IntlProvider';
import { TranslatorProvider } from './TranslatorProvider';
import { LocaleContextProvider } from './localeContext';
import { LanguageContextProvider } from './languageContext';
import { getDateLocale } from './getDateLocale';
export function withIntl(ComponentToWrap) {
  var wrapper = injectIntl(ComponentToWrap);
  if (ComponentToWrap.propTypes) {
    wrapper.propTypes = ComponentToWrap.propTypes;
  }
  if (ComponentToWrap.defaultProps) {
    wrapper.defaultProps = ComponentToWrap.defaultProps;
  }
  return wrapper;
}
export var GlobalizationProvider = function GlobalizationProvider(_ref) {
  var _ref$defaultLocale = _ref.defaultLocale,
    defaultLocale = _ref$defaultLocale === void 0 ? 'en-US' : _ref$defaultLocale,
    defaultLanguage = _ref.defaultLanguage,
    messages = _ref.messages,
    children = _ref.children,
    onLanguageChange = _ref.onLanguageChange,
    intlTextComponent = _ref.intlTextComponent;
  var _useState = useState(defaultLocale),
    _useState2 = _slicedToArray(_useState, 2),
    locale = _useState2[0],
    setLocale = _useState2[1];
  var _useState3 = useState(defaultLanguage || defaultLanguage),
    _useState4 = _slicedToArray(_useState3, 2),
    language = _useState4[0],
    setLanguage = _useState4[1];
  var dateLocale = useMemo(function () {
    return getDateLocale(locale);
  }, [locale]);
  var localeContext = {
    locale: locale,
    dateLocale: dateLocale,
    localeOnChangeCallback: function localeOnChangeCallback(newLocale) {
      setLocale(newLocale);
    }
  };
  var languageContext = {
    language: language,
    languageOnChangeCallback: function languageOnChangeCallback(newLanguage) {
      setLanguage(newLanguage);
      onLanguageChange(newLanguage);
    }
  };
  return React.createElement(LanguageContextProvider, {
    value: languageContext
  }, React.createElement(LocaleContextProvider, {
    value: localeContext
  }, React.createElement(IntlProvider, {
    defaultLocale: defaultLocale,
    locale: locale,
    messages: messages,
    textComponent: intlTextComponent
  }, React.createElement(TranslatorProvider, null, children))));
};
var globalizationProviderPropTypes = {
  defaultLocale: PropTypes.string,
  defaultLanguage: PropTypes.string,
  messages: PropTypes.objectOf(PropTypes.string),
  onLanguageChange: PropTypes.func,
  intlTextComponent: PropTypes.string
};
GlobalizationProvider.defaultProps = {
  defaultLocale: 'en-US',
  messages: {},
  onLanguageChange: function onLanguageChange() {
    return warning('GlobalizationProvider: The language has been changed but no onLanguageChange callback provided.');
  }
};
GlobalizationProvider.displayName = 'GlobalizationProvider';
GlobalizationProvider.propTypes = globalizationProviderPropTypes;
GlobalizationProvider.__docgenInfo = {
  componentName: "GlobalizationProvider",
  packageName: "@jutro/locale",
  description: "A GlobalizationProvider that can be placed in the app component hierarchy.",
  displayName: "GlobalizationProvider",
  methods: [],
  actualName: "GlobalizationProvider",
  props: {
    defaultLocale: {
      type: {
        name: "string"
      },
      required: false,
      description: "Default locale. Changes to this property will be ignored. Change locale with GlobalizationChooser.",
      defaultValue: {
        value: "'en-US'",
        computed: false
      }
    },
    defaultLanguage: {
      type: {
        name: "string"
      },
      required: false,
      description: "Default language. Changes to this property will be ignored. Change language with GlobalizationChooser."
    },
    messages: {
      type: {
        name: "objectOf",
        value: {
          name: "string"
        }
      },
      required: false,
      description: "Messages used by IntlProvider. You need to handle loading and passing new messages when language changes.\nSee MessagesLoader.",
      defaultValue: {
        value: "{}",
        computed: false
      }
    },
    onLanguageChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Triggered when language is changed\n(language: string) => void",
      defaultValue: {
        value: "() => warning('GlobalizationProvider: The language has been changed but no onLanguageChange callback provided.')",
        computed: false
      }
    },
    intlTextComponent: {
      type: {
        name: "string"
      },
      required: false,
      description: "The tag to render text inside of react-intl <Formatted*> components; defaults to React.Fragment (https://formatjs.io/docs/react-intl/upgrade-guide-3x)"
    }
  }
};