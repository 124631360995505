import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import ContextConsumer from './ContextConsumer';
export default function createContextConsumerHOC(config) {
  var ComponentToWrap = config.component,
    ContextToConsume = config.context,
    mapContextToProps = config.mapContextToProps,
    displayName = config.displayName;
  var wrapper = function renderWrapper(props) {
    return React.createElement(ContextConsumer, {
      contexts: [ContextToConsume]
    }, function (context) {
      var contextProps = mapContextToProps ? mapContextToProps(props, context) : {
        context: context
      };
      return React.createElement(ComponentToWrap, _extends({}, props, contextProps));
    });
  };
  wrapper.displayName = displayName || "WithContext(".concat(ComponentToWrap.displayName || ComponentToWrap.name, ")");
  wrapper.WrappedComponent = ComponentToWrap;
  if (ComponentToWrap.propTypes) {
    wrapper.propTypes = ComponentToWrap.propTypes;
  }
  if (ComponentToWrap.defaultProps) {
    wrapper.defaultProps = ComponentToWrap.defaultProps;
  }
  return hoistStatics(wrapper, ComponentToWrap);
}