import _toConsumableArray from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$getOwnPropertyNames from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-names";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _Object$getPrototypeOf from "@babel/runtime-corejs3/core-js-stable/object/get-prototype-of";
function getPrototype(obj) {
  return obj.prototype || _Object$getPrototypeOf(obj);
}
function getPrototypes(object) {
  var proto = getPrototype(object);
  var protos = [];
  while (proto !== null && proto !== undefined && proto !== Object.prototype) {
    protos.push(proto);
    proto = getPrototype(proto);
  }
  return protos;
}
var ImplDescriptor = function () {
  function ImplDescriptor(objOrFunc) {
    _classCallCheck(this, ImplDescriptor);
    this.instance = objOrFunc;
    this.allPrototypes = getPrototypes(objOrFunc);
  }
  _createClass(ImplDescriptor, [{
    key: "findPrototypeMember",
    value: function findPrototypeMember(name) {
      var _context;
      var proto = _findInstanceProperty(_context = this.allPrototypes).call(_context, function (p) {
        return p[name] !== undefined;
      });
      return proto ? proto[name] : undefined;
    }
  }, {
    key: "getMembers",
    value: function getMembers() {
      var _context2, _context3;
      var keys = new _Set(_filterInstanceProperty(_context2 = [].concat(_toConsumableArray(_Object$getOwnPropertyNames(this.instance)), _toConsumableArray(_mapInstanceProperty(_context3 = this.allPrototypes).call(_context3, function (p) {
        return _Object$getOwnPropertyNames(p);
      })))).call(_context2, function (k) {
        return k !== 'prototype' && k !== 'constructor';
      }));
      return _Array$from(keys);
    }
  }, {
    key: "getMember",
    value: function getMember(name) {
      return this.instance[name] || this.findPrototypeMember(name);
    }
  }, {
    key: "hasMember",
    value: function hasMember(name) {
      return this.getMember(name) !== undefined;
    }
  }]);
  return ImplDescriptor;
}();
export { ImplDescriptor as default };