import _ from 'lodash';
export var animationDelayDefault = 400;
var getHeight = function getHeight(node) {
  return node.scrollHeight;
};
var setHeight = function setHeight(node, height) {
  node.style.height = "".concat(height, "px");
};
var collapse = function collapse(node) {
  return setHeight(node, 0);
};
var flushHeight = function flushHeight(node) {
  return setHeight(node, getHeight(node));
};
var simpleAnimationMap = {
  none: {},
  highlight: {
    classNames: 'highlight',
    timeout: {
      enter: animationDelayDefault
    },
    exit: false
  },
  insert: {
    classNames: 'insert',
    timeout: {
      enter: animationDelayDefault,
      exit: animationDelayDefault / 2
    }
  },
  expand: function expand() {
    var height;
    return {
      classNames: 'expand',
      onEnter: function onEnter(node) {
        height = getHeight(node);
        collapse(node);
      },
      onEntering: function onEntering(node) {
        setHeight(node, height);
      },
      onExit: flushHeight,
      onExiting: collapse,
      timeout: {
        enter: animationDelayDefault,
        exit: animationDelayDefault / 2
      }
    };
  },
  reveal: {
    classNames: 'reveal',
    transitionWrapper: 'reveal_base',
    timeout: animationDelayDefault
  }
};
export function resolveAnimationFromName(name) {
  if (_.isObject(name) || _.isFunction(name)) {
    return name;
  }
  return simpleAnimationMap[name] || simpleAnimationMap.none;
}