import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _ from 'lodash';
import { isEmptyValue } from '@jutro/data';
import { findContentFromMetadata, prepareContentFromMetadata } from '../render/renderMetadataHelper';
export function validateFieldUsingMetadata(metadata) {
  var componentProps = metadata.componentProps;
  var _ref = componentProps || {},
    required = _ref.required,
    validationMessages = _ref.validationMessages,
    value = _ref.value;
  if (!_.isEmpty(validationMessages)) {
    return false;
  }
  if (required === true && isEmptyValue(value)) {
    return false;
  }
  return true;
}
export function validateContentFromMetadata(contentMetadata, overrideProps, resolvers) {
  var rootMetadata = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var skipHidden = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var preparedContent = prepareContentFromMetadata(contentMetadata, overrideProps, resolvers, rootMetadata);
  var fieldArray = findContentFromMetadata(preparedContent, function (item) {
    return item.type === 'field';
  }, skipHidden);
  var invalid = _someInstanceProperty(fieldArray).call(fieldArray, function (item) {
    return !validateFieldUsingMetadata(item);
  });
  return !invalid;
}
export function findInvalidFieldsFromMetadata(contentMetadata, overrideProps, resolvers) {
  var rootMetadata = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var skipHidden = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var preparedContent = prepareContentFromMetadata(contentMetadata, overrideProps, resolvers, rootMetadata);
  var fieldArray = findContentFromMetadata(preparedContent, function (item) {
    return item.type === 'field';
  }, skipHidden);
  var invalidFields = _filterInstanceProperty(fieldArray).call(fieldArray, function (item) {
    return !validateFieldUsingMetadata(item);
  });
  return invalidFields;
}