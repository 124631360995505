import { useContext } from 'react';
import { isIntlToShapeLocation } from '@jutro/prop-types';
import { sanitizeUrl } from '@jutro/platform';
import { TranslatorContext } from '../TranslatorProvider';
export var getUrlTranslatorAndSanitizer = function getUrlTranslatorAndSanitizer(translator) {
  return function (url) {
    var allowNoLeadingSlash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (isIntlToShapeLocation(url)) {
      return {
        pathname: sanitizeUrl(translator(url.pathname), allowNoLeadingSlash),
        search: translator(url.search),
        hash: translator(url.hash),
        state: url.state
      };
    }
    return sanitizeUrl(translator(url), allowNoLeadingSlash);
  };
};
export var useSafeTranslatedUrls = function useSafeTranslatedUrls() {
  var translator = useContext(TranslatorContext);
  return getUrlTranslatorAndSanitizer(translator);
};