import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useContext, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import mapKeys from "lodash/mapKeys";
import isString from "lodash/isString";
import omit from "lodash/omit";
import { TranslatorContext, useSafeTranslatedUrls } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { JUTRO_TOPICS } from '@jutro/events';
import { useKeyActive } from '@jutro/platform';
import { trackMethod } from '../../helpers';
import styles from "./Button.module.css";
import { Icon } from '../Icon/Icon';
import { InlineLabel } from '../InlineLabel/InlineLabel';
export var buttonTypes = ['filled', 'outlined', 'text'];
export var buttonActionTypes = ['button', 'submit', 'reset'];
export var buttonSizes = ['small', 'medium'];
var deprecatedTypes = ['primary', 'secondary', 'tertiary', 'neutral', 'danger'];
var typeStyleMap = mapKeys([].concat(buttonTypes, deprecatedTypes));
var buttonPropTypes = {
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  renderContent: PropTypes.func,
  size: PropTypes.oneOf(buttonSizes),
  type: PropTypes.oneOf(buttonTypes),
  href: intlMessageShape,
  actionType: PropTypes.oneOf(buttonActionTypes)
};
var ButtonInternal = React.forwardRef(function (props, ref) {
  var actionType = props.actionType,
    children = props.children,
    className = props.className,
    iconClassName = props.iconClassName,
    disabled = props.disabled,
    fullWidth = props.fullWidth,
    href = props.href,
    icon = props.icon,
    iconPosition = props.iconPosition,
    onClick = props.onClick,
    renderContent = props.renderContent,
    size = props.size,
    type = props.type,
    dangerouslySetInnerHTML = props.dangerouslySetInnerHTML,
    other = _objectWithoutProperties(props, ["actionType", "children", "className", "iconClassName", "disabled", "fullWidth", "href", "icon", "iconPosition", "onClick", "renderContent", "size", "type", "dangerouslySetInnerHTML"]);
  var translator = useContext(TranslatorContext);
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var safeHref = urlTranslatorAndSanitizer(href);
  var typeStyle = typeStyleMap[type] || 'filled';
  var renderAsLink = safeHref !== undefined;
  var newRef = ref || useRef();
  var isActiveKeyPressed = useKeyActive(newRef);
  var classes = cx(_defineProperty({}, styles.activeKeypress, isActiveKeyPressed), styles.button, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, styles.iconButton, icon && !children), styles[typeStyle], typeStyle), styles.small, size === 'small'), styles.fullWidth, fullWidth), styles.disabled, disabled), styles.materialIcon, icon && _startsWithInstanceProperty(icon).call(icon, 'mi')), className);
  var isRight = iconPosition === 'right';
  var isLeft = !isRight;
  var iconClasses = cx(styles.icon, _defineProperty(_defineProperty({}, styles.leftIcon, isLeft && children), styles.rightIcon, isRight && children), iconClassName);
  var iconComponent = icon && React.createElement(Icon, {
    icon: icon,
    className: iconClasses
  });
  var translatedChildren = translator(children);
  var content = React.createElement(InlineLabel, {
    icon: iconComponent,
    iconPosition: iconPosition
  }, React.createElement("span", null, translatedChildren));
  var trackingProps = _objectSpread({}, props);
  if (isString(translatedChildren)) {
    trackingProps.label = translatedChildren;
  }
  var trackedTopic = renderAsLink ? JUTRO_TOPICS.LINK_CLICKED : JUTRO_TOPICS.BUTTON_CLICKED;
  var trackedOnClick = trackMethod(onClick, trackedTopic, trackingProps);
  var contentToRender = renderContent ? renderContent(content) : content;
  var remainingProps = omit(other, ['translator', 'showRequired']);
  var defaultRender = React.createElement("button", _extends({
    ref: newRef,
    type: actionType,
    onClick: trackedOnClick,
    className: classes,
    disabled: disabled
  }, remainingProps), contentToRender);
  var renderLinkClasses = cx(classes, _defineProperty({}, styles.disabled, disabled));
  var renderLink = React.createElement("a", _extends({
    ref: newRef,
    href: disabled ? undefined : safeHref,
    onClick: !disabled && trackedOnClick,
    className: renderLinkClasses
  }, remainingProps), contentToRender);
  return renderAsLink ? renderLink : defaultRender;
});
export var Button = memo(ButtonInternal);
ButtonInternal.propTypes = buttonPropTypes;
ButtonInternal.defaultProps = {
  actionType: 'button',
  disabled: false,
  fullWidth: false,
  iconPosition: 'left',
  size: 'medium',
  type: 'filled'
};
ButtonInternal.displayName = 'Button';
Button.displayName = 'Button';
Button.__docgenInfo = {
  componentName: "Button",
  packageName: "@jutro/components",
  description: "",
  displayName: "Button",
  methods: [],
  actualName: "Button",
  metadataType: "action",
  props: {
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "The children elements to render inside of the Button"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for icon"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, this button is disabled",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, the button expands to the available width",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional Material Icon name (mi-*)"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Where the icon is placed relative to the text",
      defaultValue: {
        value: "'left'",
        computed: false
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when button is clicked"
    },
    renderContent: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when want to wrap button content"
    },
    size: {
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: false
        }, {
          value: "'medium'",
          computed: false
        }]
      },
      required: false,
      description: "Allows you to select the smaller or larger variant",
      defaultValue: {
        value: "'medium'",
        computed: false
      }
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'filled'",
          computed: false
        }, {
          value: "'outlined'",
          computed: false
        }, {
          value: "'text'",
          computed: false
        }]
      },
      required: false,
      description: "Determines the display variety (secondary, tertiary, neutral)",
      defaultValue: {
        value: "'filled'",
        computed: false
      }
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "if this prop is used an `<a>` tag will be used instead of `<button>`\n this will be the href passed to the `<a>` tag used."
    },
    actionType: {
      type: {
        name: "enum",
        value: [{
          value: "'button'",
          computed: false
        }, {
          value: "'submit'",
          computed: false
        }, {
          value: "'reset'",
          computed: false
        }]
      },
      required: false,
      description: "Type of action button performs",
      defaultValue: {
        value: "'button'",
        computed: false
      }
    }
  }
};