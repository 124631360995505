import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext, useSafeTranslatedUrls } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./FooterNavBar.module.css";
var footerNavLinkPropTypes = {
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  url: intlMessageShape
};
export var FooterNavLink = function FooterNavLink(props) {
  var header = props.header,
    url = props.url,
    className = props.className;
  var translator = useContext(TranslatorContext);
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var classes = cx(styles.navItem, className);
  return React.createElement("div", {
    className: classes
  }, React.createElement("a", {
    tabIndex: "0",
    className: styles.navLink,
    role: "link",
    href: urlTranslatorAndSanitizer(url)
  }, translator(header)));
};
FooterNavLink.propTypes = footerNavLinkPropTypes;
FooterNavLink.__docgenInfo = {
  componentName: "FooterNavLink",
  packageName: "@jutro/components",
  description: "The `FooterNavLink` component is designed as a simple component for presenting a navigation link inside the `FooterNavBar` container.",
  displayName: "FooterNavLink",
  methods: [],
  actualName: "FooterNavLink",
  metadataType: "action",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Link header."
    },
    url: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "External link."
    }
  }
};